import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { AuthService, IauthResponse } from 'src/app/Data/Services/auth.service';
import { DeviceService } from 'src/app/Data/Services/device/device-service.service';
import { catchError } from 'rxjs';
import { SnackbarService } from '../Layouts/snackbar/snackbar.service';
import { AppVersionControl } from 'src/app/Data/app-version.contrtol';
import { StorageKeys } from 'src/app/Data/Services/storage.data/storage-keys.data';
import { GlobalsService } from 'src/app/Data/Services/globals.service';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BidiModule } from '@angular/cdk/bidi';
import { NgStyle, NgIf } from '@angular/common';
import { CurrentDirDirective } from '../../Directives/current-dir.directive';
import { LanguageToggleComponent } from '../Layouts/sidenav/sub-components/user-profile-card/language-toggle/language-toggle.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        LanguageToggleComponent,
        CurrentDirDirective,
        NgStyle,
        FormsModule,
        ReactiveFormsModule,
        BidiModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        NgIf,
        MatCheckboxModule,
        MatButtonModule,
        TranslateModule,
    ],
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    protected deviceService: DeviceService,
    private snackBarService: SnackbarService,
    private router: Router
  ) {}

  private readonly HttpErrors = {
    UnrecognizedTenant: 'unrecognized tenant',
    NoMatch: 'no match',
  };
  // ---- App Versions ----
  protected appVersionsControl: AppVersionControl = AppVersionControl.instance;

  protected isMobile: boolean = this.deviceService.isMobile;
  protected isTablet: boolean = this.deviceService.isTablet;
  protected isDesktop: boolean = this.deviceService.isDesktop;

  // ----- vars -----
  hide = true;
  isRememberChecked: boolean = false;
  checkBoxColor: ThemePalette;

  protected get dir(): string {
    return GlobalsService.dir;
  }

  //---- Form Validators ------
  loginFormGroup: FormGroup = new FormGroup({
    validateCompany: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.pattern('^[a-zA-Z0-9]*$'),
    ]),
    validateUserName: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.pattern('^[!-~]*$'),
    ]),
    validatePassword: new FormControl('', [Validators.required]),
    rememberMeChecked: new FormControl(this.isRememberChecked),
  });

  ngOnInit(): void {
    // initial remember me property
    this.isRememberChecked = window.storageService.getItem(StorageKeys.AuthServiceKeys.KeyRememberMe) ?? false

    // set the company name to the environment.company value according to the url:
    //================================================================================
    this.loginFormGroup.controls['validateCompany'].setValue(AuthService.getCompanyFromUrl);
    if (location.href.includes('localhost')) this.loginFormGroup.controls['validateCompany'].setValue('Debug');

    if (location.href.includes('localhost') && !location.href.includes(AuthService.getCompanyFromUrl)) {
      location.replace(
        location.protocol +
          '//' +
          AuthService.getCompanyFromUrl +
          '.docserver.' +
          location.host
      );
    } else if (!location.href.includes('localhost') &&
      AuthService.apiUri &&
      !location.href.includes(AuthService.apiUri)
    ) {
      location.replace(AuthService.apiUri);
    }
  }

  protected disableLoginButton: boolean = false;
  onLogin() {
    if (this.loginFormGroup.valid) {
      this.disableLoginButton = true;
      this.authService
        .login(
          this.loginFormGroup.value.validateCompany,
          this.loginFormGroup.value.validateUserName,
          this.loginFormGroup.value.validatePassword,
          this.loginFormGroup.value.rememberMeChecked
        )
        .pipe(
          catchError((err) => {
            switch (err.error.error) {
              case this.HttpErrors.UnrecognizedTenant:
                this.snackBarService.open({
                  message: 'pages.login.errors.validcompany',
                  type: 'error',
                });
                break;
              case this.HttpErrors.NoMatch:
                this.snackBarService.open({
                  message: 'pages.login.errors.nomatch',
                  type: 'error',
                });
                break;
              default:
                this.snackBarService.open({
                  message: 'pages.login.errors.unknownerror',
                  type: 'error',
                });
                break;
            }
            this.disableLoginButton = false;
            return err;
          })
        )
        .subscribe((response: IauthResponse) => {
          if (response) {
           // console.log(response)
            if (
              location.href.includes('localhost') &&
              !location.href.includes(response.tenant)
            ) {
              this.router.navigate(['reports'])
              /* -------------------------------------------------------------------------- */
              /*                              Dev mode testing                              */
              /* -------------------------------------------------------------------------- */
              // location.replace(
              //   location.protocol +
              //     '//debug.docserver.localhost:' +
              //     location.port +
              //     '/reports/?accessToken=' +
              //     response.accessToken +
              //     '&refreshToken=' +
              //     response.refreshToken +
              //     '&tenant=' + AuthService.getCompanyFromUrl
              // );
            } else {
              location.replace(
                response.apiUri +
                  '/reports/?accessToken=' +
                  response.accessToken +
                  '&refreshToken=' +
                  response.refreshToken +
                  '&tenant=' + AuthService.getCompanyFromUrl
              );
            }
          } else {
            this.disableLoginButton = false;
          }
        });
    }
  }

  companyErrorMessage(): string {
    if (!this.loginFormGroup.value.validateCompany) {
      return 'pages.login.errors.emptycompany';
    }
    return 'pages.login.errors.validcompany';
  }
  userNameErrorMessage(): string {
    if (!this.loginFormGroup.value.validateUserName) {
      return 'pages.login.errors.emptyusername';
    }
    return 'pages.login.errors.validusername';
  }
  passwordErrorMessage(): string {
    if (!this.loginFormGroup.value.validatePassword) {
      return 'pages.login.errors.emptypassword';
    }
    // return ('pages.login.errors.validpassword');
    return '';
  }
}
