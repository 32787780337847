<button
  class="flex h-5 w-12 items-center rounded-full bg-white shadow transition duration-300 focus:outline-none"
  (click)="toggleLanguage({ updateUserPreferences: true })"
  [matTooltip]="activeLanguage.value | translate"
>
	<div
		#SwitchToggle
		id="switch-toggle"
		class="relative aspect-square h-6 -translate-x-2 transform rounded-full bg-blue-500 p-1 text-white transition duration-500"
	>
    <img [src]="activeLanguage.iconPath" alt=""/>
	</div>
</button>
