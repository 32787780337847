<mat-accordion>
  <form [formGroup]="generalSettingsFG">
    <!-- ----------------------------------------------------------------------- -->
    <!--                          Language preferences                           -->
    <!-- ----------------------------------------------------------------------- -->
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> {{'Language-preferences' | translate}}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>{{'Default-language' | translate}}</mat-label>
        <mat-select formControlName="defaultLanguage">
          <mat-option value="he">{{'he' | translate}}</mat-option>
          <mat-option value="en">{{'en' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-expansion-panel>

    <!-- ----------------------------------------------------------------------- -->
    <!--                             Default screens                             -->
    <!-- ----------------------------------------------------------------------- -->
    <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title>{{'Screens' | translate}} </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-checkbox formControlName="useSplashScreen" >{{'use-splash-screen' | translate}}</mat-checkbox>
      </mat-expansion-panel>

      <!-- ----------------------------------------------------------------------- -->
      <!--                              Company logo                               -->
      <!-- ----------------------------------------------------------------------- -->
      <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title>{{'Company-logo' | translate}}</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="flex flex-col items-center space-y-5 p-5 border border-gray-300 rounded">
            <input type="file" accept="image/*" hidden (change)="onFileChanged($event)" #fileInput>
          
            <div class="text-3xl cursor-pointer mb-5" (click)="fileInput.click()">
              <i class="fas fa-upload"></i>
            </div>
          
            <div [ngClass]="{'bg-blue-100': isDragOver}" 
                 class="flex flex-col items-center justify-center w-72 h-72 border-2 border-dashed border-gray-300 rounded relative"
                 (drop)="onDrop($event)" 
                 (dragover)="onDragOver($event)" 
                 (dragleave)="onDragLeave($event)">
              <div *ngIf="!imagePreview" class="flex flex-col items-center justify-center space-y-2">
                <p>{{'D&D-upload-file' | translate}}</p>
                <button class="mt-2 bg-primary-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" (click)="fileInput.click()">
                  {{'OR-click-to-upload' | translate}}
                </button>
              </div>
              
              <div *ngIf="imagePreview" class="absolute inset-0" [ngClass]="{'opacity-50': isDragOver}">
                <img class="w-full h-full object-cover" [src]="sanitizer.bypassSecurityTrustUrl(imagePreview)" alt="Image preview">
              </div>
            </div>

            <button *ngIf="imagePreview" class="mt-5 bg-error-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" (click)="onClear()">
                {{'Clear-logo' | translate}}
              </button>
          </div>     
      </mat-expansion-panel>

    </form>
  </mat-accordion>
  