export const StorageModeType = {
	Local: 'Local',
	Session: 'Session',
} as const;

export type StorageModeType = typeof StorageModeType[keyof typeof StorageModeType];

export interface IStorageModeData {
	storageObj: Storage;
	getItem: (key: string) => string | null;
	setItem: (key: string, value: string) => void;
	removeItem: (key: string) => void;
	key: (index: number) => string | null;
	clear: () => void;
	length: number;
}

export const StorageModeData: Map<StorageModeType, IStorageModeData> = new Map<StorageModeType, IStorageModeData>([
	[
		StorageModeType.Local,
		{
			storageObj: localStorage,
			getItem: (key: string) => {
				return localStorage.getItem(key);
			},
			setItem: (key: string, value: string) => {
				return localStorage.setItem(key, value);
			},
			removeItem: (key: string) => {
				return localStorage.removeItem(key);
			},
			key: (index: number) => {
				return localStorage.key(index);
			},
			clear: () => {
				return localStorage.clear();
			},
			length: localStorage.length,
		},
	],
	[
		StorageModeType.Session,
		{
			storageObj: sessionStorage,
			getItem: (key: string) => {
				return sessionStorage.getItem(key);
			},
			setItem: (key: string, value: string) => {
				return sessionStorage.setItem(key, value);
			},
			removeItem: (key: string) => {
				return sessionStorage.removeItem(key);
			},
			key: (index: number) => {
				return sessionStorage.key(index);
			},
			clear: () => {
				return sessionStorage.clear();
			},
			length: sessionStorage.length,
		},
	],
]);
