import '../../../Extensions/string.extention';
import { DocIconStruct } from 'src/app/modules/icon/data/icon-struct.model';
import { PermissionType } from './user-permission.type';

export class UserModel {
  id: string;
  username: string;
  fullname: string;
  title: string;
  enabled: boolean;
  email: string;
  phonenumber: string;
  hasPortrait?: boolean = false;
  hide?: boolean = false;
  preferences = new Preferences();
  tags?: string[]=[];
  roles: string[]=[];
  permissions?: PermissionType[];

  constructor(init?:Partial<UserModel>){
    Object.assign(this, init)
  } 

 public get friendlyName(): string {
    if (!this.fullname.isEmptyString()){return this.fullname}
    else if (!this.email.isEmptyString()){return this.email}
    else if (!this.phonenumber.isEmptyString()){return this.phonenumber}
    else return this.username;
  }

  public get friendlyNameWithStatus(): IRecipientStatusAndName {
    return {
      name: this.friendlyName,
      status: this.displayUserInfo,
    };
  }
	/* --------------------------- User Displayed Info -------------------------- */
	public displayedDetails: {
		title: string;
		shortTitle: (maxWords: number) => string;
		charsTitle: string;
		icon?: DocIconStruct;
	} = {
		title: '',
		shortTitle: (maxWords: number = Infinity) => this.displayedDetails.title.split(' ').slice(0, maxWords).join(' '),
		charsTitle: '',
	};

	get subTitle(): string {
		if (this.title) return this.title;
		else return this.username;
	}
	/* -------------------------------------------------------------------------- */
	displayUserInfo: string;
	displayUserInfoProps: { mainInfo: string; additionalInfo: string; iconTemplate: string; fullString: string };
	get valueToDisplay(): string {
		if (this.displayUserInfoProps) return this.displayUserInfoProps.mainInfo;
		else return this.username;
	}
}

export class Preferences {
  language: string;
  notifyEmail: boolean;
  notifySMS: boolean;
  notifyWhatsapp: boolean;
}

export interface IRecipientStatusAndName {
  status: string;
  name: string;
}
