import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  filter,
  forkJoin,
  fromEvent,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';
import { IColumnStruct } from './../data/config/column.config';

import { ColumnStruct } from '../data/config/column.config';
import { LazyTableConfigStruct } from '../data/config/lazy.config';
import { PagingConfigStruct, PagingStruct } from '../data/config/paging.config';
import { RowStateData } from '../data/config/row-state-data.model';
import { TableConst } from '../data/constants/table-constants.data';
import { TableEntryStruct, TableObjectKey } from '../data/types/table.types';
import { TableExportMode } from '../data/types/table-export.type';
import { SearchFilterChangeStruct } from '../data/types/table-filter.type';
import { TableConfig, TableConfigStruct } from '../data/types/table.data';
import {
  CellTemplateCollectorDirective,
  CellTemplateCollectorService,
} from '../directives/cell-template-collector.directive';
import { TableConnector } from '../state.controllers/table-connector';
import { EmptyTableResultsComponent } from '../sub-components/empty-table-results/empty-table-results.component';
import { GlobalTableMenuComponent } from '../sub-components/menus/global-table-menu/global-table-menu.component';
import { TablleRowMenuComponent } from '../sub-components/menus/tablle-row-menu/table-row-menu.component';
import { TableActionsComponent } from '../sub-components/table-actions/table-actions.component';
import { TablePaginatorComponent } from '../sub-components/table-paginator/table-paginator.component';
import { TableSharedModule } from '../table.module';
import { SkeletonLoaderDirective } from '../pipes/skeleton-loader.directive';
import {
  TableRowClickEvent,
  TableEventType,
} from '../data/types/table-event.type';
import {
  getDefaultTableShortcutsAndKeys,
  KeyboardShortcutType,
} from '../data/types/table-shortcuts.type';
import {
  TablePresets,
} from '../data/config/user-table-prefrences.model';
import { ExporterTool, ExportToType } from 'src/app/tools/exporter.tool';
import { TranslateService } from '@ngx-translate/core';
import { KeyboardKey } from 'src/app/tools/keyboard-keys.type';
import { LoaderComponent } from '../../Layouts/loader/loader.component';
import { DcExporterRowDataPickerDirective } from '../directives/dc-exporter-row-data-picker.directive';
import { IColumnSortStruct } from '../data/config/column-sort-struct.type';
import { DcExporterTool } from 'src/app/Extensions/tools/exporter.tool';
import { StoredTableColumn } from '../data/types/stored-table-column.model';
import { StoredTablePreset } from '../data/types/stored-table-preset.model';
import {
  IReportsQueryParams,
  SortableReportsTableColumn,
} from '../../reports/http/reports-query.http';
import { TableCheckedRowsController } from '../state.controllers/table-checked-rows.controller';
import { IStoredTableColumn } from '../data/types/user-table-store.schema';
import { HoverDirective } from 'src/app/Directives/hover.directive';
import { ReportsComponent } from '../../reports/reports.component';
import { StorageService } from 'src/app/Data/Services/storage.service';
import { StorageModeType } from 'src/app/Data/Services/storage.data/storage-mode.type';
import { pick } from 'src/app/Extensions/pick.logic';

const externalModules = [];
const matModules = [
  MatTableModule,
  MatCheckboxModule,
  MatTooltipModule,
  MatBadgeModule,
  MatMenuModule,
  MatSortModule,
];
const subComponents = [
  TableActionsComponent,
  GlobalTableMenuComponent,
  TablleRowMenuComponent,
  TablePaginatorComponent,
  EmptyTableResultsComponent,
  LoaderComponent,
];
const pipes = [CellTemplateCollectorDirective];
const directives = [SkeletonLoaderDirective, HoverDirective];

/**
 * Initializes the paginator for the table.
 * @param {MatPaginator} matPaginator - the paginator to initialize.
 * @returns None
 */
@Component({
  selector: 'generic-table',
  standalone: true,
  imports: [
    TableSharedModule,
    ...matModules,
    ...subComponents,
    ...pipes,
    ...directives,
    ...externalModules,
  ],
  providers: [],
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T extends TableEntryStruct>
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  protected isMobile = window.deviceService.isMobile;
  /* -------------------------------------------------------------------------- */
  @ViewChild(MatTable) matTable: MatTable<T>;
  @ViewChild('matRowContextTriggerMenu') matRowContextMenu: MatMenuTrigger;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  /* ------------------------------- Inputs ------------------------------ */
  @Input() data: T[] = [];
  @Input() rowsId: string;
  @Input() config: TableConfigStruct<T> | TableConfig<T>;
  @Input() columns: IColumnStruct<T>[] | ColumnStruct<T>[];
  @Input() pagingConfig: PagingConfigStruct<T> | PagingStruct<T>;
  @Input() lazyConfig: LazyTableConfigStruct<T>;
  @Input() columnDataRetriever: Record<keyof T, (row: T) => Observable<any>> =
    null;
  @Input() rowsCheckboxAllowedFn?: (row: RowStateData, ...args) => boolean =
    null;
  @Input() exportDateRange?: { fromDate: Date; toDate: Date } = null;

  /* --------------------------------- Outputs -------------------------------- */
  @Output() rowDblClicked$: EventEmitter<TableRowClickEvent<T>> =
    new EventEmitter<TableRowClickEvent<T>>();
  @Output() rowClicked$: EventEmitter<TableRowClickEvent<T>> = new EventEmitter<
    TableRowClickEvent<T>
  >();
  protected activeRow: TableRowClickEvent<T> = null;

  public checkedRowsController: TableCheckedRowsController<T> =
    TableCheckedRowsController.instance(this);

  /* ---------------------------------- State --------------------------------- */
  protected dataSource: MatTableDataSource<T> = new MatTableDataSource<T>([]);

  // Table Columns
  protected columns$: BehaviorSubject<ColumnStruct<T>[]> = new BehaviorSubject<
    ColumnStruct<T>[]
  >(undefined);
  public columnsData: ColumnStruct<T>[];
  private pinnedColumnCount: number;

  //Loader
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  protected lazyMode: boolean = false;
  protected queryResultsLength: number;

  // Table Presets
  protected tablePresetsModel: TablePresets = undefined;
  // Actual Table Preset
  protected actualTablePreset: StoredTablePreset = null;
  // Table Search and Date Filter
  public searchText: string = '';
  public dateFilterRange: { fromDate: Date; toDate: Date } = {
    fromDate: null,
    toDate: null,
  };

  // Table Rows
  tableRows: Map<TableObjectKey, RowStateData> = new Map<
    TableObjectKey,
    RowStateData
  >();
  tableDataStore: Map<TableObjectKey, T> = new Map<TableObjectKey, T>();

  // Table Connector (for triggering events from outside the table)
  protected tableConnector: TableConnector<T> = TableConnector.instance;

  /* -------------------------------- Row Menu -------------------------------- */
  protected menuPosition = {
    x: '0px',
    y: '0px',
  };
  // Table Sort Config
  sortConfig: IColumnSortStruct<T> = null;

  // Table identifier
  public get tableId(): string {
    return this.router?.url;
  }
  /* ------------------------------- Constructor ------------------------------ */
  constructor(
    protected cellTemplateCollector: CellTemplateCollectorService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private translateService: TranslateService,
    private storageService: StorageService
  ) {
    this.rowClicked$.subscribe((e) => {
      this.activeRow = e;
    });

    this.tableConnector.init(this);
  }

  tableInit: string = '';
  /* ----------------------------- Lifecycle Hooks ---------------------------- */
  ngOnInit(): void {
    if (!this.config) throw new Error('Table config is required');
    if (!this.pagingConfig) throw new Error('Table paging config is required');
    /* -------------------------------------------------------------------------- */

    // init table presets
    this.initTablePresets().subscribe((tablePresets) => {
      this.tablePresetsModel = tablePresets;

      // init table columns
      this.initColumns().subscribe(() => {
        // init lazy configurations (if exists)
        if (this.lazyConfig?.queryObservable) {
          this.lazyMode = true;
          this.dateFilterRange = { fromDate: new Date(new Date().setDate(new Date().getDate()-1)), toDate: null };
          this.applyQuery();
        } else {
          this.loadNotLazyData();
        }
      });
    });

    /* -------------------- Listen for table connector events ------------------- */

    // refresh table
    this.tableConnector.$refreshTable$.subscribe(() => {
      if (this.lazyMode) {
        this.applyQuery();
      }
    });
    this.tableInit = this.config.tableTitle;
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    if (!this.lazyMode) {
      if (this.config.enableSort) {
        if (!this.dataSource.sort && this.matSort) {
          // set custom sort function
          this.dataSource.sortData = (data, sort) => {
            return alphabeticallySort(data, sort);
          };

          this.dataSource.sort = this.matSort;

          //sort data
          this.dataSource.sort.sort({
            id: this.config.sortKey,
            start: 'asc',
            disableClear: false,
          });
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Paging Config
    if (
      changes['pagingConfig']?.currentValue !=
      changes['pagingConfig']?.previousValue
    ) {
      // init paging configurations
      this.pagingConfig = new PagingStruct(
        changes['pagingConfig']?.currentValue
      );
    }

    // Table Config
    if (!(this.config instanceof TableConfig)) {
      if (changes['config']?.currentValue != changes['config']?.previousValue) {
        // init table configurations
        this.config = new TableConfig(changes['config']?.currentValue, this);
      }
    }

    // Table Columns
    if (changes['columns']?.currentValue != changes['columns']?.previousValue) {
      if (changes['columns']?.currentValue) {
        this.columns$.next(changes['columns']?.currentValue);
      }
    }

    // Table Data and sort (if not in lazy mode)
    if (!this.lazyMode) {
      if (changes['data']?.currentValue != changes['data']?.previousValue) {
        this.loadNotLazyData(changes['data']?.currentValue);
      }
    }
  }

  ngOnDestroy(): void {
    DcExporterRowDataPickerDirective.resetTableRowsData(this.tableId);
  }

  /* ------------------------------- Pagination ------------------------------- */
  /**
   * Returns the current page number.
   * @returns {number} the current page number.
   */
  public get currentPage(): number {
    return this.pagingConfig.currentPage;
  }

  /* --------------------------------- Columns -------------------------------- */

  /**
   * Get the visible columns for the table.
   * @returns {ColumnStruct<T>[]} - The visible columns for the table.
   */
  protected get visibleColumns(): ColumnStruct<T>[] {
    return this.columns
      ?.filter((col) => col.state.visible)
      .sort((a, b) => a.state.index - b.state.index);
  }

  /**
   * Get the keys of the columns that are visible.
   * @returns {TableObjectKey[]} - The keys of the columns that are visible.
   */
  protected get visibleColumnKeys(): TableObjectKey[] {
    return this.visibleColumns
      ?.sort((a, b) => a.state.index - b.state.index)
      .map((col) => col.columnKey);
  }

  /**
   * Get the keys of the columns that are hidden.
   * @returns {TableObjectKey[]} - the keys of the columns that are hidden.
   */
  protected get hiddenColumnsKeys(): TableObjectKey[] {
    return this.columns
      ?.filter((col) => !col.state.visible)
      .map((col) => col.columnKey);
  }

  /**
   * Get the columns that are not layout columns.
   * @returns {ColumnStruct<T>[]} - the columns that are not layout columns.
   */
  protected get dataColumns(): ColumnStruct<T>[] {
    return this.columns?.sort((a, b) => a.state.index - b.state.index);
  }

  /**
   * Initializes the columns for the table.
   * @returns None
   */
  columns2?: Record<string, StoredTableColumn>;
  private initColumns(initEmpty: boolean = true): Observable<void> {
    return this.columns$.pipe(
      filter((columns) => Boolean(columns)),
      map((columns) => {
        // Spacing columns
        if (this.rowsId && !columns.some((c) => c.columnKey === this.rowsId)) {
          const rowIdColumn: ColumnStruct<T> = new ColumnStruct({
            isIdColumn: true,
            isLayoutColumn: false,
            columnKey: this.rowsId,
            title: '',
            classString: {
              headerCell: 'w-0',
              bodyCell: '',
            },
            state: {
              index: -Infinity,
              visible: false,
              columnWidth: 0,
            },
          });
          columns.push(rowIdColumn);
        }
        if (
          this.config.enableCheckboxes &&
          !columns.some((c) => c.columnKey === 'checkboxes')
        ) {
          const checkboxesColumn: ColumnStruct<T> = new ColumnStruct({
            isLayoutColumn: true,
            columnKey: 'checkboxes',
            title: '',
            pinned: !window.deviceService.isMobile, // sticky column (only on desktop)
            classString: {
              headerCell: 'w-8',
              bodyCell: '',
            },
            state: {
              index: -Infinity, // always first column
              visible: true,
            },
          });
          columns.push(checkboxesColumn);
        }
        if (
          this.config.rowActions &&
          !columns.some((c) => c.columnKey === 'actions')
        ) {
          const actionsColumn: ColumnStruct<T> = new ColumnStruct({
            isLayoutColumn: true,

            columnKey: 'actions',
            title: '',
            pinned: true,
            classString: {
              headerCell: 'min-w-[52px] w-[52px] px-2',
              bodyCell: 'min-w-[52px] w-[52px] px-2',
            },
            state: {
              index: +Infinity, // always last column
              visible: true,
            },
          });
          columns.push(actionsColumn);
        }

        const activePreset = this.tablePresetsModel?.getDefaultPreset();
        //Set columns visibility
        columns = columns.map((col) => {
          const column = new ColumnStruct(col);
          if (activePreset) {
            const columnInPreset = activePreset.columns.find(
              (c) => c.key === column.columnKey
            );
            if (columnInPreset) {
              column.state.visible = true;
              column.state.index = columnInPreset.index;
            }
          }
          return column;
        });
        this.columns2 = columns.reduce((acc, column) => {
          const storedTableCol: IStoredTableColumn =
            column as IStoredTableColumn;
          acc[column.columnKey] = new StoredTableColumn(
            column.columnKey,
            storedTableCol
          );
          return acc;
        }, {} as Record<string, StoredTableColumn>);

        let allColumns: StoredTableColumn[];
        if (!Boolean(this.columns)) this.columns2 = {};
        allColumns = Object.entries(this.columns2).map(
          ([columnKey, column]) => {
            return new StoredTableColumn(columnKey, column);
          }
        );
        // Set default sorted column
        if (this.config.enableSort) {
          const defaultSort: IColumnSortStruct<T> = this.config.defaultSort;
          const sortedColumn = allColumns.find((c) => {
            return c.columnKey === defaultSort?.defaultObjectKey;
          });
          const storedColumnKey: SortableReportsTableColumn =
            sortedColumn?.columnKey as SortableReportsTableColumn;
          if (sortedColumn) {
            this.sortConfig = {
              defaultObjectKey: storedColumnKey,
              objectKeys: defaultSort.objectKeys,
              direction: defaultSort.direction,
            };
          } else {
            this.sortConfig = this.config.defaultSort;
          }
        }

        this.reindexColumnsArray(columns, false);

        // Update the component's columns
        this.columns = [...columns];

        if (initEmpty)
          this.dataSource.data = this.initEmptyTable(
            this.visibleColumnKeys,
            this.pagingConfig.pageSize
          );
        this.pinnedColumnCount = this.columns.filter(
          (c) => c.pinned && !c.isLayoutColumn
        ).length;
        this.setTableKeyboardShortcuts();
      })
    );
  }

  /* -------------------------------------------------------------------------- */
  /*                              Global Listeners                              */
  /* -------------------------------------------------------------------------- */
  /**
   * Handles the global click event.
   * @param {MouseEvent} event - the mouse event object
   * @returns None
   */
  @HostListener('document:click', ['$event']) globalClickHandler(
    event: MouseEvent
  ) {
    if (event.target instanceof HTMLElement) {
      // Check if the click was outside the menu
      const target = event.target;
      const isMenu = target.closest('mat-menu');
      const isMenuTrigger = target.closest('button[mat-menu-trigger-for]');

      if (!isMenu && !isMenuTrigger) {
        this.matRowContextMenu?.closeMenu();
      }

      // Check if the click was outside the table
      const isTableBody = target.closest('tbody');
      if (!isTableBody) {
        this.activeRow = null;
      }
    }
  }

  /**----------------------------------   Export Table data   ------------------------------------*/

  /**
   * @important For this feature to work, you need to have query observable that returns the table entries from the server;
   * @description This function will export the table data to Excel file
   */
  public exportTableDataToExcel(): void {
    if (!this.columnDataRetriever || !this.lazyConfig.allTableEntriesObservable)
      throw new Error(
        'You need to provide a columnDataRetriever and allTableEntriesObservable in order to use this feature'
      );

    ReportsComponent.setLoader(true);
    // get all visible data columns
    const dataColumns = this.visibleColumns.filter(
      (column) => !(column.isLayoutColumn || column.isIdColumn)
    );
    const dataColumnIDs = dataColumns.map((column) => column.columnKey);
    const keysAndTitles = dataColumns.map((col) => ({
      key: col.columnKey,
      title: col.title,
    }));

    // - get all table data from api
    // - Fill the dataObsObject with the observables for each dataColumnIDs
    // - subscribe to the observables and fill the finalData object with the data
    this.lazyConfig
      .allTableEntriesObservable()
      .pipe(
        take(1),
        // fill the dataObsObject with the observables for each dataColumnIDs
        switchMap((tableData: T[]) => {
          const dataObsObject: Record<
            string,
            { [key: string]: Observable<any> }
          > = {};
          tableData.forEach((row) => {
            const rowId = row[this.rowsId].toString();
            dataObsObject[rowId] = dataColumnIDs.reduce((acc, columnId) => {
              if (this.columnDataRetriever.hasOwnProperty(columnId))
                return {
                  ...acc,
                  [columnId]: this.columnDataRetriever[columnId](row),
                };
              const columnGetter = this.columnsData.find(
                (col) => col.columnKey === columnId
              )?.getter;
              if (columnGetter)
                return { ...acc, [columnId]: of(columnGetter(row)) };
              return { ...acc, [columnId]: of('') };
            }, {});
          });
          return of(dataObsObject);
        }),
        mergeMap(
          (
            dataObsObject: Record<string, { [key: string]: Observable<any> }>
          ) => {
            return forkJoin(
              Object.keys(dataObsObject).map((rowId) => {
                return forkJoin(dataObsObject[rowId]).pipe(
                  map((data) => {
                    return {
                      id: rowId,
                      data,
                    };
                  })
                );
              })
            );
          }
        )
      )
      .subscribe((res) => {
        const dataArray: any[] = res.map((row) => row.data);
        // set the Excel file name
        const fromDate = this.exportDateRange?.fromDate
          ? this.exportDateRange.fromDate.toLocaleDateString()
          : '';
        const toDate = this.exportDateRange?.toDate
          ? this.exportDateRange.toDate.toLocaleDateString()
          : '';
        const fileName: string = `DocServerReport<${fromDate} - ${toDate}>`;
        // export the data
        DcExporterTool.exporter(dataArray, ExportToType.XLSX, {
          fileName,
          keysAndTitles,
        });
      });
  }

  /* ---------------------------- Row Element Data ---------------------------- */
  getRowElementDataSet(row: T | TableObjectKey): Record<string, any> {
    const id = typeof row === 'object' ? row[this.rowsId] : row;
    if (!id) return null;
    const rowElement = this.elementRef.nativeElement.querySelector(
      `tr[id="${id}"]`
    );
    if (rowElement) {
      return rowElement.dataset;
    }
    return null;
  }

  /* ---------------------------- Empty Table Page ---------------------------- */
  /**
   * Initializes an empty table with the given column keys and page size.
   * @param {TableObjectKey[]} columnKeys - the column keys for the table.
   * @param {number} pageSize - the number of rows in the table.
   * @returns {T[]} - the empty table.
   */
  public initEmptyTable<T>(
    columnKeys: TableObjectKey[],
    pageSize: number
  ): T[] {
    const emptyTable: Record<string, any>[] = [];
    const columnscolumnKeys: TableObjectKey[] = columnKeys;
    const emptyRowTemplate: Record<string, any> = columnscolumnKeys.reduce(
      (acc, col) => {
        return { ...acc, [col]: '' };
      },
      {}
    );

    for (let i = 0; i < pageSize; i++) {
      emptyTable.push(emptyRowTemplate);
    }

    return emptyTable as T[];
  }

  /**
   * Handles the page change event.
   * @param {PageEvent} pageEvent - the page change event.
   * @returns None
   */
  protected onPageChange(pageEvent: PageEvent) {
    this.pagingConfig.currentPage = pageEvent.pageIndex + 1; // pageIndex is zero based
    this.pagingConfig.pageSize = pageEvent.pageSize;
    this.pagingConfig.totalRows = pageEvent.length;

    if (this.lazyMode) {
      this.applyQuery();
    }
  }

  /* ------------------------- Searching and filtering ------------------------ */
  /**
   * Handles the changes to the search filter.
   * @param {SearchFilterChangeStruct} searchFilterChanges - the changes to the search filter.
   * @returns None
   */
  protected onSearchFilterChange(
    searchFilterChanges: SearchFilterChangeStruct
  ) {
    this.searchText = searchFilterChanges.search;
    this.dateFilterRange = searchFilterChanges.dateRange;
    this.pagingConfig.currentPage = 1;

    if (this.lazyMode) {
      this.applyQuery();
    } else {
      const filterValue = this.searchText;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  /**
   * Clears the filters and resets the page to the first page.
   * @returns None
   */
  protected onClearFilters(): void {
    if (this.lazyMode) {
      this.searchText = '';
      this.dateFilterRange = { fromDate: null, toDate: null };
      this.pagingConfig.currentPage = 1;
      this.applyQuery();
    } else {
      this.searchText = '';
      this.dateFilterRange = { fromDate: null, toDate: null };
      this.pagingConfig.currentPage = 1;
      this.dataSource.filter = '';
    }
  }

  protected onApplyPreset(preset: StoredTablePreset): boolean {
    //* Temp until we get view settings from the backend */
    // */ set Item in local storage on colunm check */
   this.columns.forEach((col) => {
      this.storageService.setItem(
        col.columnKey,
        col.state.visible,
        StorageModeType.Local
      );
    });
    return true
  }

  /* ---------------------------------- Lazy ---------------------------------- */
  /**
   * Apply the current query to the data source.
   * @returns None
   */

  protected applyQuery() {
    const pageSize = this.pagingConfig.pageSize;

    let sortParams: undefined | IColumnSortStruct<T> = undefined;
    if (this.sortConfig) {
      const { defaultObjectKey, direction, objectKeys } = this.sortConfig;
      sortParams = {
        defaultObjectKey: defaultObjectKey,
        direction: direction,
        objectKeys: objectKeys,
      };
    }

    const queryData: IReportsQueryParams = {
      query: this.searchText,
      page: this.currentPage,
      size: pageSize,
      fromDate: this.dateFilterRange.fromDate,
      toDate: this.dateFilterRange.toDate,
      sort: sortParams,
    };

    this.loading$.next(true);
    this.lazyConfig
      .queryObservable(queryData)
      .subscribe((data: { rows: T[]; totalCount?: number }) => {
        if (!data) return;
        this.queryResultsLength = data.totalCount;
        this.pagingConfig.totalRows = data.totalCount;
        // clean data from any unwanted properties
        this.dataSource.data = data.rows.map((row, index) => {
          const pageSize = this.pagingConfig.pageSize;
          const rowIndex = (this.currentPage - 1) * pageSize + index;
          const pageIndex = Math.ceil(
            (rowIndex + 1) / this.pagingConfig.pageSize
          );

          if (this.rowsId) this.initRowGetters(row, pageIndex, rowIndex);

          return pick(row, this.visibleColumnKeys);
        });
        this.loading$.next(false);
      });
  }

  /**
   * Toggle the visibility of the given column.
   * @param {ColumnStruct<T>} toggledColumn - the column to toggle
   * @returns None
   */
  protected onVisibleColumnsChanged(visibleColumnsEvent: MatSelectChange) {
    const visibleColumns = visibleColumnsEvent.value as ColumnStruct<T>[];
    const visibleColumnKeys = visibleColumns.map((col) => col.columnKey);

    this.columns.forEach((col) => {
      if (visibleColumnKeys.includes(col.columnKey) && !col.state.visible) {
        col.state.visible = true;
        col.state.index -= TableConst.VISIBLE_INVISIBLE_COLUMNS_DELTA;
      } else if (
        !visibleColumnKeys.includes(col.columnKey) &&
        col.state.visible
      ) {
        col.state.visible = false;
        col.state.index += TableConst.VISIBLE_INVISIBLE_COLUMNS_DELTA;
      }
    });

    // scroll to the first visible column
    visibleColumnsEvent.source._keyManager.setActiveItem(0);
  }

  /**
   * A function that's called when the user drags a column header to a new position.
   * @param {CdkDragDrop<any, any, any>} event - The drag event.
   */
  protected onColumnsOrderChange(event: CdkDragDrop<any, any, any>) {
    const pinnedCount = this.pinnedColumnCount;
    const draggableColumnsArr = event.container.data.sort(
      (c1, c2) => c1.state.index - c2.state.index
    );
    const srcKey: TableObjectKey =
      draggableColumnsArr[event.previousIndex - pinnedCount].columnKey;
    const destKey: TableObjectKey =
      draggableColumnsArr[event.currentIndex - pinnedCount].columnKey;
    const srcElement = this.columns.find((col) => col.columnKey === srcKey);
    if (!srcElement || !destKey) return;

    const sortedColumns = this.columns.sort(
      (c1, c2) => c1.state.index - c2.state.index
    );
    sortedColumns.moveBefore(
      srcElement,
      (col: IColumnStruct<T> | ColumnStruct<T>) => col.columnKey === destKey
    );

    this.reindexColumnsArray(sortedColumns, true);
  }

  /* ---------------------------------- Rows ---------------------------------- */
  /**
   * Gets the id of the row.
   * @param {T} row - the row to get the id of.
   * @param {number} rowIndex - the index of the row.
   * @returns The id of the row.
   */
  protected getRowId(row: T, rowIndex: number): string {
    if (row && row[this.rowsId]) return row[this.rowsId] as string;
    return rowIndex.toString();
  }

  /**
   * Emits an event when a row is clicked.
   * @param {T} row - the row that was clicked.
   * @param {number} rowIndex - the index of the row that was clicked.
   * @returns None
   */
  protected onRowClick(row: T, rowIndex: number) {
    const rowData = this.generateRowStateData(row, rowIndex);
    if (!rowData) return;
    this.rowClicked$.emit({ ...rowData, type: TableEventType.RowClick });
  }

  /**
   * Emits a TableEventType.RowDoubleClick event with the row data.
   * @param {any} row - the row that was double clicked.
   * @param {number} rowIndex - the index of the row that was double clicked.
   * @returns None
   */
  protected onRowDoubleClick(row: any, rowIndex: number) {
    const rowData = this.generateRowStateData(row, rowIndex);
    if (!rowData) return;

    this.rowDblClicked$.emit({
      ...rowData,
      type: TableEventType.RowDoubleClick,
    });
  }

  /* -------------------------------- Row Menu -------------------------------- */ /**
   * Opens the context menu for the given row.
   * @param {any} row - the row to open the context menu for.
   * @param {number} rowIndex - the index of the row to open the context menu for.
   * @param {MatMenuTrigger} menuTrigger - the menu trigger to open the menu for.
   * @param {MouseEvent} event - the mouse event that triggered the context menu.
   */
  protected openRowMenu(
    row: any,
    rowIndex: number,
    menuTrigger: MatMenuTrigger,
    event: MouseEvent
  ) {
    event.preventDefault();
    event.stopPropagation();

    if (TableConnector.instance.getSelectedRowsIDs().length > 1) return;

    menuTrigger.closeMenu();

    this.menuPosition.x = event.clientX + 'px';
    this.menuPosition.y = event.clientY + 'px';

    menuTrigger.updatePosition();

    const rowData = this.generateRowStateData(row, rowIndex);
    if (!rowData) return;
    let rowClickEvent: TableRowClickEvent<T> = {
      ...rowData,
      type: TableEventType.RowContextMenuEvent,
    };

    menuTrigger.menuData = rowClickEvent;
    this.rowClicked$.emit(rowClickEvent);

    setTimeout(() => {
      menuTrigger.openMenu();
    }, 20); // this is a hack to make sure the menu is opened after the position is updated
  }

  /* --------------------------- Pagination Methods --------------------------- */
  /**
   * Sets the paginator for the data source.
   * @param {MatPaginator} matPaginator - the paginator to set for the data source.
   * @returns None
   */
  protected onPaginatorInit(matPaginator: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = matPaginator;
    }
  }

  /**----------------------------------   Column Sorting   ------------------------------------*/
  protected hoveredSortColumn: SortableReportsTableColumn = null;

  protected toggleSortDirection(col: IColumnStruct<T> | ColumnStruct<T>) {
    if (this.sortConfig) {
      this.sortConfig.defaultObjectKey = col.objectKey;
      this.sortConfig.direction =
        this.sortConfig.direction === 'asc' ? 'desc' : 'asc';
      this.config.defaultSort = this.sortConfig;
      this.cdr.markForCheck();
      this.tableConnector.refreshTable();
    }
  }

  /**
   * Reindexes the columns array.
   * @param {(IColumnStruct<T> | ColumnStruct<T>)[]} [columns] - the columns array to reindex.
   * @param {boolean} [afterReorder=false] - whether or not the reorder was initiated by the user.
   * @returns None
   */
  private reindexColumnsArray(
    columns?: (IColumnStruct<T> | ColumnStruct<T>)[],
    afterReorder: boolean = false
  ): void {
    if (!columns) columns = this.columns;
    //Set columns indexes
    const visiblesCount = columns.filter((c) => c.state.visible).length;
    let invisibleIndex =
      TableConst.VISIBLE_INVISIBLE_COLUMNS_DELTA + visiblesCount;
    columns = columns.map((col, index) => {
      if (!afterReorder) {
        if (col.state.index == undefined) {
          if (col.state.visible) {
            col.state.index = index;
          } else {
            col.state.index = invisibleIndex++;
          }
        }
      } else {
        if (col.state.visible) {
          col.state.index = index;
        } else {
          col.state.index = invisibleIndex++;
        }
      }
      return col;
    });

    //Sort columns by index
    columns = [...columns].sort((a, b) => a.state.index - b.state.index);

    this.columns = this.columnsData = columns;
  }

  /**----------------------------------   Loaders   ------------------------------------*/
  //   private loadTablePreset(): Observable<StoredTablePreset> {
  // 	// if the table is not allowed to store, return empty preset
  // 	if (this.config.allowStoreTable) {
  // 	  // get the table preset from the storage
  // 	  const userStoredTables: UserStoredTables = AuthService.currentUser.preferences.tables;
  // 	  if (userStoredTables) {
  // 		const tablePresets = userStoredTables.getTablePresets(this.tableId);
  // 		if (tablePresets) {
  // 		  // set the actual table preset
  // 		  this.actualTablePreset = tablePresets.defaultPresetModel;
  // 		  if (this.actualTablePreset) {
  // 			this.searchText = this.actualTablePreset.globalSearch;
  // 			this.dateFilterRange = this.actualTablePreset.filter;
  // 		  }
  // 		  if (this.actualTablePreset) return of(this.actualTablePreset);
  // 		}
  // 	  }
  // 	}
  // 	return of(new StoredTablePreset(StoredTablePreset.generateTablePresetID(), {}, false));
  //   }

  /* -------------------------------------------------------------------------- */
  /*                                Initializers                                */
  /* -------------------------------------------------------------------------- */

  /**
   * Initializes the table presets for the table.
   * @returns {Observable<TablePresets>}
   */
  private initTablePresets(): Observable<TablePresets> {
    if (this.config.enableTablePresets == false)
      return of(new TablePresets(this.router.url));
    return of(undefined);
    // Note: Docserve is not using user presets
    // const tableId = this.router.url;
    // if (this.config.enableTablePresets != true) return of(new TablePresets(tableId));
    // let user_ = this.storageService.getItem(StorageKeys.AuthServiceKeys.KeyCurrentUser);
    // let user: UserModel;
    // if (user_) user = new UserModel(user_);
    // if (!user) return of(new TablePresets(tableId));
    // const userPreferences = user.preferences;
    // const tablePresets = userPreferences?.tables?.allTables.get(tableId);
    // if (tablePresets) {
    // 	const defaultPreset = tablePresets.getDefaultPreset();
    // 	if (defaultPreset) {
    // 		// init search and date filters
    // 		this.searchText = defaultPreset.globalSearch || '';
    // 		if (defaultPreset.filter && defaultPreset.filter.fromDate && defaultPreset.filter.toDate)
    // 			this.dateFilterRange = { fromDate: defaultPreset.filter.fromDate, toDate: defaultPreset.filter.toDate };
    // 	}
    // 	return of(tablePresets);
    // } else {
    // 	// create an empty table presets model
    // 	return of(new TablePresets(tableId));
    // }
  }

  /**
   * Loads the data into the table.
   * @param {T[]} dataSrc - the data to load into the table.
   * @returns None
   */
  private loadNotLazyData(dataSrc?: T[]): void {
    this.loading$.next(true);
    const data = dataSrc ? dataSrc : this.data;

    if (Array.isArray(data)) {
      if (
        Object.entries(data).every(([_, value]) => value?.toString() === '')
      ) {
        this.updateAllData([]);
        this.loading$.next(false);
        return;
      }

      if (this.matSort)
        this.updateAllData(alphabeticallySort(data, this.matSort));

      if (this.matTable) {
        this.matTable.renderRows();
        this.loading$.next(false);
      }

      this.cdr.detectChanges();
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                                   Methods                                  */
  /* -------------------------------------------------------------------------- */
  /**
   * Updates the data source with the given data.
   * @param {T[]} data - the data to update the data source with.
   * @returns None
   */
  private updateAllData(data: T[]) {
    this.dataSource.data = data;

    // this.rowsController.reset();
    data.forEach((row, rowIndex) => {
      const pageIndex = Math.ceil((rowIndex + 1) / this.pagingConfig.pageSize);
      if (this.rowsId) this.initRowGetters(row, pageIndex, rowIndex);
    });
  }

  /**
   * Initializes the row getters for the given item.
   * @param {T} item - the item to initialize the row getters for.
   * @param {number} index - the index of the item in the data array.
   * @returns None
   */
  private initRowGetters(item: T, page: number, index: number) {
    const rowId = item[this.rowsId] as string;
    if (!rowId) return;
    this.tableDataStore.set(rowId, item);
    const colsWithGetters = this.columnsData.filter((col) => col.getter);

    const row = colsWithGetters.reduce((acc, col) => {
      const getter = col.getter;
      const value = getter(item);
      return { ...acc, [col.columnKey]: value };
    }, {});

    if (this.tableRows.has(rowId)) {
      const rowData = this.tableRows.get(rowId);
      rowData.data = row;
      rowData.index = index;
      rowData.page = page;
      return;
    }
    const rowData: RowStateData = new RowStateData({
      id: rowId,
      data: item,
      index,
      page,
    });
    this.tableRows.set(rowId, rowData);
  }

  public get allTableRowIds(): TableObjectKey[] {
    return Array.from(this.tableRows.entries())
      .sort((a, b) => a[1].index - b[1].index)
      .map((row) => row[0]);
  }

  /**
   * Gets the data for a specific row and column.
   * @param {number} rowIndex - the index of the row to get data for.
   * @param {ColumnStruct<T>} col - the column to get data for.
   * @returns {Record<string, any>} the data for the row and column.
   */
  protected getCustomRowData(row: T, col: ColumnStruct<T>): any {
    return this.tableRows.get(row[this.rowsId] as string)?.data[col.columnKey];
  }

  /**
   * Generates the data for a row in the table.
   * @param {T} row - the row to generate data for.
   * @param {number} rowIndex - the index of the row.
   * @returns {RowStateData} - the data for the row.
   */
  private generateRowStateData(row: T, rowIndex: number): RowStateData {
    const rowId = row[this.rowsId] as string;
    const rowData = this.tableRows.get(rowId);

    if (!this.lazyMode) {
      rowData.id = row[this.rowsId];
      rowData.data = row;
    }
    return rowData;
  }

  /* ------------------------------ Table Exports ----------------------------- */
  /**
   * Exports the table data to a file.
   * @param {TableExportMode} mode - The mode to export the table data.
   * @param {ExportToType} type - The type of file to export the table data to.
   * @param {TableObjectKey[]} columns - The columns to export the table data to.
   * @param {string} fileName - The name of the file to export the table data to.
   * @returns None
   */
  protected onExportTo(props: {
    mode: TableExportMode;
    type: ExportToType;
    columns: { objecyKey: TableObjectKey; title: string; visible: boolean }[];
    fileName: string;
  }) {
    const { mode, type, columns, fileName } = props;
    switch (mode) {
      case TableExportMode.AllTable:
        if (this.lazyMode) {
          // retrieve all data
          const objectKeys = columns.map((col) => col.objecyKey);
          const colsWithGetters = this.columnsData.filter(
            (col) => col.getter && objectKeys.includes(col.columnKey)
          );

          const selectedRowsIds = this.checkedRowIDs;
          const dataToExport = selectedRowsIds.reduce((acc, rowId) => {
            const row = this.tableDataStore.get(rowId);
            if (!row) return acc;

            const rowWithGetters = colsWithGetters.reduce((acc, col) => {
              acc[col.columnKey] = col.getter(row);
              return acc;
            }, {});

            acc.push({ ...row, ...rowWithGetters });
            return acc;
          }, []);

          ExporterTool.exporter(dataToExport, type, {
            fileName: fileName
              ? fileName
              : this.translateService.instant(this.config.tableTitle),
            keysAndTitles: columns.map((col) => ({
              key: col.objecyKey,
              title: col.title,
            })),
          });
        } else {
          // retrieve data from table
          const data = this.dataSource.data;
          ExporterTool.exporter(data, type, {
            fileName: fileName
              ? fileName
              : this.translateService.instant(this.config.tableTitle),
            keysAndTitles: columns.map((col) => ({
              key: col.objecyKey,
              title: col.title,
            })),
          });
        }
        break;
      case TableExportMode.SelectedRows:
        // TODO: implement selected rows export
        // retrieve selected rows

        break;
    }
  }

  /* ----------------------------- Table Shortcuts ---------------------------- */
  /**
   * Sets up keyboard shortcuts for the table.
   * @returns None
   */
  private setTableKeyboardShortcuts() {
    const keyboardEventKeys = getDefaultTableShortcutsAndKeys();
    fromEvent(document, 'keydown')
      .pipe(
        filter((keyEvent: KeyboardEvent) =>
          keyboardEventKeys.hasValue(keyEvent.code as KeyboardKey)
        )
      )
      .subscribe((keyEvent: KeyboardEvent) => {
        for (let k of keyboardEventKeys) {
          if (k[1] === keyEvent.code) {
            this.emitKeyboardEvent(k[0] as KeyboardShortcutType);
          }
        }
      });
  }

  /**
   * Emits a keyboard event of the given type.
   * @param {KeyboardShortcutType} event - the type of keyboard event to emit.
   * @returns None
   */
  private emitKeyboardEvent(event: KeyboardShortcutType): void {
    if (!event) return;
    switch (event) {
      case KeyboardShortcutType.DeselectActiveRow:
        this.activeRow = null;
        break;
      case KeyboardShortcutType.CloseOpenedMenu:
        this.matRowContextMenu.closeMenu();
        break;
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                              Table Checkboxes                              */
  /* -------------------------------------------------------------------------- */
  /* ----------------------------- Row Checkboxes ----------------------------- */
  protected isRowChecked(row: T | TableObjectKey, rowIndex: number): boolean {
    const rowElementData = this.getRowElementDataSet(row);
    if (rowElementData) {
      const rowId = typeof row === 'object' ? row[this.rowsId] : row;
      const rowData = this.tableRows.get(rowId as string);
      rowData.page = this.pagingConfig.currentPage;
      rowData.index = rowIndex;
      rowElementData['checked'] = rowData.checked;
      return rowData.checked;
    }
    return false;
  }

  protected toggleRowChecked(
    row: T | TableObjectKey,
    checked: boolean = undefined
  ): void {
    const rowElementData = this.getRowElementDataSet(row);
    if (rowElementData) {
      const checkedRes = rowElementData['checked'] === 'true';
      rowElementData['checked'] = checked != undefined ? checked : !checkedRes;

      const rowId = typeof row === 'object' ? row[this.rowsId] : row;
      this.tableRows.get(rowId as string).checked =
        checked != undefined ? checked : !checkedRes;
    }
  }

  /* ----------------------------- Page Checkboxes ---------------------------- */
  protected allPageChecked(): boolean {
    const pageRows = this.tableRows
      .valuesArray()
      .filter((row) => row.page === this.pagingConfig.currentPage);
    return pageRows.length > 0 && pageRows.every((row) => row.checked);
  }

  protected togglePageChecked(
    page: number = this.pagingConfig.currentPage,
    checked: boolean
  ): void {
    const pageRows = this.tableRows
      .valuesArray()
      .filter((row) => row.page === page);
    pageRows.forEach((row) => {
      this.toggleRowChecked(row.id, checked);
    });
  }
  /* ------------------------- Global Checkbox Methods ------------------------ */
  public resetCheckedRows(event: 'all' | 'page'): void {
    if (event === 'all') {
      this.tableRows.valuesArray().forEach((row) => (row.checked = false));
    }
    if (event === 'page') {
      this.tableRows
        .valuesArray()
        .filter((row) => row.page === this.pagingConfig.currentPage)
        .forEach((row) => (row.checked = false));
    }
  }

  public get checkedRowsCount(): number {
    return this.tableRows.valuesArray().filter((row) => row.checked).length;
  }

  public get checkedRowsDataStruct(): RowStateData[] {
    return this.tableRows.valuesArray().filter((row) => row.checked);
  }

  public get checkedRowIDs(): TableObjectKey[] {
    return Array.from(this.tableRows.entries())
      .filter(([key, value]) => value.checked)
      .sort((a, b) => a[1].index - b[1].index)
      .map(([key, value]) => key);
  }

  /* -------------------------- Table loader control -------------------------- */

  private static _loaderVisible = false;

  protected get loaderVisible(): boolean {
    return TableComponent._loaderVisible;
  }

  public static setLoader(visible: boolean, message?: string) {
    TableComponent._loaderVisible = visible;
    if (message) TableComponent._message = message;
  }

  protected static _message: string;
  /**
   * The message to display in the table-loader.
   * @protected
   */
  protected get message(): string {
    return TableComponent._message;
  }
}

/**
 * Sorts the data in the table according to the active sort.
 * @param {T[]} data - the data to sort
 * @param {MatSort} sort - the sort object
 * @returns {T[]} the sorted data
 */
function alphabeticallySort<T>(data: T[], sort: MatSort) {
  if (!sort.active || sort.direction === '') return data;
  const sortedData = data.sort((a, b) => {
    if (sort.direction === 'asc')
      return a[sort.active]
        ?.toString()
        ?.localeCompare(b[sort.active]?.toString());
    if (sort.direction === 'desc')
      return b[sort.active]
        ?.toString()
        ?.localeCompare(a[sort.active]?.toString());
  });
  return sortedData;
}
