import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { SidenavComponent } from 'src/app/modules/Layouts/sidenav/sidenav.component';
import { BrowserModule } from '@angular/platform-browser';
import { AuthGuardService } from './Data/Services/auth-guard.service';
import { CompanySettingsComponent } from './modules/company-settings/company-settings.component';

export const AppRoutes: Routes = [
  {path: 'login', component:LoginComponent, pathMatch: 'full'},
  {path:'', redirectTo: 'reports', pathMatch: 'prefix'},


   {
     path: '', canActivate:[AuthGuardService] ,component: SidenavComponent, pathMatch: 'prefix',
     children:[
      {path:'reports', component: ReportsComponent, pathMatch: 'prefix' },
      {path: 'company-settings', component: CompanySettingsComponent, pathMatch: 'prefix'}
    ]
  },

  {path:'**', redirectTo: 'reports', pathMatch: 'prefix'}, //this route needs to stay the last resort.
];


