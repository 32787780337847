String.prototype.isEmptyString = function (this: string): boolean {
	return this == undefined || this == null || this.length == 0 || this.toString() == '';
};

String.prototype.insert = function (this: string, index: number, text: string): string {
	return this.substring(0, index) + text + this.substring(index, this.length);
};
String.prototype.splitInTwo = function (seperator) {
	let seperatorIndex = this.indexOf(seperator);
	if (seperatorIndex == -1) return { firstPart: this, secondPart: '' };
	return { firstPart: this.substr(0, seperatorIndex).trim(), secondPart: this.substr(seperatorIndex + 1).trim() };
};
String.prototype.toParams = function () {
	if (!this) return null;
	let params = {};
	for (let param of this.split('&')) {
		let keyValue = param.splitInTwo('=');
		params[keyValue.firstPart] = keyValue.secondPart;
	}
	return params;
};
String.prototype.fromBase64 = function () {
	let output = this.replace(/-/g, '+').replace(/_/g, '/');
	switch (output.length % 4) {
		case 0: {
			break;
		}
		case 2: {
			output += '==';
			break;
		}
		case 3: {
			output += '=';
			break;
		}
		default: {
			throw new Error('Illegal base64 string!');
		}
	}
	return decodeURIComponent(
		Array.prototype.map
			.call(window.atob(this), c => {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(''),
	);
};

// export function matchAll(pattern, haystack) {
// 	let regex = new RegExp(pattern, 'g');
// 	let matches = [];

// 	let match_result = haystack.match(regex);

// 	for (let index in match_result) {
// 		let item = match_result[index];
// 		matches[index] = item.match(new RegExp(pattern));
// 	}
// 	return matches;
// }
