import { Directive, Input, ViewContainerRef, OnInit, TemplateRef } from '@angular/core';
import { DeviceType } from '../Data/Services/device/device-service.service';

@Directive({
	selector: '[dcDevice]',
	standalone: true,
	providers: [],
})
export class DeviceDirective implements OnInit {
	@Input() dcDevice: DeviceType[] | DeviceType | true | false;

	constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

	ngOnInit() {
		const deviceType = window.deviceService.deviceType;
		if (typeof this.dcDevice === 'boolean') {
			if (this.dcDevice) {
				this.viewContainerRef.createEmbeddedView(this.templateRef);
			} else {
				this.viewContainerRef.clear();
			}
			return;
		}

		if (!Array.isArray(this.dcDevice)) {
			this.dcDevice = [this.dcDevice];
		}

		if (this.dcDevice.includes(deviceType)) {
			this.viewContainerRef.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainerRef.clear();
		}
	}
}
