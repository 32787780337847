<ng-container *ngIf="lazyMode; else SimplePagination">
	<div
		class="table-paginator-container"
		[class.mobile]="isMobile"
		*ngIf="pagingConfig as paging"
	>
		<mat-paginator
			class="mat-table-paginator"
			[pageIndex]="paging.currentPage - 1"
			[length]="paging.totalRows"
			[pageSize]="paging.pageSize"
			[hidePageSize]="true"
			[showFirstLastButtons]="true"
			[disabled]="disable"
			(page)="onPageChange($event)"
		>
		</mat-paginator>
	</div>
</ng-container>

<ng-template #SimplePagination>
	<div
		class="table-paginator-container"
		[class.mobile]="isMobile"
		*ngIf="pagingConfig as paging"
	>
		<mat-paginator
			class="mat-table-paginator w-full"
			[pageSize]="paging.pageSize"
			[hidePageSize]="true"
			[showFirstLastButtons]="true"
			[disabled]="disable"
			(page)="onPageChange($event)"
		>
		</mat-paginator>
	</div>
</ng-template>
