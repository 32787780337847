import { DeviceService } from 'src/app/Data/Services/device/device-service.service';
// Angular modules
import { Direction } from '@angular/cdk/bidi';
import { Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

// External modules
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { StorageKeys } from 'src/app/Data/Services/storage.data/storage-keys.data';
// import { FORCE_MODE, FORCE_PARAMS } from '../DevTools/env';

// Models
// import { StoredFile } from 'src/app/Utilities/PDF.utils/models/stored-file';

// Services
import { FormControl } from '@angular/forms';
import { AppInjector } from 'src/app/app.component';
import { LanguageService } from 'src/app/Data/Services/language.service';
import { StorageService } from './storage.service';

declare var require: any;
declare global {
	interface Window {
		FB: any;
	}
}

@Injectable({
	providedIn: 'root',
})
export class GlobalsService {
	forceCollapseSideBarMenu: Subject<boolean> = new Subject<boolean>();

	public static ADMIN_COMPANY_NAME = 'admin';
	public static DEBUG_COMPANY_NAME = 'debug';

	public static readonly GoldenRatio: number = 1.61803399;

	public static DeactivateOverride: boolean = false;

	private static REGEX_PHONE_ISRAEL =
		/^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/;
	private static REGEX_PHONE_USA = '^[2-9][0-9]{9}$';
	public static REGEX_PHONE_INTERNATIONAL = '^\\+[0-9]{11,12}$';
	private static REGEX_EMAIL = "^\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$";

	static disableKeyboardListeners: boolean = false;

	//global styles variables
	overflow$: string;
	static mainContentDimensions: [width: string, height: string];

	setGlobalOverflow(overflow: string): void {
		// this.overflow$ = overflow;
	}

	styleString$: string;
	styles: string[] = [];

	setGlobalStyle(style: string): void {
		if (!this.styles.includes(style)) this.styles.push(style);
		this.styleString$ = this.styles.join('; ');
	}

	public stopSync: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

	public pauseSync(pause: boolean) {
		this.stopSync.next(pause);
	}

	public static redirectTo(uri: string, router: Router) {
		router.navigateByUrl('/', { skipLocationChange: true }).then(() => router.navigate([uri]));
	}

	public static checkPhoneNumber(value: string): PhoneNumberType {
		var israel = new RegExp(GlobalsService.REGEX_PHONE_ISRAEL);
		if (israel.test(value)) return PhoneNumberType.Israel;
		var usa = new RegExp(GlobalsService.REGEX_PHONE_USA);
		if (usa.test(value)) return PhoneNumberType.USA;
		var international = new RegExp(GlobalsService.REGEX_PHONE_INTERNATIONAL);
		if (international.test(value)) return PhoneNumberType.International;
		return undefined;
	}

	public static checkEmailAddress(value: string): boolean {
		var email = new RegExp(GlobalsService.REGEX_EMAIL);
		return email.test(value);
	}

	public static get frontendVersion(): string {
		return require('src/assets/version.config.json').version;
	}

	constructor(
		private sanitizer: DomSanitizer,
		private translateService: TranslateService,
		private languageService: LanguageService,
		private injector: Injector,
		public storage: StorageService,
	) {
		this.injector = injector;

		// react to language change globally
		this.languageService.currentLanguage$.subscribe(language => {
			this.allowedCreatorText = language.locale_code == 'he' ? GlobalsService.heCycleCreator.title : GlobalsService.enCycleCreator.title;
			GlobalsService.getAllowedCreatorText = this.allowedCreatorText;
		});
	}

	static readonly byteUnits = ['bytes', 'kb', 'mb', 'gb'];

	public static getFileSize(bytes, decimals: number = 2): [number, string] {
		if (bytes === 0) {
			return [0, GlobalsService.byteUnits[0]];
		}
		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals;
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return [parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), GlobalsService.byteUnits[i]];
	}

	bindToStyle(parameters) {
		var styleBind = '';
		for (var key in parameters) {
			styleBind += `--${GlobalsService.camelToHyphen(key)}: ${parameters[key]};`;
		}
		return this.sanitizer.bypassSecurityTrustStyle(styleBind);
	}

	public static camelToHyphen(str: string) {
		var hyphenized = '';
		for (var i = 0; i < str.length; i++) {
			if (i != 0 && str[i] >= 'A' && str[i] <= 'Z') {
				hyphenized += '-';
			}
			hyphenized += str[i].toLowerCase();
		}
		return hyphenized;
	}

	addStr(str: string, index, toAdd) {
		return str.substring(0, index) + toAdd + str.substring(index, str.length);
	}

	public static convertToBoolProperty(val: any): boolean {
		if (typeof val === 'string') {
			val = val.toLowerCase().trim();

			return val === 'true' || val === '';
		}
		return !!val;
	}

	public static israeliDateTime(date: Date, time: boolean = false) {
		var millisec = +date;
		millisec = millisec - new Date().getTimezoneOffset() * 60000;
		date = new Date(millisec);
		var toReturn =
			GlobalsService.pad(date.getDate(), 2) + '/' + GlobalsService.pad(date.getMonth() + 1, 2) + '/' + GlobalsService.pad(date.getFullYear(), 4);
		if (time) toReturn += ' ' + GlobalsService.pad(date.getHours(), 2) + ':' + GlobalsService.pad(date.getMinutes(), 2);
		return toReturn;
	}

	public static usDateTime(date: Date, time: boolean = false) {
		var millisec = +date;
		millisec = millisec - new Date().getTimezoneOffset() * 60000;
		date = new Date(millisec);
		var toReturn =
			GlobalsService.pad(date.getMonth() + 1, 2) + '/' + GlobalsService.pad(date.getDate(), 2) + '/' + GlobalsService.pad(date.getFullYear(), 4);
		if (time) toReturn += ' ' + GlobalsService.pad(date.getHours(), 2) + ':' + GlobalsService.pad(date.getMinutes(), 2);
		return toReturn;
	}

	public static hoursMinutes(date: Date, calcDiffs: boolean = false) {
		if (calcDiffs) {
			var millisec = +date;
			millisec = millisec - new Date().getTimezoneOffset() * 60000;
			date = new Date(millisec);
		}
		return GlobalsService.pad(date.getHours(), 2) + ':' + GlobalsService.pad(date.getMinutes(), 2);
	}

	public static millisecondsFromIsraeliDateTime(date: string) {
		var d = new Date();
		var splitted = date.split(' ')[0].split('/');
		d.setFullYear(+splitted[2]);
		d.setMonth(+splitted[1] - 1);
		d.setDate(+splitted[0]);
		return +d;
	}

	public static pad(num: number, size: number): string {
		let s = num + '';
		while (s.length < size) s = '0' + s;
		return s;
	}

	public static capitalize(word: string): string {
		return word.charAt(0).toLocaleUpperCase() + word.substring(1);
	}

	// public static StoredFilePDFToBlob(file: StoredFile) {
	// 	const byteChars = window.atob(file.fileEncode);
	// 	const byteNum = new Array(byteChars.length);
	// 	for (let i = 0; i < byteChars.length; i++) {
	// 		byteNum[i] = byteChars.charCodeAt(i);
	// 	}
	// 	const byteArray = new Uint8Array(byteNum);
	// 	return new Blob([byteArray], { type: 'application/pdf;charset=utf-8' });
	// }

	// eslint-disable-next-line getter-return
	public get dir(): Direction {
		if (this.languageService.CurrentDir == 'rtl' || this.languageService.CurrentDir == 'ltr') {
			return this.languageService.CurrentDir;
		}
	}

	public static get dir(): Direction {
		return AppInjector.get(GlobalsService).dir;
	}

	public static get lang(): 'he' | 'en' {
		return AppInjector.get(LanguageService).CurrentDir === 'rtl' ? 'he' : 'en';
	}

	public get currentLang(): { he: boolean; en: boolean } {
		const lang = AppInjector.get(LanguageService).CurrentLanguage.locale_code === 'he' ? 'he' : 'en';
		return { he: lang === 'he', en: lang === 'en' };
	}

	public static get currentLang(): { he: boolean; en: boolean } {
		const lang = AppInjector.get(LanguageService).CurrentLanguage.locale_code === 'he' ? 'he' : 'en';
		return { he: lang === 'he', en: lang === 'en' };
	}

	public static DownloadBlob(blob: Blob, fileName: string) {
		if (this.GetMobileOperatingSystem() == 'iOS') {
			var FileSaver = require('file-saver');
			FileSaver.saveAs(blob, `${fileName}`); //${Date.now().toString()}
		} else {
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);

			link.download = `${fileName}`;
			link.click();
		}
	}

	public daysToDate(days: number) {
		let today = new Date();
		let date = new Date();
		date.setDate(today.getDate() + days);
		return this.formatDate(date);
	}

	formatDate(date: Date): string {
		var day = String(date.getDate()).padStart(2, '0');
		var month = String(date.getMonth() + 1).padStart(2, '0');
		var year = date.getFullYear();
		return day + '/' + month + '/' + year;
	}

	public static GetMobileOperatingSystem(): 'Windows Phone' | 'iOS' | 'Android' | 'unknown' {
		var userAgent = navigator.userAgent || navigator.vendor;
		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return 'Windows Phone';
		}
		if (/android/i.test(userAgent)) {
			return 'Android';
		}
		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.FB) {
			return 'iOS';
		}
		return 'unknown';
	}

	public static extendsBoolean(valueToCheck, excepts: any[] = []): boolean {
		if (new Set(excepts).has(valueToCheck)) return true;
		let falsies = ['false', 'undefined', 'null', 'NaN'];
		const falsyStringOptions = new Set(falsies);
		if (Boolean(valueToCheck)) {
			return !falsyStringOptions.has(valueToCheck);
		}
		return false;
	}

	public static dataURLtoFile(dataWebEncode, filename) {
		try {
			var arr = dataWebEncode?.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = window.atob(arr[1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, { type: mime });
		} catch (e) {
			return;
		}
	}

	public static replaceAll(string, search, replace): string {
		return string.split(search).join(replace);
	}

	public static get backendVersion(): string {
		return JSON.tryParse(sessionStorage.getItem(StorageKeys.AppVersionKeys.KeyBackendVersion));
	}

	public static svgToBase64(svg: string): string {
		if (svg) return 'data:image/svg+xml;base64,' + svg;
	}

	public static checkEmailValidity(email: string): boolean {
		const re =
			// eslint-disable-next-line no-useless-escape
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	public static readonly heCycleCreator = { title: 'יוצר המסמך', placeHolder: '{{creator_email}}' };
	public static readonly enCycleCreator = { title: 'Document Creator', placeHolder: '{{creator_email}}' };
	private allowedCreatorText: string;
	static getAllowedCreatorText: string;

	public static LastDownloadCycleUrl: string = undefined;

	/**
	 * A validator that checks if the phone number is valid.
	 * @param {FormControl} control - the control to validate
	 * @returns {null | { phoneVaild: { value: boolean } }} - null if the phone number is valid, otherwise an object containing the error.
	 */
	public static phoneNUmberValidator(control: FormControl) {
		let phone_number = control.value;
		let result = GlobalsService.checkPhoneNumber(phone_number);
		return result === undefined ? { phoneVaild: { value: result } } : null;
	}

	public static getCurrentLocaleLanguage(): 'he-IL' | 'en-US' {
		const langService: LanguageService = AppInjector.get(LanguageService);
		return langService?.CurrentDir == 'rtl' ? 'he-IL' : 'en-US';
	}

  public static globalTranslate(key: string, params?: any): string {
    const translateService: TranslateService = AppInjector.get(TranslateService);
    return translateService?.instant(key, params);
  }

	public static get isMobile(): boolean {
		return AppInjector.get(DeviceService).isMobile;
	}
}

export enum PhoneNumberType {
	Israel,
	USA,
	International,
}

export function pick<T>(obj: T, keys: string[]): T {
	if (!keys) return null;
	const result = {} as T;
	keys.forEach(key => (result[key] = obj[key]));
	return result;
}
