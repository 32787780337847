<mat-list-item class="dc-sidenav-item">
	<span class="flex w-full items-center justify-between gap-3">
		<button
			class=""
			[routerLink]="item?.routerLink"
			(click)="onClickItem(item, $event)"
			[matTooltip]="item.label | translate"
			[matTooltipPosition]="languageService.Dir == 'rtl' ? 'left' : 'right'"
		>
			<span class="flex w-full items-center justify-start">
				<DocIcon
					class="h-8 w-8"
					[init]="item.icon"
				/>

				<section class="text-xl">
					{{ item.label | translate }}
				</section>
			</span>

			<!-- If is category (with subitems) -->
			<section
				*ngIf="item.type == 'category' && item.subItems?.length > 0"
				class="mx-1"
			>
				<DocIcon
					[ltrIcon]="item.opened ? 'chevron-right' : 'chevron-down'"
					[rtlIcon]="item.opened ? 'chevron-left' : 'chevron-down'"
					type="regular"
					color="primary"
					size="sm"
				/>
			</section>
		</button>

		<!-- open item menu -->
		<button
      *ngIf="item.type == 'item'"
      class="w-max p-1"
      mat-icon-button
      [matMenuTriggerFor]="itemMenu"
      [matMenuTriggerData]="{item: item}"
		>
			<DocIcon
				name="ellipsis-v"
				type="regular"
				size="sm"
				color="primary"
			/>
		</button>
	</span>
</mat-list-item>

<!-- item menu -->
<mat-menu
	#itemMenu="matMenu"
	class="aside w-max min-w-0 max-w-none"
>
	<ng-template
		matMenuContent
		#itemMenuTemplate
		let-item="item"
	>
		<!-- open in new tab -->
		<button
      class="w-max p-1"
      mat-menu-item
      [dcOpenInNewTab]="item['routerLink']"
      [matTooltip]="'directives.open-in-new-tab.label' | translate"
		>
			<DocIcon
				name="external-link-alt"
				type="regular"
				size="sm"
				color="primary"
			/>
		</button>
	</ng-template>
</mat-menu>
