import { RowStateData } from '../config/row-state-data.model';
import { TableEntryStruct } from './table.types';

export const TableEventType = {
	ColumnClick: 'columnClick' as const,
	CellClick: 'cellClick' as const,
	RowClick: 'rowClick' as const,
	RowContextMenuEvent: 'contextmenu' as const,
	RowDoubleClick: 'dblclick' as const,
	CheckboxClick: 'checkboxClick' as const,
} as const;

export type TableEventType = (typeof TableEventType)[keyof typeof TableEventType] | Lowercase<string>;

export type TableEvent<T extends TableEntryStruct> = {
	type: TableEventType;
};

export type TableCellClickEvent<T extends TableEntryStruct> = {
	value: any;
	columnKey: keyof T;
} & TableEvent<T>;

export type TableRowClickEvent<T extends TableEntryStruct> = RowStateData & TableEvent<T>;

export type TableColumnClickEvent<T extends TableEntryStruct> = {
	columnKey: keyof T;
} & TableEvent<T>;

export type TableCheckboxClickEvent<T extends TableEntryStruct> = {
	index: number;
	data: T;
	checked: boolean;
	selectedCheckboxes: number[];
} & TableEvent<T>;
