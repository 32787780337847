import { DcColorType } from 'src/styles/doc-theme.data';
import { IColumnStruct } from '../../table/data/config/column.config';
import { ConfigurableTableActionStructFn, TableActionStruct } from '../../table/data/config/table-action-struct.interface';
import { ReportsTableStruct } from './reports-table-struct';
import { TableComponent } from '../../table/table/table.component';
import { ReportsComponent } from '../reports.component';
import { AppInjector } from 'src/app/app.component';
import { DialogControlService } from '../../dialog/dialog-control.service';
import { DocumentsService } from 'src/app/Data/Services/documents.service';
import { catchError } from 'rxjs';
import { SnackbarService } from '../../Layouts/snackbar/snackbar.service';

export function ReportsTableConfData(reportsComponent: ReportsComponent): {
  columns: IColumnStruct<ReportsTableStruct>[];
  globalActions: TableActionStruct[];
  rowActions: TableActionStruct[];
  primaryAction: TableActionStruct;
} {
  /* --------------------------------- Columns -------------------------------- */

  const fileName: IColumnStruct<ReportsTableStruct> = {
    title: 'fileName',
    columnKey: 'fileName',
    state: {
      columnWidth: 30,
    },
  };

  const docStatus: IColumnStruct<ReportsTableStruct> = {
    title: 'status',
    columnKey: 'docStatus',
    enableSearch: true,
    enableSort: false,
    state: {
      columnWidth: 5,
    },
    customTemplate: true,
  };


  const recipients: IColumnStruct<ReportsTableStruct> = {
    title: 'recipients',
    columnKey: 'recipientCellData',
    state: {
      columnWidth: 30,
    },
    customTemplate: true,
  };


  //create doc date
  const CreateDate: IColumnStruct<ReportsTableStruct> = {
    title: 'CreateDate',
    columnKey: 'CreateDate',
    state: {
      columnWidth: 30,
    },
    customTemplate: true,
  };

  const LastUpdate: IColumnStruct<ReportsTableStruct> = {
    title: 'LastUpdate',
    columnKey: 'LastUpdate',
    state: {
      columnWidth: 30,
    },
    customTemplate: true,
  };

  const columns: IColumnStruct<ReportsTableStruct>[] = [
    docStatus,
    fileName,
    recipients,
    CreateDate,
    LastUpdate,
  ];

  const exportTableDataAction: TableActionStruct = {
    label: () => 'export-data-title',
    color: 'primary',
    Icon: () => {
      return {
        name: 'file-export',
        type: 'duotone',
        color: 'primary',
        size: '2x',
      };
    },
    hidden: () => false,
    disabled: () => false,
    data: {reportsComponent},
    onClickFn: e => {
      const dialogService = AppInjector.get(DialogControlService);
      const tableComponent: TableComponent<ReportsTableStruct> = e?.tableComponent;
      dialogService.open('exportDataDialog', {data: tableComponent});
      reportsComponent.exportDateFilterFG.reset();

    },
    section: 'global',
  };

  

  /* ----------------------------- Global Actions ----------------------------- */
  const globalActions: TableActionStruct[] = [
    exportTableDataAction,
  ];



  /* ------------------------------ Rows Actions ------------------------------ */
  const previewAction: TableActionStruct = {
    label: () => 'preview-row-action',
    color: 'primary',
    iconName: 'eye',
    Icon: () => {
      return {
        name: 'eye',
        type: 'duotone',
        color: 'primary',
        size: '2x',
      };
    },
    hidden: () => false,
    disabled: () => false,
    data: {reportsComponent},
    onClickFn: e => {
      const rowId = e?.event?.id;
      reportsComponent.openPreviewWithData(rowId);
    },
    section: 'menu',
    mobile: true,
    showCheckedRowsCountBadge: () => false,
  };

  const notifyAction: TableActionStruct = {
    label: () => 'notify-row-action',
    color: 'primary',
    iconName: 'bell',
    Icon: () => {
      return {
        name: 'bell',
        type: 'duotone',
        color: 'primary',
        size: '2x',
      };
    },
    hidden: () => false,
    disabled: () => false,
    data: {reportsComponent},
    onClickFn: e => {
      const rowId = e?.event?.id;
      reportsComponent.notifyRecipients(rowId);
    },
    section: 'menu',
    mobile: true,
    showCheckedRowsCountBadge: () => false,
  };

  const downloadAction: TableActionStruct = {
    label: () => 'download-row-action',
    color: 'primary',
    iconName: 'download',
    hidden: () => false,
    disabled: () => false,
    data: {reportsComponent},
    onClickFn: e => {
      const rowId = e?.event?.id;
      const fileName = e?.event?.data?.fileName;
      reportsComponent.downloadDocument(rowId, fileName);
    },
    section: 'menu',
    mobile: true,
    showCheckedRowsCountBadge: () => false,
  };

  const rowActions: TableActionStruct[] = [previewAction, notifyAction, downloadAction];

  return {
    columns,
    globalActions,
    rowActions,
    primaryAction: null,
  };
}


