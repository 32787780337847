
import { IColumnStruct } from 'src/app/modules/table/data/config/column.config';
import { TableActionStruct } from 'src/app/modules/table/data/config/table-action-struct.interface';
import { DcColorType } from 'src/styles/doc-theme.data';
import { MetaTagComponent } from '../meta-tag.component';
import {IMetaTagTableStruct} from './metatag-table-struct.type';

export function MetaTagTableData(metaTagEditComponent: MetaTagComponent): {
  columns: IColumnStruct<IMetaTagTableStruct>[];
  globalActions: TableActionStruct[];
  rowActions: TableActionStruct[];
} {
  /* --------------------------------- Columns -------------------------------- */
  const key: IColumnStruct<IMetaTagTableStruct> = {
    title: 'key',
    columnKey: 'key',
    state: {
      columnWidth: 50,
      visible: true
    },
  };

  const value: IColumnStruct<IMetaTagTableStruct> = {
    title: 'value',
    columnKey: 'value',
    state: {
      columnWidth: 50,
      visible: true
    },
  };

  const columns: IColumnStruct<IMetaTagTableStruct>[] = [key, value];

  /* ----------------------------- Global Actions ----------------------------- */

  const createAction: TableActionStruct = {
    label: () => 'create-new-tag',
    color: DcColorType.Primary,
    Icon: () => ({name: 'plus', type: 'light', color: 'primary', size: '2x'}),
    hidden: () => false,
    disabled: () => false,
    data: {metaTagEditComponent},
    onClickFn: e => {
      const {event, data} = e;
      metaTagEditComponent.createMetaTag();
    },
    section: 'global',
  };

  const globalActions: TableActionStruct[] = [createAction];

  /* ------------------------------ Rows Actions ------------------------------ */

  const deleteAction: TableActionStruct = {
    label: () => 'delete-tag',
    color: DcColorType.Error,
    iconName: 'trash',
    hidden: () => false,
    disabled: () => false,
    data: {metaTagEditComponent},
    onClickFn: e => {
      const {event, data} = e;
      const metaTagEditComponent: MetaTagComponent = e.data.metaTagEditComponent;
      const metaTagKey = event.id;
      if (!metaTagKey) return;
      metaTagEditComponent.deleteMetaTag(metaTagKey);
    },
  };

  const editAction: TableActionStruct = {
    label: () => 'edit-tag',
    color: DcColorType.Primary,
    iconName: 'edit',
    hidden: () => false,
    disabled: () => false,
    data: {metaTagEditComponent},
    onClickFn: e => {
      const {event, data} = e;
      const metaTagEditComponent: MetaTagComponent = e.data.metaTagEditComponent;
      const metaTagKey = event.id;
      if (!metaTagKey) return;
      const metaTagValue = metaTagEditComponent.metaTagTableData$.value.find(mt => mt.key === metaTagKey).value;
      if (!metaTagValue) return;
      metaTagEditComponent.editMetaTag(metaTagKey);
    },
  };

  const rowActions: TableActionStruct[] = [editAction, deleteAction];

  return {
    columns,
    globalActions,
    rowActions,
  };
}
