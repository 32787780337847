import { Injectable } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { IBrowserInfo, IDimention } from './device.interface';

declare global {
	interface Window {
		deviceService: DeviceService;
	}
}

export type DeviceType = 'mobile' | 'desktop' | 'tablet';

@Injectable({
	providedIn: 'root',
})
export class DeviceService {
	constructor(private deviceDetectorService: DeviceDetectorService) {
		window['deviceService'] = this;
	}

	public get getDeviceInfo(): DeviceInfo {
		return this.deviceDetectorService.getDeviceInfo();
	}

	/* -------------------------------------------------------------------------- */
	/*                                 Device Type                                */
	/* -------------------------------------------------------------------------- */

	public get deviceType(): DeviceType {
		if (this.isMobile) return 'mobile';
		if (this.isTablet) return 'tablet';
		return 'desktop';
	}
	public get isMobile(): boolean {
		return this.deviceDetectorService?.isMobile();
	}

	public get isDesktop(): boolean {
		return this.deviceDetectorService?.isDesktop();
	}

	public get isTablet(): boolean {
		return this.deviceDetectorService?.isTablet();
	}

	/* -------------------------------------------------------------------------- */

	public get getDeviceOrientation(): OrientationType {
		let orientation: string = this.deviceDetectorService?.orientation;
		return orientation as OrientationType;
	}

	public get getDeviceDimensions(): IDimention {
		let dims: IDimention = {
			width: window.innerWidth,
			height: window.innerHeight,
		};
		return dims;
	}

	public get getDeviceOS(): string | 'mac' | 'ios' | 'android' | 'windows' | 'linux' {
		return this.deviceDetectorService.getDeviceInfo().os.toLowerCase();
	}

	public get getDeviceBrowser(): IBrowserInfo {
		let info: DeviceInfo = this.deviceDetectorService.getDeviceInfo();
		return { browser: info.browser, version: info.browser_version };
	}

	public static getMainContainersSizes() {
		const mainContent = document.querySelector('.MAIN-CONTENT');
		const topBar = document.querySelector('.topbar');
		return {
			mainContent: {
				width: mainContent.clientWidth,
				height: mainContent.clientHeight,
			},
			topBar: {
				width: topBar.clientWidth,
				height: topBar.clientHeight,
			},
		};
	}
}
