import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from 'src/app/Data/Services/language.service';
import { IUserPortrait } from 'src/app/Data/Entities/User/user-portrait.model';
import { StorageKeys } from 'src/app/Data/Services/storage.data/storage-keys.data';
import { LoaderDirective } from 'src/app/Directives/loder.directive';
import { AuthService } from 'src/app/Data/Services/auth.service';
import { DocIconComponent } from 'src/app/modules/icon/icon.component';
import { LanguageToggleComponent } from './language-toggle/language-toggle.component';
import { UserPreferencesComponent } from './user-preferences-dialog/user-preferences.component';
import { DialogControlService } from 'src/app/modules/dialog/dialog-control.service';
import { DialogDirective } from 'src/app/modules/dialog/dialog.directive';
import { UsersService } from 'src/app/Data/Services/users.service';

const commons = [CommonModule, TranslateModule];
const matModules = [MatButtonModule];
const standalones = [DialogDirective, DocIconComponent, LoaderDirective, LanguageToggleComponent, UserPreferencesComponent];
const npmModules = [];

@Component({
	selector: 'dc-user-profile-card',
	standalone: true,
	imports: [...commons, ...matModules, ...standalones, ...npmModules],
	templateUrl: './user-profile-card.component.html',
	styleUrls: ['./user-profile-card.component.scss'],
})
export class UserProfileCardComponent implements OnInit {
    UsersService = UsersService
	/* -------------------------------------------------------------------------- */
	/*                                 Constructor                                */
	/* -------------------------------------------------------------------------- */
	constructor(private auth: AuthService, protected languageService: LanguageService, protected dialogService: DialogControlService ) {
		
	}

	/* -------------------------------------------------------------------------- */
	/*                               LifeCycle Hooks                              */
	/* -------------------------------------------------------------------------- */
	ngOnInit(): void {}

	/* --------------------------------- Avatar --------------------------------- */
	protected get currentUserPortrait(): string {
    	const portrait = window.storageService.getItem<IUserPortrait>(StorageKeys.AuthServiceKeys.KeyCurrentUserPortrait);
	    if (!portrait)
		   return  "/assets/icons/user-icon.gif"
		   return portrait?.image
	  }

	/* ------------------------- User Preferences Dialog ------------------------ */
	protected openUserPreferencesDialog(): void {
		const dialogRef = this.dialogService.open("userPreferencesDialog");
		dialogRef.afterClosed().subscribe(
			(res)=>{}
		  );
    
	}

	/* --------------------------------- Log out -------------------------------- */
	protected logoutProcess: boolean = false;
	protected logOut(): void {
		this.logoutProcess = true;
		this.auth.logout();
	}

  protected initials(name: string): string {
    // if the name is not defined, return empty string;
    // if the name is defined:
    // if the name is a single word, return the first 2 letters of the word
    // if the name is a multi-word name, return the first letter of first 2 words ()
    // all result is uppercased
    if (!name) return '';
    const words = name.split(' ');
    if (words.length === 1) return words[0].substring(0, 2).toUpperCase();
    return words[0].substring(0, 1).toUpperCase() + words[1].substring(0, 1).toUpperCase();
  }
}
