import { TableEntryStruct, TableObjectKey } from '../types/table.types';

export type ConfigurableTableColumnStruct = (customProps?: { [key in keyof ColumnStruct<any>]?: any }) => ColumnStruct<any>;

export class ColumnStruct<T extends TableEntryStruct> {
	columnKey: TableObjectKey;
	objectKey?: TableObjectKey;
	title: string;

	/* Custom configuration for the column. */
	customTemplate?: boolean;
	getter?: (obj: T, ...args) => any;

	enableSearch?: boolean = true;
	enableSort?: boolean = true;

	state?: IColumnStateStruct | ColumnStateStruct;

	/* ------------------------------- Indicators ------------------------------- */
	pinned?: boolean = false;
	/** @description Flag for the column to be used as the id column. (hidden column)*/
	isIdColumn?: boolean = false;
	/** @description Flag for the column to be used as the layout column (checkboxes & rowMenu). (visible column)*/
	isLayoutColumn?: boolean = false;

	isDynamicColumn?: boolean = false;

	/* -------------------------------------------------------------------------- */
	classString?: {
		headerCell?: string;
		bodyCell?: string;
	};
	constructor(init?: IColumnStruct<T>) {
		Object.assign(this, init);
		if (this.columnKey && !this.objectKey) this.objectKey = this.columnKey;
		if (!this.state) this.state = new ColumnStateStruct({}, this);
		if (!(this.state instanceof ColumnStateStruct)) this.state = new ColumnStateStruct(this.state, this);
	}
}
/* -------------------------------------------------------------------------- */
export type IColumnStruct<T extends TableEntryStruct> = { [key in keyof ColumnStruct<T>]: any };
/* -------------------------------------------------------------------------- */

export class ColumnStateStruct {
	private static staticColumnsIndex: number = -1;
	/* ------------------------------ Columns Index ----------------------------- */
	private _index?: number = undefined;
	get index(): number {
		return this._index;
	}

	set index(value: number) {
		if (this.index === undefined) this._index = value;
		if (this.index === value) return;
		if (this?.columnStructRef && (this?.columnStructRef.isIdColumn || this?.columnStructRef?.isLayoutColumn || this.columnStructRef.pinned)) return;
		this._index = value;
	}

	/* ---------------------------- Column Visibility --------------------------- */
	private _visible?: boolean = true;

	/** @description Flag for the column to be visible. */
	public get visible(): boolean {
		return this._visible;
	}

	set visible(value: true | false) {
		if (value === undefined) this._visible = true;
		if (this.visible === value) return;
		if ((this?.columnStructRef && this.columnStructRef.isIdColumn) || this.columnStructRef.isLayoutColumn || this.columnStructRef.pinned) return;
		this._visible = value;
	}

	/* ---------------- Configurations and States for the column. --------------- */
	columnSearch?: string = '';
	columnSort?: 'asc' | 'desc' | null;
	columnSortActive?: boolean = false;
	/** @description Column width in percentage.  */
	columnWidth?: NumberRange<101> | 'auto' = 'auto';

	constructor(init?: Partial<ColumnStateStruct>, private columnStructRef: ColumnStruct<any> = null) {
		Object.assign(this, init);
		if (this.columnStructRef.isIdColumn) {
			this._visible = true;
			this._index = -Infinity;
		} else if (this.columnStructRef.isLayoutColumn) {
			this._visible = true;
			//Check if the column is negative (at the start of the table)
			if (this._index < 0) {
				this.columnStructRef.pinned = true;
				this._index = -Infinity;
			}
			// else, check if the column is positive (at the end of the table)
			else {
				this.columnStructRef.pinned = false;
				this._index = +Infinity;
			}
		} else if (this.columnStructRef.pinned) {
			this._index = ColumnStateStruct.staticColumnsIndex--;
		}
	}
}
/* -------------------------------------------------------------------------- */
export type IColumnStateStruct = { [key in keyof ColumnStateStruct]: any };
/* -------------------------------------------------------------------------- */
