import {TableComponent} from '../table/table.component';
import {RowStateData} from '../data/config/row-state-data.model';
import {PagingConfigStruct} from '../data/config/paging.config';
import { TableEntryStruct, TableObjectKey } from '../data/types/table.types';

export class TableCheckedRowsController<T extends TableEntryStruct> {
  private static _instance: TableCheckedRowsController<any>;

  public static instance<T extends TableEntryStruct>(tableComponentInstance: TableComponent<T>): TableCheckedRowsController<T> {
    if (!this._instance) {
      this._instance = new TableCheckedRowsController(tableComponentInstance);
    }
    return this._instance;
  }

  public static reset(): void {
    this._instance = null;
  }

  private constructor(private tableComponentInstance: TableComponent<T>) {
  }

  /**----------------------------------   Variables   ------------------------------------*/
  private get rowsId(): string {
    return this.tableComponentInstance.rowsId;
  }

  private get tableRows(): Map<TableObjectKey, RowStateData> {
    return this.tableComponentInstance.tableRows;
  }

  private get tableDataStore(): Map<TableObjectKey, TableEntryStruct> {
    return this.tableComponentInstance.tableDataStore;
  }

  private get pagingConfig(): PagingConfigStruct<T> {
    return this.tableComponentInstance.pagingConfig;
  }

  /**----------------------------------   METHODS   ------------------------------------*/

  /**
   * @description returns an array of the checked rows data.
   */
  public get checkedRowsDataStruct(): RowStateData[] {
    return this.tableRows.valuesArray().filter(row => row.checked);
  }

  /**
   * @description returns the number of checked rows.
   */
  public get checkedRowsCount(): number {
    return this.checkedRowsDataStruct.length;
  }

  /**
   * @description returns an array of the checked rows ids.
   */
  public get checkedRowIDs(): TableObjectKey[] {
    return this.checkedRowsDataStruct.sort((a, b) => a.index - b.index).map(row => row.id);
  }

  /**----------------------------------   ROWS   ------------------------------------*/
  /**
   * Checks if the given row is checked.
   * @param row: T | TableObjectKey - the row to check.
   * @param rowIndex: number - the index of the row.
   * @protected
   */
  isRowChecked(row: T | TableObjectKey, rowIndex: number): boolean {
    const rowElementData = this.tableComponentInstance.getRowElementDataSet(row);
    if (rowElementData) {
      const rowId = typeof row === 'object' ? row[this.rowsId] : row;
      const rowData = this.tableRows.get(rowId as string);
      rowData.page = this.pagingConfig.currentPage;
      rowData.index = rowIndex;
      rowElementData['checked'] = rowData.checked;
      return rowData.checked;
    }
  }

  /**
   * Toggles the checked state of the given row.
   * @param row: T | TableObjectKey - the row to toggle.
   * @param checked: boolean - the checked state to set.
   * @protected
   */
  public toggleRowChecked(row: T | TableObjectKey, checked: boolean = undefined): void {
    const rowElementDataRef = this.tableComponentInstance.getRowElementDataSet(row);
    if (this.tableRows.has(row as TableObjectKey)) {
      const rowElementData = this.tableDataStore.get(row as TableObjectKey);
      if (this.tableComponentInstance.rowsCheckboxAllowedFn && !this.tableComponentInstance.rowsCheckboxAllowedFn(null, rowElementData)) {
        rowElementDataRef['checked'] = false;
        this.tableRows.get(row as TableObjectKey).checked = false;
        return;
      }
    }
    if (rowElementDataRef) {
      const checkedRes = rowElementDataRef['checked'] === 'true';
      rowElementDataRef['checked'] = checked != undefined ? checked : !checkedRes;

      const rowId = typeof row === 'object' ? row[this.rowsId] : row;
      this.tableRows.get(rowId as string).checked = checked != undefined ? checked : !checkedRes;
    }
  }

  /***
   * Resets the checked rows.
   * @param event: 'all' | 'page' - the type of reset to perform.
   */
  public resetCheckedRows(event: 'all' | 'page'): void {
    if (event === 'all') {
      this.tableRows.valuesArray().forEach(row => (row.checked = false));
    }
    if (event === 'page') {
      this.tableRows
        .valuesArray()
        .filter(row => row.page === this.pagingConfig.currentPage)
        .forEach(row => (row.checked = false));
    }
  }

  /**----------------------------------   PAGES   ------------------------------------*/
  /**
   * Checks if the current page is checked.
   * @protected
   */
  public allPageChecked(): boolean {
    const pageRows = this.tableRows.valuesArray().filter(row => row.page === this.pagingConfig.currentPage);
    return pageRows.length > 0 && pageRows.every(row => row.checked);
  }

  /**
   * Toggles the checked state of the given page.
   * @param page: number - the page to toggle.
   * @param checked: boolean - the checked state to set.
   * @protected
   */
  togglePageChecked(page: number = this.pagingConfig.currentPage, checked: boolean): void {
    const pageRows = this.tableRows.valuesArray().filter(row => row.page === page);
    pageRows.forEach(row => {
      this.toggleRowChecked(row.id, checked);
    });
  }
}
