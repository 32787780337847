<div
	class="TableActionsContainer flex w-full items-center justify-between px-1"
	[class.mobile]="isMobile"
	[class.disable]="disable"
>
	<section
		id="start-actions"
		class="dense-min my-1 flex gap-2"
	>
		<mat-button-toggle-group name="tableActions">
			<!-- ------------------------------- Seacrh -------------------------------- -->
			<mat-button-toggle
				*ngIf="enableSearch"
				class="no-checked-bg"
				[disableRipple]="true"
				(click)="setCollapsableActionForm('searchInput', !visibleForms.searchInput)"
				[matTooltip]="searchLabel | translate"
			>
				<div class="collapsable-button-toggle">
					<DocIcon
						name="magnifying-glass"
						type="duotone"
						size="2xl"
						[primaryColor]="visibleForms.searchInput ? 'var(--info)' : 'var(--primary)'"
						[secondaryColor]="visibleForms.searchInput ? 'var(--info)' : 'var(--primary)'"
					/>
					<ng-container
						*ngIf="visibleForms.searchInput"
						[ngTemplateOutlet]="globalSearchInputRef"
					/>
				</div>
			</mat-button-toggle>

			<!-- -------------------------- Date Range Picker -------------------------- -->
			<mat-button-toggle
				*ngIf="enableDateRange"
				class="no-checked-bg"
				[disableRipple]="true"
				(click)="setCollapsableActionForm('dateRangePicker', !visibleForms.dateRangePicker)"
				[matTooltip]="'dccomp.table.actions.date-ranger.label' | translate"
			>
				<div class="collapsable-button-toggle">
					<DocIcon
						name="calendar-days"
						type="duotone"
						size="2xl"
						[primaryColor]="visibleForms.dateRangePicker ? 'var(--info)' : 'var(--primary)'"
						[secondaryColor]="visibleForms.dateRangePicker ? 'var(--info)' : 'var(--primary)'"
					/>
					<ng-container
						*ngIf="visibleForms.dateRangePicker"
						[ngTemplateOutlet]="globalDateFilterRef"
					/>
				</div>
			</mat-button-toggle>

			<!-- --------------------------- Visible Columns --------------------------- -->
			<mat-button-toggle
				*ngIf="enableVisibleColumnsSelection"
				class="no-checked-bg"
				(click)="setCollapsableActionForm('visibleColumnsSelection', !visibleForms.visibleColumnsSelection)"
				[matTooltip]="'dccomp.cycle-tables.global-menu.visible-columns' | translate"
			>
				<div class="collapsable-button-toggle">
					<DocIcon
						name="table-columns"
						type="duotone"
						size="2xl"
						[primaryColor]="visibleForms.visibleColumnsSelection ? 'var(--info)' : 'var(--primary)'"
						[secondaryColor]="visibleForms.visibleColumnsSelection ? 'var(--info)' : 'var(--primary)'"
					/>
					<ng-container
						*ngIf="visibleForms.visibleColumnsSelection"
						[ngTemplateOutlet]="visibleColumnsSelectionRef"
					/>
				</div>
			</mat-button-toggle>

			  <!-- ---------------------------- Table Preset Save ---------------------------- -->
			  <mat-button-toggle
			  *ngIf="enablePresets && actualPreset"
			  class="no-checked-bg"
			  (click)="onClickStorePreset()"
			  [matTooltip]="'table.preset.save' | translate"
			>
			  <div class="collapsable-button-toggle">
				<DocIcon
				  name="floppy-disk"
				  type="duotone"
				  size="2x"
				  [primaryColor]="actualPreset.color"
				  [secondaryColor]="actualPreset.color"
				/>
			  </div>
			</mat-button-toggle>

		</mat-button-toggle-group>
	</section>

	<section
		id="end-actions"
		class="dense-min my-1 flex gap-2"
	>
		<mat-button-toggle-group name="customTableActions">
			<ng-container *ngFor="let action of customTableActions; trackBy: trackByCustomActions; let i = index">
				<mat-button-toggle
					*ngIf="action?.section == 'global'"
					class="custom-table-action"
					[class.disabled]="action?.disabled(action?.data)"
					[disableRipple]="true"
					(click)="action?.onClickFn(action?.data)"
					[matTooltip]="action?.label(action?.data) | translate"
					[disabled]="action?.disabled(action?.data)"
				>
					<div
						class="collapsable-button-toggle"
						[matBadge]="selectedRows?.length"
						matBadgeColor="warn"
						matBadgePosition="above before"
						matBadgeSize="small"
						[matBadgeHidden]="action?.showCheckedRowsCountBadge ? action?.showCheckedRowsCountBadge(action?.data) != true : true"
						class="cursor-pointer"
						(click)="action?.disabled(action?.data) && action?.onClickFn(action?.data)"
					>
						<DocIcon
							*ngIf="action?.Icon(action?.data) as icon"
							[name]="icon.name"
							[type]="icon.type"
							[size]="icon.size"
							color="var(--primary)"
						/>
					</div>
				</mat-button-toggle>
			</ng-container>
		</mat-button-toggle-group>
	</section>
	<!-- ----------------------------------------------------------------------- -->
	<!-- ----------------------------------------------------------------------- -->
	<!-- ----------------------------------------------------------------------- -->

	<ng-template #globalSearchInputRef>
		<mat-form-field
			appearance="outline"
			class="disable-mat-subscript"
			[class.searchInputFormField]="enableDateRange"
			[class.disabled]="true"
			(click)="$event.stopPropagation()"
		>
			<mat-label>{{ searchLabel | translate }}</mat-label>
			<input
				matInput
				type="search"
				[placeholder]="searchLabel | translate"
				#searchInput
				[formControl]="searchFC"
				(keyup)="!lazyMode && onSearchFilterChange()"
				(search)="onSearchFilterChange()"
			/>
			<button
				*ngIf="isMobile"
				mat-icon-button
				matSuffix
				[class.invisible]="!searchFC?.value"
				(click)="onSearchFilterChange()"
				[style.color]="'var(--info)'"
				[style.font-weight]="'bold'"
			>
				<mat-icon>search</mat-icon>
			</button>
		</mat-form-field>
	</ng-template>

	<ng-template #globalDateFilterRef>
		<mat-form-field
			(click)="dateRangePickerRef.open(); $event.stopPropagation()"
			appearance="outline"
			class="globalDateFilterRef disable-mat-subscript dense-min"
		>
			<mat-label [style.margin]="'8px'">{{ 'struct.dcfinder.filter.select_range_date' | translate }}</mat-label>
			<mat-date-range-input
				#matDateRangeInputRef
				[formGroup]="dateRangeFG"
				[rangePicker]="picker"
				[max]="dateRange.toDate"
				class="dir-initial"
			>
				<input
					matStartDate
					formControlName="fromDate"
					readonly
					class="text-start"
				/>
				<input
					matEndDate
					formControlName="toDate"
					readonly
					class="text-start"
					[max]="today"
				/>
			</mat-date-range-input>
			<!-- Btn: Clear Date range picker values -->
			<button
				mat-icon-button
				matSuffix
				[class.invisible]="!dateRangeFG?.getRawValue()?.fromDate || !dateRangeFG?.getRawValue()?.toDate"
				(click)="onSearchFilterChange({clearDateRange: true}); $event.stopPropagation()"
			>
				<mat-icon matDatepickerToggleIcon>clear</mat-icon>
			</button>
			<mat-datepicker-toggle
				matIconSuffix
				[for]="picker"
			></mat-datepicker-toggle>
			<mat-date-range-picker
				#dateRangePickerRef
				#picker
				[touchUi]="isMobile"
				startView="month"
				(closed)="onSearchFilterChange()"
			></mat-date-range-picker>

			<mat-error *ngIf="dateRangeFG.controls['fromDate']?.hasError('matStartDateInvalid')">Invalid start date</mat-error>
			<mat-error *ngIf="dateRangeFG.controls['toDate']?.hasError('matEndDateInvalid')">Invalid end date</mat-error>
		</mat-form-field>
	</ng-template>

	<ng-template #visibleColumnsSelectionRef>
		<mat-form-field
		  appearance="outline"
		  class="disable-mat-subscript dense-min"
		  (click)="$event.stopPropagation()"
		>
		  <mat-label>{{ 'dccomp.cycle-tables.global-menu.visible-columns' | translate }}</mat-label>
		  <mat-select
			panelClass="py-0 dc-scrollbar max-h-60vh"
			(selectionChange)="visibleColumnChangeEvent.emit($event)"
			multiple
			[formControl]="visibleColumnsFC"
		  >
			<mat-select-trigger>
			  <ng-container *ngIf="visibleColumnsFC.value.length > 0">
							<span *ngFor="let column of visibleColumnsFC.value; let i = index">
								{{ column?.title | translate }}{{ i < visibleColumnsFC.value.length - 1 ? ', ' : '' }}
							</span>
			  </ng-container>
			</mat-select-trigger>
	
			<div
			  cdkDropList
			  (cdkDropListDropped)="onColumnsOrderChange.emit($event)"
			  [cdkDropListData]="visibleColumnsFC.value"
			  cdkDropListOrientation="vertical"
			  cdkDropListLockAxis="y"
			>
			  <ng-container *ngFor="let column of dataColumns">
				<mat-option
				  *ngIf="!column.isIdColumn && !column.isLayoutColumn"
				  [disabled]="column.pinned"
				  class="dense-min visible-column-select-option px-2"
				  [value]="column"
				  cdkDrag
				  [cdkDragData]="column"
				  [cdkDragDisabled]="column.pinned || !column.state.visible"
				>
				  <div class="flex w-full items-center justify-between gap-2">
					<span>{{ column?.title | translate }}</span>
					<button
					  #dragHandleRef
					  *ngIf="!column.pinned && column.state.visible"
					  class="cursor-grabbing p-1 hover:bg-gray-200"
					  cdkDragHandle
					>
					<DocIcon
					asstes="reorder-humburger"
					assetsHover="reorder-humburger-arrows"
					[assetsHoverTriggerElement]="dragHandleRef"
					/>
					</button>
				  </div>
				</mat-option>
				<mat-divider></mat-divider>
			  </ng-container>
			</div>
		  </mat-select>
		</mat-form-field>
	  </ng-template>
</div>
