import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, distinctUntilKeyChanged } from 'rxjs';
import { LanguageService } from 'src/app/Data/Services/language.service';
import { DocIconStruct, DocIconSize, DocIconType } from './data/icon-struct.model';

@Component({
	selector: 'DocIcon',
	standalone: true,
	imports: [CommonModule, MatIconModule],
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
})
export class DocIconComponent implements OnInit, AfterViewInit, OnChanges {
	@ViewChild('DocIcon') DocIcon: ElementRef;

	@Input() set classIcon(iconClass: string) {
		const icon = iconClass.split(' ')?.map(i => i.replace('fa-', ''));
		this.name = icon[1];
		this.type = icon[0] as DocIconType;
		this.size = icon[2] as DocIconSize;
	}

	// Icon Struct - for object based icon
	@Input() set icon(icon: DocIconStruct) {
		this.name = icon.name;
		this.type = icon.type;
		this.size = icon.size;
		this.primaryColor = icon.primaryColor;
		this.primaryOpacity = icon.primaryOpacity;
		this.secondaryColor = icon.secondaryColor;
		this.secondaryOpacity = icon.secondaryOpacity;
		this.transform = icon.transform;
		this.styles = icon.styles;
		this.class = icon.class;
	}

	@Input() set init(icon: DocIconStruct | { [key in keyof DocIconComponent]: DocIconComponent[key] }) {
		if (typeof icon === 'string') {
			this.classIcon = icon;
		} else {
			this.icon = icon;
		}
	}

	// Properties
	@Input() name: string;
	@Input() type: DocIconType;
	@Input() size: DocIconSize;

	// Colors
	@Input() color: string;
	@Input() primaryColor: string;
	@Input() primaryOpacity: number;
	@Input() secondaryColor: string;
	@Input() secondaryOpacity: number;

	//Transforms
	@Input() transform: string;

	//Styles
	@Input() styles: object = {};

	//Class
	@Input() class: string;

	private _flipMode: 'x' | 'y' | 'both' = undefined;
	@Input() set autoFlip(flip: 'x' | 'y' | 'both') {
		this._flipMode = flip;
		if (this.languageService.CurrentLanguage.dir == 'rtl') {
			if (flip == 'x') this.flipX();
			else if (flip == 'y') this.flipY();
			else if (flip == 'both') this.flipBoth();
		}
	}
	@Input() flipX() {
		this.class += ' fa-flip-horizontal';
	}
	@Input() flipY() {
		this.class += ' fa-flip-vertical';
	}
	@Input() flipBoth() {
		this.class += ' fa-flip-both';
	}

	/* --------------------------- Icon By Directions --------------------------- */
	@Input() ltrIcon: string;
	@Input() rtlIcon: string;

	private updateByDirection() {
		const lang = this.languageService.currentLanguage$.getValue();
		if ((!this.ltrIcon || !this.rtlIcon) && !this.DocIcon?.nativeElement) return;
		if (lang.dir == 'rtl' && this.rtlIcon) this.name = this.rtlIcon;
		else if (lang.dir == 'ltr' && this.ltrIcon) this.name = this.ltrIcon;

		this.render(this.iconClass, this.iconStyleString);
	}
	/* -------------------------------------------------------------------------- */

	protected get iconClass(): string {
		let iconClass = '';
		if (this.type) {
			if (this.type === 'fak') iconClass += `fak `;
			else iconClass += `fa-${this.type} `;
		}
		if (this.name) iconClass += `fa-${this.name} `;
		if (typeof this.size == 'string') {
			iconClass += `fa-${this.size} `;
		}
		if (this.class) iconClass += `${this.class} `;
		return iconClass;
	}

	protected get iconStyles(): object {
		const styles = {};
		if (this.primaryColor) styles['--fa-primary-color'] = this.primaryColor;
		if (this.primaryOpacity) styles['--fa-primary-opacity'] = this.primaryOpacity;
		if (this.secondaryColor) styles['--fa-secondary-color'] = this.secondaryColor;
		if (this.secondaryOpacity) styles['--fa-secondary-opacity'] = this.secondaryOpacity;
		if (this.transform) styles['--fa-transform'] = this.transform;
		if (this.color) styles['color'] = this.color;
		if (typeof this.size == 'number') styles['font-size'] = this.size + 'px';
		this.styles = { ...styles, ...this.styles };
		return styles;
	}

	protected get iconStyleString(): string {
		return Object.keys(this.iconStyles)
			.map(key => `${key}: ${this.iconStyles[key]}`)
			.join('; ');
	}

	constructor(
		private cdr: ChangeDetectorRef,
		private elementRef: ElementRef,
		private iconRegistry: MatIconRegistry,
		private sanitizer: DomSanitizer,
		private languageService: LanguageService,
		private renderer: Renderer2,
	) {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.languageService.currentLanguage$.pipe(distinctUntilKeyChanged('value')).subscribe(lang => {
			this.updateByDirection();
		});
		this.cdr.detectChanges();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes?.asstes?.currentValue && changes?.asstes?.currentValue !== changes?.asstes?.previousValue) {
			this.registerMatIcon(changes?.asstes?.currentValue);
			this.activeSvg$.next(changes?.asstes?.currentValue);
			this.svgLoaded = true;
		}

		if (changes?.assetsHover?.currentValue && changes?.assetsHover?.currentValue !== changes?.assetsHover?.previousValue) {
			this.registerMatIcon(changes?.assetsHover?.currentValue);
			this.svgLoaded = true;
		}

		if (!this.DocIcon?.nativeElement) return;

		if (
			changes['rtlIcon']?.currentValue !== changes['rtlIcon']?.previousValue ||
			changes['ltrIcon']?.currentValue !== changes['ltrIcon']?.previousValue
		) {
			this.updateByDirection();
			return;
		}

		const changesToWatch = [
			'name',
			'type',
			'size',
			'primaryColor',
			'primaryOpacity',
			'secondaryColor',
			'secondaryOpacity',
			'transform',
			'styles',
			'class',
			'color',
		];

		let thereIsChange: boolean = false;
		changesToWatch.forEach(change => {
			if (changes[change]?.currentValue !== changes[change]?.previousValue) {
				this[change] = changes[change].currentValue;
				thereIsChange = true;
			}
		});

		this.cdr.detectChanges();
		if (thereIsChange) this.render(this.iconClass, this.iconStyleString);
	}

	private render(iconClass: string = this.iconClass, iconStyleString: string = this.iconStyleString) {
		const iconHTML = `<i class="${iconClass}" style="${iconStyleString}"></i>`;
		this.renderer.setProperty(this.DocIcon.nativeElement, 'innerHTML', iconHTML);
	}

	/* -------------------------------------------------------------------------- */
	/*                                Assets ICONS                                */
	/* -------------------------------------------------------------------------- */
	// Private Asstes Icons
	protected activeSvg$: BehaviorSubject<string> = new BehaviorSubject(null);
	protected svgLoaded: boolean = false;

	@Input() asstes: string;
	@Input() assetsHover: string;
	@Input() set assetsHoverTriggerElement(element: HTMLElement | null) {
		const elementTrigger = element ? element : this.elementRef.nativeElement;
		elementTrigger.addEventListener('mouseenter', this.onHoverAssets.bind(this));
		elementTrigger.addEventListener('mouseleave', this.onLeaveAssets.bind(this));
	}

	private registerMatIcon(iconName: string) {
		const url: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`../../../../assets/icons/${iconName}.svg`);
		this.iconRegistry.addSvgIcon(iconName, url);
	}

	private onHoverAssets(e: MouseEvent) {
		if (this.asstes && this.assetsHover) {
			this.activeSvg$.next(this.assetsHover);
			this.cdr.detectChanges();
		}
	}

	private onLeaveAssets(e: MouseEvent) {
		if (this.asstes && this.assetsHover && this.activeSvg$.value === this.assetsHover) {
			this.activeSvg$.next(this.asstes);
			this.cdr.detectChanges();
		}
	}
}
