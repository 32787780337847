import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[dcOpenInNewTab]',
  exportAs: 'dcOpenInNewTab',
  standalone: true,
})
export class OpenInNewTabDirective implements AfterViewInit {
	@Input() event: string = 'click';

  @Input('dcOpenInNewTab') url: string | string[];

	constructor(private renderer: Renderer2, private element: ElementRef) {}

	ngAfterViewInit(): void {
		this.renderer.listen(this.element.nativeElement, this.event, event => {
			event.preventDefault();
      let url: string;
			if (Array.isArray(this.url)) url = this.url.join('/');
			else if (typeof this.url === 'string') url = this.url.trim();
			window.open(url, '_blank');
		});
	}
}
