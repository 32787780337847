import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class InitiableService {
  private initSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly initState: Observable<boolean> = this.initSubject.asObservable();
  protected get isDestroyed() { return !this.initSubject.value }
  private hasInitialized: boolean = false;
  constructor(dependancies: InitiableService[]) {
    const observables = dependancies.map(s => s.initState);
    if (observables.length == 0) {
      setTimeout(() => this.performInit(), 0);
    } else {
      combineLatest(observables).pipe(map(values => values.every(value => value))).subscribe(finished => {
        if (finished) {
          setTimeout(() => this.performInit(), 0);
        } else {
          if (this.hasInitialized) setTimeout(() => this.destroy(), 0);
        }
      });
    }
  }

  private performInit() {
    this.init().subscribe(done => {
      this.hasInitialized = true;
      this.initSubject.next(done);
      if (done) {
        setTimeout(() => this.onInit(), 0);
      }
    });
  }

  protected init(): Observable<boolean> {
    return of(true);
  }
  protected onInit(): void { }
  protected destroy(): void {
    this.initSubject.next(false);
    setTimeout(() => this.onDestroy(), 0);
  }
  protected onDestroy(): void { }
}
