<mat-accordion>
  <form [formGroup]="templatesAndMessagesFG">
  <!-- ----------------------------------------------------------------------- -->
  <!--                             HTML templates:                             -->
  <!-- ----------------------------------------------------------------------- -->
  <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> {{'HTML-templates' | translate}}</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="flex flex-col">
        <mat-form-field appearance="fill" class="w-80">
          <mat-label>{{'Message-type' | translate}}</mat-label>
          <mat-select formControlName="selectedTypeHTML" (selectionChange)="selectTemplateHTML()" [disabled]="true">
            <mat-option *ngFor="let type of getUniqueTypesHTML()" [value]="type">
              {{type | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-80">
          <mat-label>{{'Template-language' | translate}}</mat-label>
          <mat-select formControlName="selectedLangHTML" (selectionChange)="selectTemplateHTML()">
            <mat-option *ngFor="let lang of getUniqueLangsHTML()" [value]="lang">
              {{lang | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="w-80">
          <mat-label>{{'HTML-template' | translate}}</mat-label>
          <textarea matInput formControlName="textareaValueHTML" class="h-48"></textarea>
        </mat-form-field>
      </div>
  </mat-expansion-panel>

  <!-- ----------------------------------------------------------------------- -->
  <!--                             SMS templates:                              -->
  <!-- ----------------------------------------------------------------------- -->

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> {{'SMS-templates' | translate}}</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex flex-col">
      <mat-form-field appearance="fill" class="w-80">
        <mat-label>{{'SMS-sender-name' | translate}}</mat-label>
        <input matInput formControlName="smsAccountName">
      </mat-form-field>
      <mat-form-field appearance="fill" class="w-80">
        <mat-label>{{'Message-type' | translate}}</mat-label>
        <mat-select formControlName="selectedTypeSMS" (selectionChange)="selectTemplateSMS()" [disabled]="true">
          <mat-option *ngFor="let type of getUniqueTypesSMS()" [value]="type">
            {{type | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-80">
        <mat-label>{{'Template-language' | translate}}</mat-label>
        <mat-select formControlName="selectedLangSMS" (selectionChange)="selectTemplateSMS()">
          <mat-option *ngFor="let lang of getUniqueLangsSMS()" [value]="lang">
            {{lang | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field appearance="fill" class="w-80">
        <mat-label>{{'SMS-default-message' | translate}}</mat-label>
        <textarea matInput formControlName="textareaValueSMS" class="h-48"></textarea>
      </mat-form-field>
    </div>
</mat-expansion-panel>

</form>
</mat-accordion>
