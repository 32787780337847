import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  first,
  map,
  of,
  switchMap,
} from 'rxjs';
import { Component, NgModule, OnInit } from '@angular/core';
import {
  DocumentsService,
  IQueryResponse,
} from 'src/app/Data/Services/documents.service';
import {
  ColumnStruct,
  IColumnStruct,
} from '../table/data/config/column.config';
import {
  PagingConfigStruct,
  PagingStruct,
} from '../table/data/config/paging.config';
import {
  TableConfig,
  TableConfigStruct,
} from '../table/data/config/table.config';
import { computeRowPerPage } from '../table/data/shared-logic/rows-per-page.logic';
import { ReportsTableConfData } from './data/reports-table-config.data';
import {
  ReportsTableStruct,
  DocUserStatusColor,
  DocumentStatusColor,
  IDocumentListRequest,
} from './data/reports-table-struct';
import { BadgeColorType } from 'src/app/modules/badge/badgeColor.type';
import {
  DocumentModel,
  DocumentStatus,
  DocUserStatus,
} from 'src/app/Data/Entities/Documents/document.model';
import { LazyTableConfigStruct } from '../table/data/config/lazy.config';
import {
  DocumentListType,
  IReportsQueryParams,
  IReportsQueryResponse,
  ReportsQueryRequest,
  SortableReportsTableColumn,
} from './http/reports-query.http';
import { QueryTableParamsStruct } from '../table/data/types/table-query-params-struct.type';
import { ReportsDataModel } from './data/reportsData.model';
import { UserModel } from 'src/app/Data/Entities/User/user.model';
import { UsersService } from 'src/app/Data/Services/users.service';
import { SnackbarService } from '../Layouts/snackbar/snackbar.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { reportTableColumnDataRetriever } from './data/reports-table-column-data-retriever.const';
import { TableObjectKey } from '../table/data/types/table.types';
import { TableConnector } from '../table/state.controllers/table-connector';
import {
  FormGroup,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { DialogControlService } from '../dialog/dialog-control.service';
import { TableComponent } from '../table/table/table.component';
import moment from 'moment';
import { PreviewDocumentComponent } from '../preview-document/preview-document.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { LoaderComponent } from '../Layouts/loader/loader.component';
import { MatCardModule } from '@angular/material/card';
import { DialogDirective } from '../dialog/dialog.directive';
import { UserCircleComponent } from '../user-circle/user-circle.component';
import { BadgeComponent } from '../badge/badge.component';
import { NgIf, AsyncPipe, DatePipe } from '@angular/common';
import { CellTemplateCollectorDirective } from '../table/directives/cell-template-collector.directive';
import { MetaTagsService } from '../company-settings/sub-components/meta-tag/service/meta-tags.service';
import { IMetaTagKeySchema } from '../company-settings/sub-components/meta-tag/data/metatag-table-struct.type';
import { StorageService } from 'src/app/Data/Services/storage.service';

/**
 * @title Data table with sorting, pagination, and filtering.
 */
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  standalone: true,
  imports: [
    TableComponent,
    CellTemplateCollectorDirective,
    NgIf,
    BadgeComponent,
    UserCircleComponent,
    DialogDirective,
    MatCardModule,
    LoaderComponent,
    MatDividerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    PreviewDocumentComponent,
    AsyncPipe,
    DatePipe,
    TranslateModule,
  ],
})
export class ReportsComponent implements OnInit {
  constructor(
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private usersService: UsersService,
    private snackbar: SnackbarService,
    protected dialogService: DialogControlService,
    private metaTagsService: MetaTagsService,
    private storageService:StorageService
  ) {
    const tableConfigs = ReportsTableConfData(this);
    this.reportsTableColumns = tableConfigs.columns;
    this.reportsTableConfig.tableActions = tableConfigs.globalActions;
    this.reportsTableConfig.rowActions = tableConfigs.rowActions;
    this.tableType = [DocumentListType.Sent, DocumentListType.Received];
  }

  ngOnInit(): void {
    /* -------------------------------------------------------------------------- */
    /*                             Add dynamic columns                            */
    /* -------------------------------------------------------------------------- */
    this.metaTagsService.getMetaTagKeys().subscribe((res) => {
      let metaTagsColumnStrings = res.map((metaTag) => metaTag);
      //add the meta tags columns to the table columns
      let columnsStruct = this.reportsTableColumns.map(
        (col) => new ColumnStruct(col)
      );
      let metaTagsKeys = this.generateDynamicColumns(metaTagsColumnStrings);
      metaTagsKeys = metaTagsKeys.map((column) => {
        column = new ColumnStruct(column);
        column.state.visible = this.storageService.getItem(column.columnKey) ?? false;
        column.isDynamicColumn = true;
        column.enableSearch = true;
        column.enableSort = true;
        column.getter = (document: ReportsTableStruct) => {
          const docData = this.DocumentsData.get(document.id);
          const mtKey = docData.metadatas.find(
            (metaTag) => metaTag.key == column.columnKey
          )?.key;
          if (!mtKey) return undefined;
          return docData.metadatas.find(
            (metaTag) => metaTag.key == column.columnKey
          )?.value;
        };
        
        return column;
      });
      columnsStruct = columnsStruct.concat(metaTagsKeys);

      this.reportsTableColumns = columnsStruct;
    });
  }

  protected BadgeColorType: BadgeColorType;
  protected currentDate: Date = new Date();

  protected DocumentsData: Map<string, DocumentModel> = new Map<
    string,
    DocumentModel
  >();

  /* ----------------------------- Documents type ----------------------------- */
  protected DocumentStatus: DocumentStatus;
  DocumentStatusColor: DocumentStatusColor;
  protected DocumentListType: DocumentListType[];

  /* ----------------------------- User type ----------------------------- */

  public DocUserStatus: DocUserStatus;
  DocUserStatusColor: DocUserStatusColor;

  public reportsTableData$: BehaviorSubject<ReportsTableStruct[]> =
    new BehaviorSubject<ReportsTableStruct[]>(null);

  protected reportsTableConfig:
    | TableConfigStruct<ReportsTableStruct>
    | TableConfig<ReportsTableStruct> = {
    tableTitle: 'reports',
    tableItemName: {
      plural: 'רשומות',
      singular: 'רשומה אחת',
    },
    enableSearch: true,
    enableCheckboxes: false,
    enableSort: true,
    enableGlobalTableMenu: false,
    enableDateRangeFiltering: true,
    tableActions: [],
    rowActions: [],
    sortKey: 'CreateDate',
    enableTablePresets: true,
    defaultSort: {
      defaultObjectKey: 'CreateDate',
      objectKeys: Object.values(SortableReportsTableColumn),
      direction: 'desc',
    },
  };

  protected reportsTableColumns:
    | IColumnStruct<ReportsTableStruct>[]
    | ColumnStruct<ReportsTableStruct>[];
  protected reportsTablePagination:
    | PagingConfigStruct<ReportsTableStruct>
    | PagingStruct<ReportsTableStruct> = {
    pageSize: computeRowPerPage(),
  };

  protected _tableType: DocumentListType[];

  public get tableType(): DocumentListType[] {
    return this._tableType;
  }

  set tableType(tableType: DocumentListType[] | DocumentListType) {
    Array.isArray(tableType)
      ? (this._tableType = tableType)
      : (this._tableType = [tableType]);
  }

  public exportDateFilterFG: FormGroup = new FormGroup({
    fromDate: new FormControl<Date | null>(null, Validators.required),
    toDate: new FormControl<Date | null>(null, Validators.required),
  });
  public static disabledExportButton: boolean = false;

  private static _disabledExportButton = false;

  protected get loaderVisible(): boolean {
    return ReportsComponent._disabledExportButton;
  }

  public static setLoader(visible: boolean) {
    ReportsComponent._disabledExportButton = visible;
  }

  protected queryInProgress: boolean = false;

  protected reportsLazyConfig: LazyTableConfigStruct<ReportsTableStruct> = {
    queryObservable: (queryRequest: IReportsQueryParams) => {
      if (this.queryInProgress == false) {
        this.queryInProgress = true;
        queryRequest.type = [DocumentListType.Sent, DocumentListType.Received];
        let queryCycleRequest: ReportsQueryRequest = new ReportsQueryRequest(
          queryRequest
        );

        const usersObs$: Observable<UserModel[]> =
          this.usersService.refreshUsersList();
        const docsObs$: Observable<IQueryResponse> =
          this.documentsService.query(queryCycleRequest);

        return combineLatest([usersObs$, docsObs$]).pipe(
          catchError((err) => {
            console.log(err);
            this.snackbar.open({
              message: 'errorLoadingDataReports',
              type: 'error',
            });
            ReportsComponent.setLoader(false);
            return [];
          }),
          map((result) => {
            const [usersObs, docsObs] = result;
            const usersRes: UserModel[] = usersObs as UserModel[];
            const docsRes: IQueryResponse = docsObs as IQueryResponse;

            docsRes?.documents?.forEach((doc) => {
              this.DocumentsData.set(doc.id, doc);
            });

            const reportsTableData = new ReportsDataModel(
              usersRes,
              docsRes.documents,
              this.translate
            );
            const rowTableData = reportsTableData.setRowTableDataModel(
              docsRes.documents,
              usersRes
            );
            this.queryInProgress = false;
            this.reportsTableData$.next(rowTableData);
            return {
              rows: rowTableData,
              totalCount: docsObs.totalDocuments,
            };
          })
        );
      }
      return of(undefined);
    },

    allTableEntriesObservable: () => {
      return this.getAllTableCycles();
    },
  };

  /* ----------------------------- Dynamic Columns ---------------------------- */
  protected generateDynamicColumns(
    columns: IMetaTagKeySchema[]
  ): ColumnStruct<ReportsTableStruct>[] {
    let startIndex = this.reportsTableColumns.length + 1;
    return columns.map((columnName) => {
      return {
        index: startIndex++,
        title: columnName.name,
        columnKey: columnName.key,
      };
    });
  }

  protected reportTableColumnDataRetriever: Record<
    TableObjectKey,
    (row: any) => Observable<any>
  > = reportTableColumnDataRetriever();

  documentData: string = '';

  private getAllTableCycles(
    type: DocumentListType[] = this.tableType
  ): Observable<ReportsTableStruct[]> {
    const tableQueryParams: QueryTableParamsStruct =
      TableConnector.instance.getQueryParams();
    const fromDate: Date = this.exportDateFilterFG.get('fromDate').value;
    const toDate: Date = this.exportDateFilterFG.get('toDate').value;
    if (!fromDate && !toDate) return of([]);
    const queryAllTableCyclesRequest: ReportsQueryRequest =
      new ReportsQueryRequest({
        page: 1,
        size: 0,
        query: tableQueryParams.searchText,
        sort: tableQueryParams.sortConfig,
        fromDate: moment(fromDate).startOf('day').toDate() as Date,
        toDate: moment(toDate).endOf('day').toDate() as Date,
        type,
      });
    const usersObs$: Observable<UserModel[]> =
      this.usersService.refreshUsersList();
    const documentsQuery$: Observable<IQueryResponse> =
      this.documentsService.query(queryAllTableCyclesRequest);

    return this.documentsService.query(queryAllTableCyclesRequest).pipe(
      first(),
      switchMap((response) => {
        return combineLatest([usersObs$, documentsQuery$]).pipe(
          catchError((err) => {
            console.log(err);
            this.snackbar.open({
              message: 'errorLoadingDataReports',
              type: 'error',
            });
            return [];
          }),
          map((result: [UserModel[], IQueryResponse]) => {
            const usersRes: UserModel[] = result[0];
            const docsRes: IQueryResponse = result[1];
            if (docsRes.documents.length == 0) {
              this.snackbar.open({
                message: 'export-not-fount',
                type: 'warn',
              });
              ReportsComponent.setLoader(false);
              return [];
            }
            const reportsTableData = new ReportsDataModel(
              usersRes,
              docsRes.documents,
              this.translate
            );
            return reportsTableData.setRowTableDataModel(
              docsRes.documents,
              usersRes
            );
          })
        );
      })
    );
  }

  exportTableData() {
    const tableComponent: TableComponent<ReportsTableStruct> =
      this.dialogService.getDialogData('exportDataDialog');
    if (tableComponent) {
      const columns: {
        objectKey: string;
        title: string;
        visible: boolean;
      }[] = tableComponent.columns.map(
        (col: IColumnStruct<ReportsTableStruct>) => {
          return {
            objectKey: col.objectKey,
            title: col.title,
            visible: col.state.visible,
          };
        }
      );
      tableComponent.exportTableDataToExcel();
    }
  }

  openPreviewWithData(id: string) {
    TableComponent.setLoader(true, 'loading-document');
    this.documentsService
      .getDocumentData(id)
      .pipe(
        catchError((err) => {
          this.snackbar.open({
            message: 'loading-document-error',
            type: 'Error',
          });
          throw err;
        })
      )
      .subscribe((res) => {
        if (!res) return;
        this.documentData = res;
        TableComponent.setLoader(false);
        this.dialogService.open('previewDialog');
      });
  }

  notifyRecipients(id: string) {
    this.documentsService
      .notifyRecipients(id)
      .pipe(
        catchError((err) => {
          this.snackbar.open({
            message: 'notify-recipients-error',
            type: 'Error',
          });
          throw err;
        })
      )
      .subscribe((res) => {
        res
          ? this.snackbar.open({
              message: 'notify-recipients-success',
              type: 'success',
            })
          : this.snackbar.open({
              message: 'notify-recipients-error',
              type: 'Error',
            });
      });
  }

  
  downloadDocument(id: string, fileName:string) {
    this.documentsService
      .getDocumentData(id)
      .pipe(
        catchError((err) => {
          this.snackbar.open({
            message: 'download-document-error',
            type: 'Error',
          });
          throw err;
        })
      )
      .subscribe((res) => {
        if(res){
          this.downloadPdf(res, fileName)
        }
      });
  }


  downloadPdf(base64String: string, fileName:string) {
    const binaryString = window.atob(base64String);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'application/pdf' });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();

    this.snackbar.open({
      message: 'download-document-success',
      type: 'success',
    })
    

    window.URL.revokeObjectURL(url);
  }



}
