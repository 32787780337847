import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

export const SkeletonLoaderColorType = {
	Gray: 'lightgray',
	White: 'white',
} as const;

export type SkeletonLoaderColorType =
	| Lowercase<keyof typeof SkeletonLoaderColorType>
	| (typeof SkeletonLoaderColorType)[keyof typeof SkeletonLoaderColorType];

@Directive({
	selector: '[SkeletonLoader]',
	standalone: true,
})
export class SkeletonLoaderDirective {
	private readonly skeletonLoaderClasses: string[] = ['w-[80%]', 'max-h-[80%]', 'animate-pulse', 'rounded-2xl'];

	constructor(private element: ElementRef, private renderer: Renderer2) {}

	@Input() set SkeletonLoader(props: boolean | { show: boolean; bg: SkeletonLoaderColorType }) {
		let show: boolean = typeof props === 'boolean' ? props : props.show;
		let bg: string = typeof props === 'boolean' ? SkeletonLoaderColorType.Gray : props.bg === 'gray' ? SkeletonLoaderColorType.Gray : props.bg;
		if (show) {
			this.skeletonLoaderClasses.forEach(className => {
				this.renderer.addClass(this.element.nativeElement, className);
			});
			this.renderer.setStyle(this.element.nativeElement, 'background-color', bg);
			this.renderer.setStyle(this.element.nativeElement, 'opacity', 0.2);
		} else {
			console.log('hide');
		}
	}
}

/**
 * Example usage:
 * 							<ng-container *ngIf="dataManager.loading; else ActualBodyCell">
								<div
									[style.height.px]="bodyCellRef.clientHeight * 0.4"
									[SkeletonLoader]="{ show: true, bg: 'gray' }"
								></div>
 *
 *
 */
