import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceDirective } from 'src/app/Directives/device.directive';
import { DocIconComponent } from '../icon/icon.component';


const materialModules = [MatFormFieldModule, MatButtonModule, MatTooltipModule];
const shared_standalone = [
  DocIconComponent,
];
const imports_exports = [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	TranslateModule,
	...shared_standalone,
	...materialModules,
	DeviceDirective,
];

@NgModule({
	declarations: [],
	imports: [...imports_exports],
	exports: [...imports_exports],
	providers: [],
})
export class TableSharedModule {}
