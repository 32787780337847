<div class="outer">
	<div class="spinner" *ngIf="loading">
		<i
			[style]="{ '--fa-primary-color': color, '--fa-secondary-color': color }"
			class="animate-spin-slow fa-duotone fa-spin"
			[class]="'fa-' + type"
		></i>
    <!-- ------------------------------- Message ------------------------------- -->
    <div class="message" *ngIf="message">
      <h6>{{ message | translate }}</h6>
      </div>
	</div>
	<ng-content></ng-content>
</div>
