import { distinctUntilKeyChanged } from 'rxjs';
import { LanguageService } from 'src/app/Data/Services/language.service';
import { Directive, HostBinding, ElementRef, Input, AfterViewInit, Renderer2 } from '@angular/core';
import { AppInjector } from '../app.component';

@Directive({
	selector: '[dc-dir]',
	standalone: true,
})
export class DirectionDirective implements AfterViewInit {
	private languageService: LanguageService = AppInjector.get(LanguageService);

	constructor(private element: ElementRef, private renderer: Renderer2) {}

	@HostBinding('attr.dir') get dir() {
		return this.languageService.CurrentLanguage.dir;
	}

	@Input() dirElements: string[] = [];

	@Input() ltrClass: string;
	@Input() rtlClass: string;

	@Input() ltrStyles: { [key: string]: string };
	@Input() rtlStyles: { [key: string]: string };

	ngAfterViewInit(): void {
		if (this.ltrClass || this.rtlClass || this.ltrStyles || this.rtlStyles) {
			this.languageService.currentLanguage$.pipe(distinctUntilKeyChanged('value')).subscribe(lang => {
				if (lang.dir === 'rtl') {
					if (this.rtlClass) this.element.nativeElement.classList.add(this.rtlClass);
					if (this.rtlStyles)
						Object.keys(this.rtlStyles).forEach(key => {
							this.renderer.setStyle(this.element.nativeElement, key, this.rtlStyles[key]);
						});
				} else if (lang.dir === 'ltr') {
					if (this.ltrClass) this.element.nativeElement.classList.add(this.ltrClass);
					if (this.ltrStyles)
						Object.keys(this.ltrStyles).forEach(key => {
							this.renderer.setStyle(this.element.nativeElement, key, this.ltrStyles[key]);
						});
				}
			});
		}
		if (this.dirElements) {
			const elements = this.dirElements;
			elements.forEach(element => {
				this.element.nativeElement.querySelector(element)?.setAttribute('dir', this.languageService.CurrentLanguage.dir);
			});
		}
	}
}
