import {StorageModeType} from 'src/app/Data/Services/storage.data/storage-mode.type';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CdkDrag, CdkDragEnd, DragDropModule} from '@angular/cdk/drag-drop';
import {CdkConnectedOverlay, ConnectedPosition, OverlayModule} from '@angular/cdk/overlay';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule, MatMenuTrigger} from '@angular/material/menu';
import {MatDrawerMode, MatSidenavModule} from '@angular/material/sidenav';
import {MatTooltipModule} from '@angular/material/tooltip';
import { Router, RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {distinctUntilKeyChanged, filter} from 'rxjs';
import {DocIconComponent} from 'src/app/modules/icon/icon.component';
import {AuthService} from 'src/app/Data/Services/auth.service';
import {LanguageService} from 'src/app/Data/Services/language.service';
import {StorageKeys} from 'src/app/Data/Services/storage.data/storage-keys.data';
import {LongClickDirective} from 'src/app/Directives/long-click.directive';
import {DirectionDirective} from 'src/app/Directives/direction.directive';
import {HoverDirective} from 'src/app/Directives/hover.directive';
import {IsMobileDirective} from 'src/app/Directives/is-mobile.directive';
import {LoaderDirective} from 'src/app/Directives/loder.directive';
import {DeviceService} from 'src/app/Data/Services/device/device-service.service';
// import {CompanySettingsService} from 'src/app/Services/news/company-settings.service';
import {AppVersionControl} from 'src/app/Data/app-version.contrtol';
import {IUserPortrait} from 'src/app/Data/Entities/User/user-portrait.model';
import {DcSidenavItemStruct} from './data/sidenav-item-struct.model';
import {getSideNavItems} from './data/sidenav.const/sidenav-shared.const';
import {SideNavControl} from './data/sidenav.control/sidenav.control';
import {SidenavItemComponent} from './sub-components/sidenav-item/sidenav-item.component';
import {UserProfileCardComponent} from './sub-components/user-profile-card/user-profile-card.component';
import { UsersService } from 'src/app/Data/Services/users.service';

const standalones = [
  SidenavItemComponent,
  UserProfileCardComponent,
  DirectionDirective,
  LoaderDirective,
  HoverDirective,
  DocIconComponent,
  IsMobileDirective,
  LongClickDirective,
];
const commons = [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, NgOptimizedImage];
const matModules = [
	MatSidenavModule,
	MatTooltipModule,
	MatInputModule,
	MatFormFieldModule,
	MatDividerModule,
	MatListModule,
	OverlayModule,
	RouterModule,
	MatButtonModule,
	DragDropModule,
	MatMenuModule,
];

@Component({
  selector: 'dc-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: true,
  imports: [...commons, ...matModules, ...standalones],
  animations: [
    trigger('drawerState', [
      state(
        'expanded',
        style({
            transform: 'translateX(100px)',
        }),
      ),
      state(
        'collapsed',
				style({
					 transform: 'translateX(50px)',
				}),
			),
			transition('collapsed => expanded', animate('250ms ease-in')),
			transition('expanded => collapsed', animate('250ms ease-out')),
		]),
	],
})
export class SidenavComponent implements OnInit {

    protected AuthService: typeof AuthService = AuthService;
    protected UsersService: typeof UsersService = UsersService;

    protected AppVersionControl: AppVersionControl = AppVersionControl.instance;
     // protected CompanySettingsService: typeof CompanySettingsService = CompanySettingsService;
    protected deviceService: DeviceService = window.deviceService;
	/* --------------------------------- Inputs --------------------------------- */

	/* --------------------------------- Outputs -------------------------------- */
	@Output() toggleSidenav = new EventEmitter<void>();

	/* ------------------------- SideNav Configurations ------------------------- */
	protected readonly mode: MatDrawerMode = 'side';
    /* ---------------------------- SideNav Variables --------------------------- */
	protected sideNavCtrl: SideNavControl = SideNavControl.instance;
    // SideNav Items
	protected sidenavTopItems: DcSidenavItemStruct[] = getSideNavItems(['general', 'documents', 'templates']);
	protected sidenavBottomItems: DcSidenavItemStruct[] = getSideNavItems(['more']);
	
	/* --------------------------------- Search --------------------------------- */
	protected searchFC: FormControl = new FormControl('');

	/* ---------------------------- User Profile Data --------------------------- */
    @ViewChild('userProfileCard', {static: true}) userProfileCardOverlay: CdkConnectedOverlay;

	protected userProfileOpened: boolean = false;
	protected readonly userProfileCardPositions: ConnectedPosition[] = [
		// RTL
		{
			originX: 'start',
			originY: 'bottom',
			offsetY: -10,
			offsetX: -10,
			overlayX: 'start',
			overlayY: 'bottom',
		},
		// LTR
		{
			originX: 'end',
			originY: 'bottom',
			offsetY: -10,
			offsetX: +10,
			overlayX: 'end',
			overlayY: 'bottom',
		},
	];
	protected get currentUserPortrait(): string {
		const portrait = window.storageService.getItem<IUserPortrait>(StorageKeys.AuthServiceKeys.KeyCurrentUserPortrait);
		if(!portrait) return "/assets/icons/user-icon.gif"
    return portrait?.image;
	}

  protected get companyLogo():string{
	const logo = window.storageService.getItem<string>(StorageKeys.CompanySettingsKeys.KeyCompanyLogo);
	return logo ? logo : ""
  }

	/* ------------------------ Sidenav Menu Interactions ----------------------- */
  @ViewChild('sidenavMenuOptsTrigger', {static: true}) set _sidenavMenuOptsTrigger(sidenavMenuOptsTrigger: MatMenuTrigger) {
    // prevent menu click events
    if (sidenavMenuOptsTrigger) {
      sidenavMenuOptsTrigger._handleMousedown = () => {
      };
      sidenavMenuOptsTrigger._handleClick = () => {
      };
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                                Constructor                                */

  /* -------------------------------------------------------------------------- */
	constructor(private router: Router,protected authService: AuthService, protected languageService: LanguageService, private userService: UsersService) {
    languageService.currentLanguage$
      .pipe(
        distinctUntilKeyChanged('value'),
        filter(_ => this.userProfileOpened == true),
      )
      .subscribe(_ => {
        // close user profile card
        this.userProfileOpened = false;
      });
	}

	/* -------------------------------------------------------------------------- */
	/*                               LifeCycle Hooks                              */
	/* -------------------------------------------------------------------------- */

  ngOnInit(): void {
   /*-------------------------------------------*/
   /* removing access data from url after login */
   /*-------------------------------------------*/
    if (location.href.includes('?accessToken=')){
      this.router.navigate([], {
        queryParams: {
          'accessToken': null,
          'refreshToken': null,
          'tenant':null
        },
        queryParamsHandling: 'merge'
      })
    }
	this.userService.refreshCurrentUser();
    }

	/* -------------------------------------------------------------------------- */
	/*                              Protected Methods                             */
	/* -------------------------------------------------------------------------- */

	protected preventMouseOnDrag(event: MouseEvent, drag: CdkDrag) {
		if (drag && drag._dragRef.isDragging()) {
			event.stopPropagation();
			event.preventDefault();
		} else {
			this.sideNavCtrl.toggle();
		}
	}

	/* -------------------------------------------------------------------------- */
	/*                          Float Open Sidenav button                         */
	/* -------------------------------------------------------------------------- */
	protected floatSidenavOpenBtnTransform: string =
		window.storageService.getItem<string>(StorageKeys.SidenavKeys.KeyFloatOpenSidenavBtnTransform) || 'translate3d(0px, 0px, 0px)';

	protected onDragOpenSidenavBtnEnded(e: CdkDragEnd<any>) {
		const top = e.distance.y;
		const transform: string = `translate3d(0px, ${top}px, 0px)`;
		window.storageService.setItem(StorageKeys.SidenavKeys.KeyFloatOpenSidenavBtnTransform, transform, StorageModeType.Local);
	}


  /* -------------------------------------------------------------------------- */
  /*                                Company Name                                */
  /* -------------------------------------------------------------------------- */
  protected initials(name: string): string {
    // if the name is not defined, return empty string;
    // if the name is defined:
    // if the name is a single word, return the first 2 letters of the word
    // if the name is a multi-word name, return the first letter of first 2 words ()
    // all result is uppercased
    if (!name) return '';
    const words = name.split(' ');
    if (words.length === 1) return words[0].substring(0, 2).toUpperCase();
    return words[0].substring(0, 1).toUpperCase() + words[1].substring(0, 1).toUpperCase();
  }
}
