import { TableSharedModule } from './../../table.module';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'empty-table-results',
	standalone: true,
	imports: [CommonModule, TableSharedModule],
	templateUrl: './empty-table-results.component.html',
	styleUrls: ['./empty-table-results.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyTableResultsComponent {
	@Input() showClearButton: boolean = false;
	@Output() clear: EventEmitter<void> = new EventEmitter<void>();
	constructor() {}
}
