import { AuthService } from 'src/app/Data/Services/auth.service';
import { Direction } from '@angular/cdk/bidi';
import { Language, LanguageType } from './language.data/language.types';
import { Injectable } from '@angular/core';
import { AppInjector } from 'src/app/app.component';
import { getLanguage } from './language.data/language.const';
import { BehaviorSubject, distinctUntilChanged, distinctUntilKeyChanged, filter } from 'rxjs';
import { UsersService } from 'src/app/Data/Services/users.service';
import { StorageKeys } from 'src/app/Data/Services/storage.data/storage-keys.data';
import { StorageModeType } from 'src/app/Data/Services/storage.data/storage-mode.type';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageService {
  public currentLanguage$: BehaviorSubject<Language> = new BehaviorSubject<Language>(getLanguage('he'));

  constructor(private userService: UsersService, private translateService: TranslateService) {
		this.currentLanguage$.pipe(distinctUntilKeyChanged('value')).subscribe(lang => {
			document.documentElement.setAttribute('lang', lang.locale_code);
			document.documentElement.setAttribute('dir', lang.dir);
		});

		UsersService.currentUser$
			.pipe(
				filter(u => Boolean(u) && window.storageService.getItem(StorageKeys.AuthServiceKeys.KeyLanguage) != u?.preferences?.language),
				distinctUntilChanged((u1, u2) => u1?.preferences?.language == u2?.preferences?.language),
			)
			.subscribe(user => {
				if (user?.preferences?.language) {
					this.setLanguage(user.preferences.language, false);
				}
			});
	}


  public setLanguage(language: Language | LanguageType | string, updatePrefrences: boolean = false): void {
		let lang: Language;
		if (typeof language === 'string') {
			lang = getLanguage(language);
		} else lang = language;

		this.currentLanguage$.next(lang);
    this.translateService.use(lang.value);
		window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyLanguage, lang.value, StorageModeType.Local);
		if (updatePrefrences) this.updateUserPreferredLanguage(lang);
	}

	public get CurrentLanguage(): Language {
		return this.currentLanguage$.getValue();
	}

	/* ---------------------------- Current Direction --------------------------- */
	public get CurrentDir(): Direction {
		return this.CurrentLanguage?.dir == 'rtl' ? 'rtl' : 'ltr';
	}

	public static get Dir(): Direction {
		return AppInjector.get(LanguageService).CurrentDir;
	}

	/* -------------------------------------------------------------------------- */
	/*                                HTTP METHODS                                */
	/* -------------------------------------------------------------------------- */
	public updateUserPreferredLanguage(language: Language): void {
		const langPropPath = 'preferences.language';
		// this.userService.updateCurrentUserProperties({ [langPropPath]: language.value }).subscribe(_ => {});
	}
}
