import { Direction } from '@angular/cdk/bidi';
import { ScrollStrategy } from '@angular/cdk/overlay';
import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { AutoFocusTarget, DialogPosition, DialogRole, MatDialogConfig } from '@angular/material/dialog';
import { DeviceService } from 'src/app/Data/Services/device/device-service.service';
import { LanguageService } from 'src/app/Data/Services/language.service';
import { DcColorType } from 'src/styles/doc-theme.data';
import { DialogControlService, DialogRefStructure } from './dialog-control.service';

export class DialogConfig extends MatDialogConfig {
	events: DialogEventType[] = [];
	override data: any;

	borderColor: DcColorType = DcColorType.Primary;
	constructor(init?: Partial<DialogConfig>) {
		super();
		Object.assign(this, init);
	}
}

export const DialogEventType = {
	beforeClosed: 'beforeClosed',
	onOpened: 'onOpened',
	afterOpened: 'afterOpened',
	afterClosed: 'afterClosed',
	keydownEvents: 'keydownEvents',
	backdropClick: 'backdropClick',
} as const;
export type DialogEventType = keyof typeof DialogEventType;

@Directive({
	selector: '[DcDialog]',
	standalone: true,
})
export class DialogDirective implements OnInit, OnDestroy {
	dialogRef: DialogRefStructure;


	@Input() DcDialog: string;
	@Input() dialogConfig: DialogConfig = undefined;

	//dialog properties
	@Input() role?: DialogRole;
	@Input() panelclass?: string | string[];
	@Input() hasbackdrop?: boolean;
	@Input() backdropclass?: string | string[];
	@Input() disableclose?: boolean;
	@Input() width?: string;
	@Input() height?: string;
	@Input() minwidth?: number | string;
	@Input() minheight?: number | string;
	@Input() maxwidth?: number | string;
	@Input() maxheight?: number | string;
	@Input() position?: DialogPosition;
	@Input() direction?: Direction;
	@Input() autofocus?: AutoFocusTarget | string;
	@Input() scrollstrategy?: ScrollStrategy;
	@Input() closeonnavigation?: boolean;
	@Input() borderColor: DcColorType = DcColorType.Primary;

	//custom dialog inputs and outputs
	/**
	 * @param props :[width: string | 'full', height: string | 'full']
	 */
	mobileProps: { width: string; height: string; minWidth: string; minHeight: string; maxWidth: string; maxHeight: string } | undefined = undefined;

	/**{ width: string; height: string; minWidth: string; minHeight: string; maxWidth: string; maxHeight: string }*/
	@Input() set mobileDialogDims(
		props: [
			width: string | 'full' | undefined,
			height: string | 'full' | undefined,
			maxWidth?: string | 'full' | undefined,
			maxHeight?: string | 'full' | undefined,
		],
	) {
		if (this.deviceService.isMobile || this.deviceService.isTablet) {
			const [width, height, maxWidth, maxHeight] = props;
			this.mobileProps = {
				width: '',
				height: '',
				minWidth: '',
				minHeight: '',
				maxWidth: '',
				maxHeight: '',
			};

			if (width == 'full') {
				this.fullWidth = true;
				this.mobileProps.width = this.mobileProps.maxWidth = this.mobileProps.minWidth = '98vw';
			} else if (typeof width == 'string') {
				this.mobileProps.width = this.width = width;
			}
			if (height == 'full') {
				this.fullHeight = true;
				this.mobileProps.height = this.mobileProps.maxHeight = this.mobileProps.minHeight = '98vh';
			} else if (typeof height == 'string') {
				this.height = this.mobileProps.height = height;
			}
			if (maxWidth == 'full') {
				this.maxwidth = '100%';
				this.mobileProps.maxWidth = '98vw';
			} else if (typeof maxWidth == 'string') {
				this.maxwidth = maxWidth;
				this.mobileProps.maxWidth = maxWidth;
			} else if (maxHeight == 'full') {
				this.mobileProps.maxHeight = this.maxheight = '100%';
			} else if (typeof maxHeight == 'string') {
				this.mobileProps.maxHeight = this.maxheight = maxHeight;
			}
			this.fullScreen = [undefined, undefined];
		}
	}

	/** A setter for the fullScreen property:  [width: boolean, height: boolean, onlyMobile: boolean]. */
	@Input() set fullScreen(props: [width: boolean, height: boolean] | true) {
		if (props == true) {
			this.fullWidth = true;
			this.fullHeight = true;
		} else {
			const [width, height] = props;
			if (width == undefined && height == undefined) return;
			if (width == true) this.fullWidth = true;
			if (height == true) this.fullHeight = true;
		}
	}

	@Input() set fullWidth(fullW: boolean) {
		if (fullW == true) {
			this.width = this.minwidth = this.maxwidth = '98vw';
		}
	}

	@Input() set fullHeight(fullH: boolean) {
		if (fullH == true) {
			this.height = '100%';
			this.minheight = this.maxheight = '98vh';
		}
	}

	@Output() onOpened = this.dialogService.onOpened;
	@Output() dialogEvents: EventEmitter<[DialogEventType, any]> = new EventEmitter();

	constructor(
		public dialogHost: TemplateRef<any>,
		private dialogService: DialogControlService,
		private languageService: LanguageService,
		private deviceService: DeviceService,
	) {}

	ngOnInit(): void {
		this.dialogRef = this.dialogService.registerDialog(this, this.DcDialog, this.dialogHost, this.generateDialogConfigurations());
		this.dialogService.dialogEvents.subscribe(([event, data]) => {
		  this.dialogEvents.emit([event, data]);
		});
	  }

	ngOnDestroy(): void {
		this.dialogService.unregisterDialog(this.DcDialog);
	}

	generateDialogConfigurations(): DialogConfig {
		if (this.dialogConfig == undefined) {
			const config = {
				id: this.DcDialog,
				role: this.role,
				panelClass: this.panelclass,
				hasBackdrop: this.hasbackdrop,
				backdropClass: this.backdropclass,
				disableClose: this.disableclose,
				direction: this.direction ? this.direction : this.languageService.CurrentLanguage.dir == 'rtl' ? 'rtl' : 'ltr',
				ariaDescribedBy: '',
				ariaLabel: '',
				ariaLabelledBy: '',
				componentFactoryResolver: undefined,
				data: undefined,
				delayFocusTrap: false,
				enterAnimationDuration: '',
				events: [],
				exitAnimationDuration: '',
				injector: undefined,
				restoreFocus: false,
				viewContainerRef: undefined,
				position: this.position,
				autoFocus: this.autofocus,
				scrollStrategy: this.scrollstrategy,
				closeOnNavigation: this.closeonnavigation,
				width: this.width,
				height: this.height,
				minWidth: this.minwidth,
				minHeight: this.minheight,
				maxWidth: this.maxwidth,
				maxHeight: this.maxheight,
			};
			if ((this.deviceService.isMobile || this.deviceService.isTablet) && this.mobileProps != undefined) {
				config.width = this.mobileProps.width;
				config.height = this.mobileProps.height;
				config.minWidth = this.mobileProps.minWidth;
				config.minHeight = this.mobileProps.minHeight;
				config.maxWidth = this.mobileProps.maxWidth;
				config.maxHeight = this.mobileProps.maxHeight;
			}
			this.dialogConfig = new DialogConfig(config);
			this.dialogConfig.borderColor = this.borderColor;
		}
		return this.dialogConfig;
	}
}
