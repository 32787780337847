<div class="flex space-x-2">
	<div
		style="padding-top: 0.2em; padding-bottom: 0.2rem"
    [ngClass]="backgroundColor"
		class="flex items-center gap-1 space-x-1 rounded-full px-2 text-xs"
    [style.color]="badgeColor"

	>
		<!-- DOT (optional) -->
		<div
			*ngIf="enableDot"
			style="width: 0.4rem; height: 0.4rem"
			class="rounded-full"
      [style.background-color]="badgeColor"
		></div>
		<!-- TEXT -->
		<div>{{ text | translate }}</div>
	</div>
</div>
