import {catchError, map, Observable, of} from 'rxjs';
import {computed, Injectable, Signal, signal} from '@angular/core';

import {toObservable} from '@angular/core/rxjs-interop';
import { ApiService } from 'src/app/Data/Services/api.service';
import { LanguageService } from 'src/app/Data/Services/language.service';
import { MetaTagKeysModel, IMetaTagKeySchema } from '../data/metatag-table-struct.type';

@Injectable({
	providedIn: 'root',
})
export class MetaTagsService {
	availableKeys = signal<MetaTagKeysModel>(undefined);
	enabledKeys: Signal<IMetaTagKeySchema[]> = computed(() => this.availableKeys()?.enabledKeys);

	byTagKey: Signal<{[key: string]: IMetaTagKeySchema}> = computed(() => {
		const keys = this.availableKeys()?.enabledKeys;
		if (!keys) return undefined;
		return keys.reduce((acc, key) => {
			acc[key.key] = key;
			return acc;
		}, {});
	});

	byTagName: Signal<{[key: string]: IMetaTagKeySchema}> = computed(() => {
		const keys = this.availableKeys()?.enabledKeys;
		if (!keys) return undefined;
		return keys.reduce((acc, key) => {
			acc[key.name] = key;
			return acc;
		}, {});
	});

	/* ----------------- Indicators ----------------- */
	metaTagsInitialized$: Observable<boolean> = toObservable(this.availableKeys).pipe(map(_ => !!_));

	constructor(
		public api: ApiService,
		private languageService: LanguageService,
	) {}

	public getMetaTagKeys(containDisables: boolean = false, force: boolean = false): Observable<IMetaTagKeySchema[]> {
		if (this.availableKeys() && !force) {
			return of(containDisables ? this.availableKeys()?.allKeys : this.availableKeys()?.enabledKeys);
		}
		return this.api.get<{metaTags:IMetaTagKeySchema[]}>('MetaTag/list').pipe(
			catchError(_ => {
				return of(undefined);
			}),
			map(res=> {
				this.availableKeys();
				return res.metaTags;
				//return containDisables ? this.availableKeys()?.allKeys : this.availableKeys()?.enabledKeys;
			}),
		);

	}

	createMetaTag(request: IMetaTagKeySchema): Observable<boolean> {
		return this.api.post<boolean>('MetaTag/metatag', null, request).pipe(
			catchError(_ => {
				return of(false);
			}),
			map(_ => {
				return true;
			}),
		);
	}

	deleteMetaTag(key: string): Observable<boolean> {
		return this.api.delete<boolean>('MetaTag/metatag', {key}).pipe(
			catchError(_ => {
				return of(false);
			}),
			map(_ => {
				return true;
			}),
		);
	}

	updateMetaTag(request: IMetaTagKeySchema): Observable<boolean> {
		return this.api.put<boolean>('MetaTag/metatag', request).pipe(
			catchError(_ => {
				return of(false);
			}),
			map(_ => {
				return true;
			}),
		);
	}
}
