<mat-menu
	#rowMenu="matMenu"
	class="row-action-menu"
	[hasBackdrop]="false"
  *ngIf="actions?.length > 0"
>
	<div
		class="flex min-w-[9rem] flex-col"
		*ngFor="let item of actions; let i = index"
	>
		<ng-container *dcDevice="item.mobile == false ? ['desktop', 'tablet'] : true">
			<ng-container *ngIf="item?.hidden({ data: item?.data, rowId: activeRow?.id }) != true">
				<button
					class="px-2shadow h-full w-full select-none bg-white py-1 font-semibold text-gray-800 hover:bg-gray-100"
					(click)="item.onClickFn({ event: activeRowEvent, data: item?.data })"
				>
					<span class="mx-2 flex items-center gap-2">

            <DocIcon
            [name]="item.iconName"
            type="duotone"
            size="lg"
            [primaryColor]="dcColor(item.color)"
            [secondaryColor]="dcColor(item.color)"
            />
						<h6 class="text-lg">{{ item.label(item?.data) | translate }}</h6>
					</span>
				</button>
				<mat-divider *ngIf="i < actions.length - 1"></mat-divider>
			</ng-container>
		</ng-container>
	</div>
</mat-menu>
