<div class="absolute top-0 left-10 pt-12">
  <docLanguage-toggle />
</div>

<div
  CurrentDir
  class="h-full w-full bg-gray-100 text-gray-900 flex justify-center overflow-hidden"
>
  <div
    class="max-w-screen-xl m-0 sm:m-10 bg-white shadow sm:rounded-lg flex justify-center flex-1"
    [ngStyle]="{ 'flex-direction': dir === 'ltr' ? 'row-reverse' : 'row' }"
  >
    <!-- ----------------------------------------------------------------------- -->
    <!--                              Form section                               -->
    <!-- ----------------------------------------------------------------------- -->
    <div class="lg:w-1/2 xl:w-7/12 p-6 sm:p-1">
      <div>
        <img src="../../../assets/logo/logo.png" class="w-24 md:w-32 2xl:w-60 mx-auto" />
      </div>
      <div class="mt-4 flex flex-col items-center">
        <h1 class="text-2xl xl:text-3xl font-extrabold">Login</h1>
        <div class="w-full flex-1 mt-8">
          <div class="mx-auto max-w-xs">
            <form [formGroup]="loginFormGroup" [dir]="dir === 'ltr' ? 'ltr' : 'rtl'">
              <div class="flex flex-col justify-center">
                <mat-form-field class="w-full">
                  <mat-label>{{ "pages.login.company" | translate }}</mat-label>
                  <input
                    matInput
                    type="text"
                    name="companyName"
                    formControlName="validateCompany"
                  />
                  <mat-icon matPrefix>domain</mat-icon>
                  <mat-error *ngIf="loginFormGroup.controls['validatePassword'].hasError('required')">
                    {{ "pages.login.errors.emptycompany" | translate }}
                  </mat-error>

                </mat-form-field>

                <mat-form-field class="w-full">
                  <mat-label>{{
                    "pages.login.username" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="text"
                    name="userName"
                    formControlName="validateUserName"
                  />
                  <mat-icon matPrefix>account_circle</mat-icon>
                  <mat-error *ngIf="loginFormGroup.controls['validatePassword'].hasError('required')">
                    {{ "pages.login.errors.emptyusername" | translate }}
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="w-full">
                  <mat-label>{{
                    "pages.login.password" | translate
                  }}</mat-label>
                  <input
                    matInput
                    name="password"
                    [type]="hide ? 'password' : 'text'"
                    formControlName="validatePassword"
                  />
                  <mat-icon *ngIf="loginFormGroup.controls['validatePassword'].value != ''" class="cursor-pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-icon matPrefix>https</mat-icon>
                  <mat-error *ngIf="loginFormGroup.controls['validatePassword'].hasError('required')">
                    {{ "pages.login.errors.emptypassword" | translate }}
                  </mat-error>
                </mat-form-field>

                <div class="flex justify-start">
                  <mat-checkbox formControlName="rememberMeChecked"
                    >{{ "pages.login.rememberme" | translate }}
                  </mat-checkbox>
                </div>

                <div class="pt-3">
                  <button
                    class=" tracking-wide font-semibold w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                    (click)="onLogin()"
                    color="primary"
                    mat-raised-button
                    [disabled]="disableLoginButton"
                  >
                    {{ "pages.login.login" | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- ----------------------------------------------------------------------- -->
    <!--                              Image section                              -->
    <!-- ----------------------------------------------------------------------- -->
    <div class="flex-1 bg-indigo-100 text-center hidden lg:flex">
      <div
        class="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
        style="background-image: url('../../../assets/login/docServer.png')"
      ></div>
    </div>
  </div>
</div>
