import { GlobalsService } from 'src/app/Data/Services/globals.service';
import { Direction } from '@angular/cdk/bidi';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, RendererFactory2 } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { AppInjector } from './../../../app.component';
import { SnackbarType } from './data/snackbar-type.type';
import { SnackbarActionStruct, SnackbarConfig, SnackbarPosition, SnackbarStruct } from './data/snackbar.types';
import { SnackbarComponent } from './snackbar.component';

@Injectable({
	providedIn: 'root',
})
export class SnackbarService {
	public activeSnackbar: SnackbarStruct<any>;

	/* -------------------------------------------------------------------------- */
	public static get instance(): SnackbarService {
		return AppInjector.get(SnackbarService);
	}

	constructor(private matSnackBar: MatSnackBar, rendererFactory: RendererFactory2) {}
	/* -------------------------------------------------------------------------- */
	public open<T>({
		message,
		type,
		fontSize,
		fontWeight,
		component,
		action,
		position,
		durationMS,
		direction,
		matConfig,
	}: {
		message: string;
		type: SnackbarType;
		fontSize?: 'small' | 'medium' | 'large' | 'x-large';
		fontWeight?: 'normal' | 'bold' | 'bolder' | 'lighter';
		component?: ComponentType<T>;
		action?: SnackbarActionStruct<T>;
		position?: SnackbarPosition;
		durationMS?: number;
		direction?: Direction;
		matConfig?: SnackbarConfig<T>;
	}): MatSnackBarRef<any> {
		message = GlobalsService.globalTranslate(message);
		this.activeSnackbar = {
			message,
			type,
			fontSize,
			fontWeight,
			component,
			action,
			position,
			durationMS,
			direction,
			matConfig,
		};
		this.activeSnackbar.matConfig = this.generateMatSnackBarConfig(this.activeSnackbar);

		if (component) {
			return this.matSnackBar.openFromComponent(component, this.activeSnackbar.matConfig);
		} else {
			const sb = this.matSnackBar.open(message, action?.label, this.activeSnackbar.matConfig);
			if (action?.action) sb.onAction().subscribe(() => action.action(this.activeSnackbar));
			return sb;
		}
	}

	private generateMatSnackBarConfig<T>(conf: SnackbarStruct<T>): MatSnackBarConfig {
		const isMobile = window.deviceService.isMobile;
		const matConf: MatSnackBarConfig = {
			...conf,
			horizontalPosition: conf.position?.horizontal || 'center',
			verticalPosition: conf.position?.vertical || isMobile ? 'top' : 'bottom',
			duration: conf?.durationMS || 5000,
			direction: conf.direction || GlobalsService.dir,
			panelClass: this.generateColorClass(conf.type, conf.matConfig?.panelClass),
		};
		conf.textAlign = 'center';
		if (conf.fontSize || conf.fontWeight || conf.textAlign) {
			if (matConf.panelClass) {
				if (!Array.isArray(matConf.panelClass)) {
					matConf.panelClass = [matConf.panelClass];
					if (conf.fontWeight) matConf.panelClass.push(`dc-snackbar-${conf.fontWeight}`);
					if (conf.fontSize) matConf.panelClass.push(`dc-snackbar-${conf.fontSize}`);
					if (conf.textAlign) matConf.panelClass.push(`dc-snackbar-text-${conf.textAlign}`);
				} else {
					if (conf.fontWeight) matConf.panelClass.push(`dc-snackbar-${conf.fontWeight}`);
					if (conf.fontSize) matConf.panelClass.push(`dc-snackbar-${conf.fontSize}`);
					if (conf.textAlign) matConf.panelClass.push(`dc-snackbar-text-${conf.textAlign}`);
				}
			} else {
				matConf.panelClass = [];
				if (conf.fontWeight) matConf.panelClass.push(`dc-snackbar-${conf.fontWeight}`);
				if (conf.fontSize) matConf.panelClass.push(`dc-snackbar-${conf.fontSize}`);
				if (conf.textAlign) matConf.panelClass.push(`dc-snackbar-text-${conf.textAlign}`);
			}
		}

		return matConf;
	}

	private generateColorClass(type: SnackbarType, panelClassArr?: string | Array<string>): Array<string> {
		const colorClass: string = `dc-snackbar-${type.toLowerCase()}`;
		if (Array.isArray(panelClassArr)) {
			panelClassArr.push(colorClass);
			return panelClassArr;
		} else if (panelClassArr) {
			return [panelClassArr, colorClass];
		}
		return [colorClass];
	}
}
