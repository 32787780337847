import { Component, Input, OnInit } from '@angular/core';
import { RecipientCellData } from '../reports/data/reports-table-struct';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'doc-user-circle',
    templateUrl: './user-circle.component.html',
    styleUrls: ['./user-circle.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, MatTooltipModule]
})
export class UserCircleComponent implements OnInit {
  @Input() recipients: RecipientCellData[];

  protected displayedRecipients: RecipientCellData[] = [];
  protected extraCount: number = 0;

  ngOnInit(): void {
    this.displayedRecipients = this.recipients.slice(0, 10);
    if (this.recipients.length > 10) {
      this.extraCount = this.recipients.length - 10;
    }
  }

  getCircleClasses(userStatus: string, index: number): string {
    let classes = 'font-semibold text-sky-900 text-lg leading-none border-2 w-12 h-12 rounded-full shadow-lg flex justify-center items-center';
    switch (userStatus) {
      case 'None':
        classes += ' bg-gray-100 border-gray-500';
        break;
      case 'Sent':
        classes += ' bg-blue-100 border-blue-400';
        break;
      case 'Accessed':
        classes += ' bg-green-100 border-green-400';
        break;
      default:
        classes += ' bg-red-100 border-red-400';
        break;
    }
    if (index >= 0) {
      classes += ' -mr-3';
    }
    return classes;
  }

  isEmail(text: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(text);
  }

  isPhone(text: string): boolean {
    const phonePattern = /(^05[0-9]{8}$)|(^05[0-9]-[0-9]{7}$)|(^\+[0-9]{3}\+[0-9]{9}$)/;
    return phonePattern.test(text);
  }

  isWhatsApp(text: string): boolean {
    return text.includes('+');
  }


  initials(name: string): string {
    if (!name) return '';
    const words = name.split(' ');
    if (words.length === 1) return words[0].substring(0, 2).toUpperCase();
    return words[0].substring(0, 1) + words[1].substring(0, 1).toUpperCase();
  }
}









