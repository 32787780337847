<generic-table
  rowsId="id"
  [config]="reportsTableConfig"
  [pagingConfig]="reportsTablePagination"
  [columns]="reportsTableColumns"
  [data]="reportsTableData$ | async"
  [lazyConfig]="reportsLazyConfig"
  [columnDataRetriever]="reportTableColumnDataRetriever"
  [exportDateRange]="exportDateFilterFG.value"
  (rowDblClicked$)="openPreviewWithData($event.id)"
/>

<!-- ----------------------------------------------------------------------- -->
<!--                        DocStatus costume column                         -->
<!-- ----------------------------------------------------------------------- -->

<ng-template
  let-getterValue
  let-row="row"
  let-cell="cell"
  let-rowIndex="rowIndex"
  let-column="column"
  let-getter="getter"
  CellTemplateCollector="docStatus"
>
  <docBadge
    *ngIf="cell"
    [text]="cell"
    [enableDot]="true"
    [badgeColor]="
      cell === 'Draft'
        ? 'gray'
        : cell === 'Sent'
        ? 'blue'
        : cell === 'Accessed'
        ? 'green'
        : 'red'
    "
  />
</ng-template>

<!-- ----------------------------------------------------------------------- -->
<!--                        Recipients(user circle) costume column                         -->
<!-- ----------------------------------------------------------------------- -->

<ng-template
  let-getterValue
  let-row="row"
  let-cell="cell"
  let-rowIndex="rowIndex"
  let-column="column"
  let-getter="getter"
  CellTemplateCollector="recipientCellData"
>
  <div class="flex items-center">
    <!-- <dc-multi-user-avatar [users]="cell" /> -->
    <doc-user-circle class="flex flex-row" [recipients]="cell" />
  </div>
</ng-template>

<!-- ----------------------------------------------------------------------- -->
<!--                      lastUpdate costume column                      -->
<!-- ----------------------------------------------------------------------- -->

<ng-template
  let-getterValue
  let-row="row"
  let-cell="cell"
  let-rowIndex="rowIndex"
  let-column="column"
  let-getter="getter"
  CellTemplateCollector="LastUpdate"
>
  <span>{{ cell | date : "dd/MM/yy HH:mm" }}</span>
</ng-template>

<!-- ----------------------------------------------------------------------- -->
<!--                      create doc costume column                          -->
<!-- ----------------------------------------------------------------------- -->

<ng-template
  let-getterValue
  let-row="row"
  let-cell="cell"
  let-rowIndex="rowIndex"
  let-column="column"
  let-getter="getter"
  CellTemplateCollector="CreateDate"
>
  <span>{{ cell | date : "dd/MM/yy HH:mm" }}</span>

  <!-- ----------------------------------------------------------------------- -->
  <!--                        Export dialog date filter                        -->
  <!-- ----------------------------------------------------------------------- -->

  <ng-template
    DcDialog="exportDataDialog"
    height="auto"
    maxheight="98vh"
    width="30%"
    [hasbackdrop]="true"
    [mobileDialogDims]="['100%', 'auto', '98vw', '78vh']"
  >
    <mat-card class="h-full max-h-full gap-2 overflow-hidden">
      <doc-loader [loading]="loaderVisible" [message]="'load-data'">
      <mat-card-header>
        <mat-card-title>{{ "export-data-title" | translate }}</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <div class="h-available overflow-auto text-lg">
        <mat-card-content>
          
          <div class="flex flex-col gap-2">
            <mat-form-field appearance="fill">
              <mat-label>{{ "export-chose-date-range" | translate }}</mat-label>
              <mat-date-range-input
                [formGroup]="exportDateFilterFG"
                [rangePicker]="picker"
                [max]="currentDate"
              >
                <input
                  matStartDate
                  formControlName="fromDate"
                  placeholder="{{ 'from-date-placeholder' | translate }}"
                />
                <input
                  matEndDate
                  formControlName="toDate"
                  placeholder="{{ 'to-date-placeholder' | translate }}"                 
                />
              </mat-date-range-input>
              <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error
                *ngIf="
                  exportDateFilterFG.controls.fromDate.hasError(
                    'matStartDateInvalid'
                  )
                "
                >{{ "date-error" | translate }}</mat-error
              >
              <mat-error
                *ngIf="
                  exportDateFilterFG.controls.toDate.hasError(
                    'matEndDateInvalid'
                  )
                "
                >{{ "date-error" | translate }}</mat-error
              >
            </mat-form-field>
          </div>
        
        </mat-card-content>
      </div>
      <mat-divider></mat-divider>
      <div class="w-full flex justify-center items-center">
        <mat-card-actions class="w-3/4 flex justify-between">
          <button
            mat-stroked-button
            color="primary"
            (click)="dialogService.close('exportDataDialog')"
          >
            {{ "cancel-button" | translate }}
          </button>
          <button
            [disabled]="exportDateFilterFG.invalid"
            mat-raised-button
            color="primary"
            (click)="exportTableData()"
          >
            {{ "export-button" | translate }}
          </button>
        </mat-card-actions>
      </div>
    </doc-loader>
    </mat-card>
  </ng-template>


  <!-- ----------------------------------------------------------------------- -->
  <!--                           Open preview dialog                           -->
  <!-- ----------------------------------------------------------------------- -->
  <ng-template
    DcDialog="previewDialog"
    maxheight="98vh"
    height="100%"
    width="98%"
    [hasbackdrop]="true"
    [mobileDialogDims]="['100%', '100%', '98vw', '98vh']"
  >
  <div class="h-full w-full overflow-hidden">
    <mat-card class="h-full max-h-full gap-2 overflow-hidden">
      <mat-card-header>
        <mat-card-title>{{ "preview-row-action" | translate }}</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <div class="h-available overflow-hidden text-lg">
        <mat-card-content class="min-h-[90vh]">
          <preview-document [pdfBase64]="documentData" />
        </mat-card-content>
      </div>
      <mat-divider></mat-divider>
        <mat-card-actions align="end">
          <button
            mat-raised-button
            color="primary"
            (click)="dialogService.close('previewDialog')"
          >
            {{ "close" | translate }}
          </button>
        </mat-card-actions>
    </mat-card>
  </div>
  </ng-template>



</ng-template>
