import { Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { UserModel } from 'src/app/Data/Entities/User/user.model';
import { GlobalsService } from 'src/app/Data/Services/globals.service';
import { LanguageService } from 'src/app/Data/Services/language.service';
import { UsersService } from 'src/app/Data/Services/users.service';
import { LoaderDirective } from 'src/app/Directives/loder.directive';
import { DocIconComponent } from 'src/app/modules/icon/icon.component';

import { HttpErrorResponse } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { catchError } from 'rxjs';
import { SnackbarService } from 'src/app/modules/Layouts/snackbar/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon'
import { MatDividerModule } from '@angular/material/divider';

const commons = [
  ReactiveFormsModule,
  CommonModule,
  NgOptimizedImage,
  FormsModule,
];
const matModules = [
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatSelectModule,
  MatExpansionModule,
  MatTabsModule,
  MatCardModule,
  FormsModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatIconModule,
  TranslateModule,
  MatCardModule,
  MatDividerModule
];
const standalones = [DocIconComponent, LoaderDirective];
const npmModules = [];

/**
 * @title Preference Dialog
 */

@Component({
  selector: 'user-preferences-dialog',
  templateUrl: 'user-preferences.component.html',
  styleUrls: ['user-preferences.component.scss'],
  standalone: true,
  imports: [...commons, ...matModules, ...standalones, ...npmModules],
})
export class UserPreferencesComponent implements OnInit {

  protected userForm: FormGroup;
  protected prefForm: FormGroup;
  protected currentUser: UserModel;
  private prevLang: string = "";

  protected submit: string = "preferences.user-edit.apply";
  protected mainLabel: string = "preferences.header";
  protected detailsLabel: string = "preferences.details";
  protected prefLabel: string = "preferences.user.preferences";
  protected userNameLabel: string = "pages.login.username";
  protected nameLabel: string = "preferences.user.fullname";
  protected titleLabel: string = "preferences.user.title";
  protected emailLabel: string = "preferences.user.email";
  protected phoneLabel: string = "preferences.user.phone";
  protected changePassword: string = "preferences.user-edit.changepassword";
  protected PreferredNotifyLabel: string = "preferences.user.notify";
  protected PreferredLangLabel: string = "preferences.user.lang";
  protected noNotificationLabel: string = "preferences.user.dont.notify";
  protected newPassLabel: string = "preferences.user-edit.newpassword";
  protected confPassLabel: string = "preferences.user-edit.confirmpassowrd";
  protected PreferredNotify: string;
  protected notifyEmail: boolean = false;
  protected notifySMS: boolean = false;
  protected notifyWhatsapp: boolean = false;
  protected noNotify: boolean = false;
  protected heLabel: string = "he";
  protected enLabel: string = "en";
  protected disableSubmitButton: boolean;

  passwordForm: FormGroup; 

  constructor(
    protected usersService: UsersService,
    protected translateService: TranslateService,
    protected languageService: LanguageService,
    protected snackbarService: SnackbarService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UserPreferencesComponent>){
      this.fb = new FormBuilder();
    }

  ngOnInit(): void {
    UsersService.currentUser$.subscribe((user) => {
      this.currentUser = user;
      this.userForm = new FormGroup({
        usernameControl: new FormControl({
          value: this.currentUser.username,
          disabled: true,
        }),
        nameControl: new FormControl(
          { value: this.currentUser.fullname, disabled: false },
          [Validators.required, Validators.minLength(3),  Validators.maxLength(50)]
        ),
        titleControl: new FormControl({
          value: this.currentUser.title,
          disabled: false,
        }, Validators.maxLength(60)),
        emailControl: new FormControl(
          { value: this.currentUser.email, disabled: false },
          Validators.email
        ),
        phoneControl: new FormControl(
          { value: this.currentUser.phonenumber, disabled: false },
          Validators.pattern('[- +()0-9]{10,12}')
        ),
        // rolesConrol : new FormControl({value: this.currentUser.roles, disabled: false}),
      });

      this.prefForm = new FormGroup({
        prefLangConrol: new FormControl({
          value: this.currentUser.preferences.language,
          disabled: false,
        }),
        prefNotifyConrol: new FormControl({
          value: this.userPrefredNotify(),
          disabled: false,
        }),
      });
    });

    this.passwordForm = new FormGroup({
      newPwd: new FormControl( '',[
      Validators.required,
      Validators.minLength(6),
      Validators.pattern('^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$')
      ]),
      
      confirmPwd: new FormControl('',[
      Validators.required,
      Validators.minLength(6),
      Validators.pattern('^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$')
    ])
    });

    // ==== Set Prev Language for reloading the current Value on edit submition =======
    this.languageService.currentLanguage$.subscribe((val) => {this.prevLang = val.value });

    this.disableSubmitButton = this.userForm.valid;
  }

  protected submitUserPreference(): void {
    let newPassword = this.passwordForm.controls['newPwd'].value ?? "";
    let user: UserModel = {
      id: UsersService.currentUser.id,
      roles: UsersService.currentUser.roles,
      hide: UsersService.currentUser.hide,
      enabled: UsersService.currentUser.enabled,
      email: this.userForm.controls['emailControl'].value,
      fullname: this.userForm.controls['nameControl'].value,
      title: this.userForm.controls['titleControl'].value,
      phonenumber: this.userForm.controls['phoneControl'].value,
      

      preferences: {
        language: this.prefForm.controls['prefLangConrol'].value,
        notifyEmail: this.notifyEmail,
        notifySMS: this.notifySMS,
        notifyWhatsapp: this.notifyWhatsapp,
      },
      tags: UsersService.currentUser.tags,
      username: UsersService.currentUser.username,
      hasPortrait: UsersService.currentUser.hasPortrait,
      displayedDetails: UsersService.currentUser.displayedDetails,
      displayUserInfo: UsersService.currentUser.displayUserInfo,
      displayUserInfoProps: UsersService.currentUser.displayUserInfoProps,
      friendlyName: UsersService.currentUser.friendlyName,
      friendlyNameWithStatus: UsersService.currentUser.friendlyNameWithStatus,
      subTitle: UsersService.currentUser.subTitle,
      valueToDisplay: UsersService.currentUser.valueToDisplay,
    };
    this.usersService
      .updateUser(user, newPassword)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          let message: string = ""
          console.log(err.error);
          if (err.message == 'email exists') {
            message = this.translateService.instant('preferences.user-edit.errors.email-exists')
          }
          else if (err.message == 'phone exists') {
            message = this.translateService.instant('preferences.user-edit.errors.phone-exists')
          } else {
            message = err.error
           }
           this.snackbarService.open({
            message: message,
            type: 'error',
          });
          throw err
        })
      )
      .subscribe((res) => {
        if (res) {
          UsersService.currentUser$.next(res);
          this.currentUser = new UserModel(res);
          let message =  this.translateService.instant('preferences.user-edit.updatesuccess')
          this.snackbarService.open({
            message: message,
            type: 'success',
          });

          if (res.preferences.language != this.prevLang){
            this.reloadCurrentPage() 
          }
        }
      });
  }

  closeDialog() {
    // Send data to the parent component
    this.dialogRef.close(); 
  }
  reloadCurrentPage() {
    window.location.reload();
  }

  protected userPrefredNotify(): string {
    if (this.currentUser.preferences.notifyEmail) {
      this.notifyEmail = true;
      return 'notifyEmail';
    } else if (this.currentUser.preferences.notifySMS) {
      this.notifySMS = true;
      return 'notifySMS';
    } else if (this.currentUser.preferences.notifyWhatsapp) {
      this.notifyWhatsapp = true;
      return 'notifyWhatsapp';
    } else {
      this.noNotify = true;
      return 'non';
    }

  }

   get disableSubmit(){
    if (this.passwordForm.touched) return this.userForm.valid && this.passwordForm.valid;
    return this.userForm.valid;
  }

   get newPwd(){
    return this.passwordForm.get('newPwd');
  }

   get confirmPwd(){
    return this.passwordForm.get('confirmPwd');
  }

  protected get dir(): string {
    return GlobalsService.dir;
  }

  protected get float(): string {
    return GlobalsService.dir;
  }
}
