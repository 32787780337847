<div *ngIf="currentUser" class="main">
  <h1 class="header">
    {{ 'preferences.header' | translate }}
  </h1>

  <mat-tab-group dynamicHeight>
    <mat-tab-nav-panel>
      <mat-tab label="{{  mainLabel | translate }} ">
        <form [formGroup]="userForm" [dir]="dir === 'ltr' ? 'ltr' : 'rtl'">
          <div class="userForm">
            <mat-form-field class="userField">
              <mat-label>{{ userNameLabel | translate }} </mat-label>
              <input matInput type="text" formControlName="usernameControl" />
            </mat-form-field>

            <mat-form-field class="userField">
              <mat-label>{{ nameLabel | translate }} </mat-label>
              <input matInput type="text" formControlName="nameControl" />
            </mat-form-field>

            <mat-form-field class="userField">
              <mat-label>{{ titleLabel | translate }} </mat-label>
              <input matInput type="text" formControlName="titleControl" />
            </mat-form-field>

            <mat-form-field class="userField">
              <mat-label>{{ emailLabel | translate }} </mat-label>
              <input matInput type="text" formControlName="emailControl" />
            </mat-form-field>

            <mat-form-field class="userField">
              <mat-label>{{ phoneLabel | translate }} </mat-label>
              <input matInput type="text" formControlName="phoneControl" />
            </mat-form-field>
          </div>
        </form>

        <!-- password change  -->
        <mat-divider></mat-divider>
        <mat-label class="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
          {{ 'preferences.user-edit.changepassword' | translate }}
        </mat-label>
        <div class="userForm">
          <form [formGroup]="passwordForm" [dir]="dir === 'ltr' ? 'ltr' : 'rtl'">
            <mat-form-field class="userField">
              <mat-label>
                {{ newPassLabel | translate }}
              </mat-label>
              <input matInput formControlName="newPwd" id="newPwd" type="password" />
              <!-- <mat-error *ngIf="confirmPwd?.touched && newPwd?.touched">{{"preferences.user-edit.reqNewPass"
                | translate }} </mat-error> -->
                <mat-error *ngIf="newPwd.errors?.['pattern'] || newPwd.errors?.['minlength']"> {{"preferences.user-edit.passNotValid" | translate}} </mat-error>
            </mat-form-field>

            <mat-form-field class="userField">
              <mat-label>
                {{ confPassLabel | translate }}
              </mat-label>
              <input matInput formControlName="confirmPwd" id="confirmPwd" type="password" />
              <mat-error class="ml-5" *ngIf="confirmPwd?.value != newPwd?.value && newPwd?.touched">
                {{"preferences.user-edit.passNoMatch" | translate}} </mat-error>
            </mat-form-field>

          </form>
        </div>
      </mat-tab>

      <mat-tab label="{{ prefLabel | translate }}">
        <form [formGroup]="prefForm" [dir]="dir === 'ltr' ? 'ltr' : 'rtl'">
          <div class="userForm">
            <mat-form-field class="userField">
              <mat-label>{{ PreferredLangLabel | translate}}</mat-label>
              <mat-select formControlName="prefLangConrol">
                <mat-option value="he"> {{ heLabel | translate }} </mat-option>
                <mat-option value="en"> {{ enLabel | translate }} </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="userField">
              <mat-label>{{ PreferredNotifyLabel | translate }} </mat-label>
              <mat-select formControlName="prefNotifyConrol">
                <mat-option value="notifySMS"> SMS </mat-option>
                <mat-option value="notifyEmail"> Email </mat-option>
                <mat-option value="notifyWhatsapp"> Whatsapp </mat-option>
                <mat-option value="non"> {{ noNotificationLabel | translate }} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </mat-tab>
    </mat-tab-nav-panel>
  </mat-tab-group>
  <!-- ----------------------------------------------------------------------- -->
  <!--                              Action button                              -->
  <!-- ----------------------------------------------------------------------- -->
  <mat-divider> </mat-divider>

  <mat-card-actions [style.float]="dir === 'ltr' ? 'right' : 'left'" class="flex gap-5 pl-1 pr-1 pt-3">
    <button mat-stroked-button color="primary" (click)="closeDialog()" matDialogClose="">
      {{ "close" | translate }}
    </button>
    <button mat-raised-button [disabled]="!disableSubmit"color="primary" type="submit" (click)="submitUserPreference()">
      {{ submit | translate }}
    </button>
  </mat-card-actions>

  <mat-divider> </mat-divider>
</div>