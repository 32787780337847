
<div class="flex justify-between items-end">
  <div class="flex flex-row-reverse justify-end items-end" style="margin-inline-start: 1rem;">
    <div *ngIf="extraCount > 0" class="text-2xl">
      <h1>...</h1>
    </div>
    <div
      *ngFor="let user of displayedRecipients; let i = index"
      [ngClass]="getCircleClasses(user.userStatus, i)"
      [matTooltip]="user.friendlyName"
      matTooltipClass="new-lines"
      matTooltipPosition="below"
    >
      <div *ngIf="isEmail(user.friendlyName)">
        <i class="fa-regular fa-at text-xl"></i>
      </div>
      <div *ngIf="isPhone(user.friendlyName)">
        <i class="fa-regular fa-comment-sms text-2xl text-center "></i>
      </div>
      <div *ngIf="isWhatsApp(user.friendlyName)">
        <i class="fa-brands fa-whatsapp text-2xl text-center"></i>
    </div>
      <div *ngIf="!isEmail(user.friendlyName) && !isPhone(user.friendlyName) && !isWhatsApp(user.friendlyName)">
        <span>{{ initials(user.friendlyName) }}</span>
      </div>
    </div>

  </div>
</div>


