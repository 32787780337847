import {AuthService} from 'src/app/Data/Services/auth.service';
import {PermissionType} from 'src/app/Data/Entities/User/user-permission.type';
import {DcSidenavItemStruct, IDcSidenavItemStruct} from '../sidenav-item-struct.model';

export type SideNavCategory = 'general' | 'documents' | 'templates' | 'more';

/**
 * SideNav categories:
 * Note: The first item in each category will be the item that represents the category.
 */
const sideNavCategories: Map<SideNavCategory, IDcSidenavItemStruct[]> = new Map<SideNavCategory, IDcSidenavItemStruct[]>([
  [
    'general',
		[
			{
				id: 'documents',
				index: 1,
				label: 'documents',
				routerLink: ['reports'],
				icon: {
				  name: 'file',
				  color: 'primary',
				  type: 'regular',
				  size: 'lg',
				},
				mobile: true,
				permissions: [PermissionType.documents_manage],
				
			},
			{
				id: 'company-settings',
				index: 2,
				label: 'struct.topbar.menuitems.settings',
				routerLink: ['company-settings'],
				icon: {
				  name: 'tools',
				  color: 'primary',
				  type: 'regular',
				  size: 'lg',
				},
				mobile: true,
				permissions: [PermissionType.settings_read],
				
			}

		],
	],

]);

export function getSideNavItems(
	category: SideNavCategory[],
	userPermissions: PermissionType[] = AuthService.currentUserPermissions,
): DcSidenavItemStruct[] {
	if(!userPermissions) throw new Error('User permissions are not defined');
	return category.reduce((acc, cat) => {
		let items = sideNavCategories.get(cat)?.map(item => new DcSidenavItemStruct(item));
		if (items) {
			let categoryItem = items.find(item => item.id === cat);
			if (categoryItem) {
				categoryItem.subItems = items.filter(item => item.id !== cat);
				categoryItem.type = 'category';
			}
			acc.push(...items.filter(item => item.permissions.some(p => userPermissions?.includes(p))));
		}
		return acc;
	}, []);
}
