import { TableConst } from "../constants/table-constants.data";

export function computeRowPerPage(rowHeight: number = TableConst.DEFAULT_ROW_HEIGHT) {
	let mainComponentHeight = document.querySelector('body')?.clientHeight;
		mainComponentHeight = mainComponentHeight * 0.9;
	const titleHeight = 50;
	const actionHeight = 56;
	const tableHeaderHeight = 56;
	const paginationHeight = 56;
	const availableRowsSpace = mainComponentHeight - titleHeight - actionHeight - tableHeaderHeight - paginationHeight;
	return Math.floor(availableRowsSpace / rowHeight);
}
