import { ApplicationRef, enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { bootstrapApplication, enableDebugTools } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { tokenInterceptor } from './app/Data/Services/token.interceptor';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutes } from './app/app-routing.module';
import { StorageService } from './app/Data/Services/storage.service';
import { AuthService } from './app/Data/Services/auth.service';
import { LanguageService } from './app/Data/Services/language.service';
import { UsersService } from './app/Data/Services/users.service';
import { DatePipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppComponent } from './app/app.component';
import { AuthGuardService } from './app/Data/Services/auth-guard.service';
import { DeviceService } from './app/Data/Services/device/device-service.service';
import { DialogControlService } from './app/modules/dialog/dialog-control.service';
import { CellTemplateCollectorService } from './app/modules/table/directives/cell-template-collector.directive';
import { HttpLoaderFactory } from './app/Data/Services/language.data/translate-http-loader-factory';

if (environment.production) {
  enableProdMode();
}

window.onload = function() {
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        HttpClientModule,
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
          },
        }),
        ...externalModules,
      ),
      provideRouter(AppRoutes, withComponentInputBinding()),
      tokenInterceptor,
      provideAnimations(),
      provideHttpClient(withInterceptorsFromDi()),
      ...inAppServices,
    ]
  })
  .then(module => {
    enableDebugTools(module.injector.get(ApplicationRef).components[0]);

  })
  .catch(err => console.log(err));
}



const externalModules = [
	MatDialogModule,
	MatSnackBarModule,
	DatePipe,
];

const inAppServices = [
  TranslateService,
	StorageService,
  AuthService,
	LanguageService,
  UsersService,
  LanguageService,
  DatePipe,
  DeviceService,
  DialogControlService,
  CellTemplateCollectorService,
  AuthGuardService,
];

