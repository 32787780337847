<div
	[dir]="languageService.CurrentDir"
	class="w-80 select-none rounded-2xl bg-white p-4 shadow-lg dark:bg-gray-800"
	[dcLoader]="logoutProcess"
>
	<div class="flex flex-row items-start gap-4">

    <div class="w-[7.3rem] h-20  text-center grid items-center border-solid border-2 border-gray-400  rounded-full  ">
      <h1>{{initials(UsersService.currentUser?.fullname)}}</h1>
    </div>

		<div class="flex h-20 w-full flex-col justify-between text-center">
			<!-- User full name and job title -->
			<div>
				<!-- User full name -->
				<p class="text-xl font-medium text-gray-800 dark:text-white">
					{{ UsersService.currentUser?.fullname }}
				</p>
				<!-- User job title -->
				<p class="text-xs text-gray-400">{{ UsersService.currentUser?.title }}</p>
			</div>

      <!-- Preferred language -->
			<div class="flex w-full justify-end rounded-lg bg-blue-100 p-1 dark:bg-white">
				<docLanguage-toggle />
			</div>
		</div>
	</div>
	<div class="mt-3 flex items-center justify-between gap-4">
		<!-- User preferences -->
	    <button
			mat-stroked-button
			class="w-2/3 rounded-lg border bg-accent-100 px-4 py-2 text-base"
			(click)="openUserPreferencesDialog()"
		>
			<div class="flex flex-row items-center justify-evenly gap-1">
				<DocIcon
					name="regular-gear-circle-user"
					type="fak"
					size="lg"
					color="primary"
				/>
				<span>
					{{ 'struct.user-edit.user_prefrences_cycles' | translate }}
				</span>
			</div>
		</button>
		<!-- Log out -->
		<button
			mat-stroked-button
			class="w-1/3 rounded-lg border bg-white-100 px-4 py-2 text-base text-gray-500 hover:bg-white-500 hover:text-error-500"
			(click)="logOut()"
		>
			<!-- loader -->
			<div class="flex flex-row items-center justify-evenly gap-1 whitespace-nowrap">
				<DocIcon
					name="right-from-bracket"
					type="duotone"
					size="lg"
				/>
				<span>
					{{ 'struct.topbar.mobilemenu.logout' | translate }}
				</span>
			</div>
		</button>
	</div>
</div>

<ng-template
  DcDialog="userPreferencesDialog"
  height="80%"
  maxheight="80vh"
  width="80%"
  [mobileDialogDims]="['100%', '90%', '98vw', '78vh']"
  [hasbackdrop]="true"
>
 <user-preferences-dialog />
</ng-template>

