import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SnackbarTypes } from 'src/app/modules/Layouts/snackbar/data/snackbar-type.type';
import { IColumnStruct, ColumnStruct } from 'src/app/modules/table/data/config/column.config';
import { PagingConfigStruct } from 'src/app/modules/table/data/config/paging.config';
import { TableRowClickEvent } from 'src/app/modules/table/data/types/table-event.type';
import { TableConfigStruct, TableConfig } from 'src/app/modules/table/data/types/table.data';
import { IMetaTagKeySchema, IMetaTagTableStruct } from './data/metatag-table-struct.type';
import { MetaTagTableData } from './data/metatag-table.data';
import { MetaTagsService } from './service/meta-tags.service';
import { DialogControlService } from 'src/app/modules/dialog/dialog-control.service';
import { SnackbarService } from 'src/app/modules/Layouts/snackbar/snackbar.service';
import { TableComponent } from 'src/app/modules/table/table/table.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { DialogDirective } from 'src/app/modules/dialog/dialog.directive';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'meta-tag',
  standalone: true,
  imports: [CommonModule, TableComponent, TranslateModule, MatCardModule, MatFormFieldModule, MatButtonModule, MatInputModule, FormsModule, ReactiveFormsModule, MatDividerModule, DialogDirective],
  templateUrl: './meta-tag.component.html',
  styleUrls: ['./meta-tag.component.scss']
})
export class MetaTagComponent {
	public createTagFG: FormGroup;
	public editTagFG: FormGroup;

	/* -------------------------------------------------------------------------- */
	/*                     Metaag Table Configurations and data                    */
	/* -------------------------------------------------------------------------- */
	public metaTagTableData$: BehaviorSubject<Array<IMetaTagTableStruct>> = new BehaviorSubject(undefined);

	protected dcMetaTagTableConfig: TableConfigStruct<IMetaTagTableStruct> | TableConfig<IMetaTagTableStruct> = {
    tableTitle: 'Tags',
    tableItemName: {
      plural: 'רשומות',
      singular: 'רשומה אחת',
    },
    enableSearch: true,
    enableCheckboxes: false,
    enableGlobalTableMenu: false,
    allowStoreTable: false,
    enableTableColumnsShowHide: false,
    tableActions: [],
    rowActions: [],
    enableSort: true,
  };

	protected dcMetaTagColumns: IColumnStruct<IMetaTagTableStruct>[] | ColumnStruct<IMetaTagTableStruct>[];

	metaTagTablePaginationConfig: PagingConfigStruct<IMetaTagTableStruct> = {
		pageSize: 7,
	};

	/* ------------------------------- Constructor ------------------------------ */
	constructor(protected metaTagService: MetaTagsService, public dcDialogControl: DialogControlService, private snackbarService: SnackbarService) {
		const metaTagTableData = MetaTagTableData(this);
		this.dcMetaTagColumns = metaTagTableData.columns;
		this.dcMetaTagTableConfig.tableActions = metaTagTableData.globalActions;
		this.dcMetaTagTableConfig.rowActions = metaTagTableData.rowActions;
	}

	/* ----------------------------- LifeCycle Hooks ---------------------------- */
	ngOnInit(): void {
		this.metaTagService.getMetaTagKeys().subscribe(data => {
      if(!data) return;
			const availableMetaTags = [];
			data.forEach(m => {
				const metaTag: IMetaTagTableStruct = {key: m.key, value: m.name};
				availableMetaTags.push(metaTag);
			});
			this.metaTagTableData$.next(availableMetaTags);
		});
		this.createTagFG = new FormGroup({
			key: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9]+$/), Validators.required]),
			name: new FormControl('', Validators.required),
		});
		this.editTagFG = new FormGroup({
			key: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9]+$/), Validators.required]),
			name: new FormControl('', Validators.required),
		});
		this.editTagFG.controls.key.disable();
	}

	/* -------------------------------------------------------------------------- */
	/*                                   Methods                                  */
	/* -------------------------------------------------------------------------- */
	protected onRowClick(row: TableRowClickEvent<IMetaTagTableStruct>) {}

	protected onRowDoubleClick(row: TableRowClickEvent<IMetaTagTableStruct>) {
		this.editMetaTag(row.id);
	}

	public deleteMetaTag(metaTagKey: string) {
		this.metaTagService.deleteMetaTag(metaTagKey).subscribe(res => {
			if (res) {
				this.snackbarService.open({message: 'delete-tag-success', type: SnackbarTypes.Success});
				this.metaTagTableData$.next(this.metaTagTableData$.value.filter(m => m.key !== metaTagKey));
			} else {
				this.snackbarService.open({message: 'delete-tag-error', type: SnackbarTypes.Error});
			}
		});
	}

	public editMetaTag(metaTagKey) {
		const data = this.metaTagTableData$.value.find(mt => mt.key === metaTagKey);
		this.editTagFG.controls.key.setValue(data.key);
		this.editTagFG.controls.name.setValue(data.value);
		this.dcDialogControl
			.open('editTagRef')
			.afterClosed()
			.subscribe((res: {closeData: {edit: boolean; name: string}}) => {
				if (res?.closeData.edit) {
					const request: IMetaTagKeySchema = {key: metaTagKey, name: res.closeData.name};
					this.metaTagService.updateMetaTag(request).subscribe(res => {
						if (res) {
							this.snackbarService.open({message: 'edit-tag-success', type: SnackbarTypes.Success});
							const data = this.metaTagTableData$.value.find(mt => mt.key === metaTagKey);
							if (data) {
								data.value = request.name;
								this.metaTagTableData$.next(this.metaTagTableData$.value);
							}
						} else {
							this.snackbarService.open({message: 'edit-tag-error', type: SnackbarTypes.Error});
						}
					});
				}
			});
	}

	public createMetaTag() {
		this.dcDialogControl
			.open('createTagRef')
			.afterClosed()
			.subscribe((createRes: {closeData: {create: boolean; key: string; name: string}}) => {
				if (createRes?.closeData.create) {
					const request: IMetaTagKeySchema = {
						key: createRes.closeData.key,
						name: createRes.closeData.name,
					};
					this.metaTagService.getMetaTagKeys(true, true).subscribe(data => {
						const allMetaTags = [];
						data.forEach(m => {
							const metaTag: IMetaTagTableStruct = {key: m.key, value: m.name};
							allMetaTags.push(metaTag);
						});
						const metaTag = allMetaTags.find(mt => mt.key === createRes.closeData.key);
						if (metaTag) {
								// restore - tag with this key already existed and got removed
								this.dcDialogControl
									.open('restoreTagRef')
									.afterClosed()
									.subscribe((restoreRes: {closeData: {restore: boolean}}) => {
										if (restoreRes?.closeData.restore) {
											const request: IMetaTagKeySchema = {
												key: metaTag.key,
												name: createRes.closeData.name,
											};
											this.metaTagService.updateMetaTag(request).subscribe(res => {
												if (res) {
													// restore tag, by setting to enabled and updating value
													this.snackbarService.open({
														message: 'create-new-tag-success',
														type: SnackbarTypes.Success,
													});
													const mt: IMetaTagTableStruct = {
														key: request.key,
														value: request.name,
													};
													const updatedMetaTags: IMetaTagTableStruct[] = [...this.metaTagTableData$.value, mt];
													this.metaTagTableData$.next(updatedMetaTags);
												} else {
													this.snackbarService.open({
														message: 'create-new-tag-error',
														type: SnackbarTypes.Error,
													});
												}
											});
										}
									});
							
						} else {
							// add new tag
							this.metaTagService.createMetaTag(request).subscribe(res => {
								if (res) {
									this.snackbarService.open({message: 'create-new-tag-success', type: SnackbarTypes.Success});
									const mt: IMetaTagTableStruct = {
										key: createRes.closeData.key,
										value: createRes.closeData.name,
									};
									const updatedMetaTags: IMetaTagTableStruct[] = [...this.metaTagTableData$.value, mt];
									this.metaTagTableData$.next(updatedMetaTags);
								} else {
									this.snackbarService.open({message: 'create-new-tag-error', type: SnackbarTypes.Error});
								}
							});
						}
					});
				}
			});
		this.createTagFG.reset();
	}
}
