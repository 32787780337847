import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import {
  DocumentModel
} from 'src/app/Data/Entities/Documents/document.model';
import { UserModel } from 'src/app/Data/Entities/User/user.model';
import { TableComponent } from '../../table/table/table.component';
import { ReportsTableStruct } from './reports-table-struct';


export class ReportsDataModel {
  users: UserModel[];
  documents: DocumentModel[];
  protected reportsTableData$ = new BehaviorSubject<ReportsTableStruct[]>(null);
  private documentsDataStore = new Map<string, ReportsTableStruct>();

  constructor(
    users: UserModel[],
    documents: DocumentModel[],
    public translate: TranslateService
  ) {
    this.users = users;
    this.documents = documents;
  }

  setRowTableDataModel(docs: DocumentModel[], users: UserModel[]): ReportsTableStruct[] {
    const reportsTableData: ReportsTableStruct[] = [];
  
    docs.forEach((doc) => {
      const rowTableData: ReportsTableStruct = {
        id: doc?.id,
        fileName: doc?.name,
        docStatus: doc?.status,
        userStatus: null,
        recipientCellData: [],
        LastUpdate: doc.log[doc.log.length - 1].timestamp.toString(),
        CreateDate: '',
        // Initialize metadata array here
        metadatas: [],
      };
  
      // Loop through metadata and add to the metadata array
      doc.metadatas?.forEach((data) => {
        rowTableData.metadatas.push(data); // Add metadata to the array
      });
  
      const translation = {
        tran: () =>
          this.translate.instant(doc?.status.toString(), {
            value: doc?.status.toString(),
          }),
      };
  
      doc.recipients.forEach((r, index) => {
        rowTableData.recipientCellData[index] = {
          friendlyName: r.user.friendlyName,
          userStatus: r.status,
        };
      });
  
      doc.log.forEach((log) => {
        if (log.action === 'DocumentSent') {
          rowTableData.CreateDate = log.timestamp.toString();
        }
      });
  
      users.forEach((usr) => {
        if (!usr.id) {
          throw new Error('No usr id');
        }
        const filteredRecipients = doc.recipients.filter((recipient) => usr.id === recipient.user.id);
  
        filteredRecipients.forEach((du) => {
          rowTableData.userStatus = du.status;
        });
      });
  
      this.documentsDataStore.set(rowTableData.id, rowTableData);
      reportsTableData.push(rowTableData);
    });
    TableComponent.setLoader(false);
    return reportsTableData;
  }
}
