export const PermissionType = {
	//permanent user
	permanent: 'permanent',

	//documents
	documents_manage: 'documents.manage',
	documents_create: 'documents.create',
	documents_sign: 'documents.receive',

	//settings
	settings_read: 'settings.read',
	settings_update: 'settings.update',

	//admin
	admin: 'administrator',

	//users
	users_manage: 'users.manage',

	//developer
	developer: 'Developer', // TODO: need to add this to the backend
} as const;

export type PermissionType = (typeof PermissionType)[keyof typeof PermissionType];
