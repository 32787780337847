import { KeyboardKey } from "src/app/tools/keyboard-keys.type";

export const KeyboardShortcutType = {
	DeselectActiveRow: 'deselectActiveRow',
	CloseOpenedMenu: 'closeOpenedMenu',
} as const;

export type KeyboardShortcutType = (typeof KeyboardShortcutType)[keyof typeof KeyboardShortcutType];

export function getDefaultTableShortcutsAndKeys(): Map<KeyboardShortcutType, KeyboardKey> {
	return new Map<KeyboardShortcutType, KeyboardKey>([
		[KeyboardShortcutType.DeselectActiveRow, KeyboardKey.Escape],
		[KeyboardShortcutType.CloseOpenedMenu, KeyboardKey.Escape],
	]);
}
