import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenu, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorType, getColor } from 'src/styles/theme.data';
import { TableRowClickEvent } from '../../../data/types/table-event.type';
import { TableActionStruct, TableEntryStruct } from '../../../data/types/table.data';
import { TableSharedModule } from '../../../table.module';


const matModules = [
	MatMenuModule,
	MatTooltipModule,
	MatIconModule,
	MatDividerModule,
	MatFormFieldModule,
	MatIconModule,
	MatDatepickerModule,
	MatOptionModule,
	MatSelectModule,
	MatInputModule,
	MatCheckboxModule,
	MatSlideToggleModule,
];

@Component({
	selector: 'table-row-menu',
	standalone: true,
	imports: [TableSharedModule, ...matModules],
	templateUrl: './table-row-menu.component.html',
	styleUrls: ['./table-row-menu.component.scss'],
})
export class TablleRowMenuComponent<T extends TableEntryStruct> implements OnInit {
	@ViewChild('rowMenu') public matMenu: MatMenu;

	/* ------------------------------- Inputs ------------------------------ */
	@Input() menuTrigger: MatMenuTrigger;
	@Input() actions: TableActionStruct[];
	@Input() activeRow: TableRowClickEvent<T>;

	/* --------------------------------- Outputs -------------------------------- */
	get activeRowEvent(): TableRowClickEvent<T> {
		const e = this.activeRow;
		e.data['id'] = e.id;
		return e;
	}

	/* ---------------------------------- State --------------------------------- */

	/* ------------------------------- Shared Data ------------------------------ */

	/* ------------------------------- Constructor ------------------------------ */
	constructor() {}

	/* ----------------------------- Lifecycle Hooks ---------------------------- */
	ngOnInit(): void {}

	/* -------------------------------------------------------------------------- */
	/*                                   Methods                                  */
	/* -------------------------------------------------------------------------- */
	protected dcColor: (color: ColorType) => string = (color: ColorType) => getColor(color);
}
