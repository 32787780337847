import { Injectable, TemplateRef, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogConfig, DialogDirective, DialogEventType } from './dialog.directive';
import { Observable, Subscription, Subject, of } from 'rxjs';
import { getColorClass } from 'src/styles/doc-theme.data';

export interface DialogRefStructure {
	dialogDirective: DialogDirective;
	contextElement: TemplateRef<any>;
	dialogRef?: MatDialogRef<any>;
	config?: DialogConfig;
	subscriptions?: Subscription[];

	info: {
		isOpen: boolean;
	};
}

@Injectable({
	providedIn: 'root',
})
export class DialogControlService {
	private static instance: DialogControlService;
	private dialogReferences: Map<string, DialogRefStructure> = new Map();
  
	beforeClosed: Observable<any | undefined>;
	afterOpened: Observable<void>;
	afterClosed: Observable<any | undefined>;
	keydownEvents: Observable<KeyboardEvent>;
	backdropClick: Observable<MouseEvent>;
  
	//custom cw-events
	onOpened: EventEmitter<void> = new EventEmitter<void>();
  
	constructor(private matDialog: MatDialog) {
	  if (DialogControlService.instance) {
		return DialogControlService.instance;
	  }
	  DialogControlService.instance = this;
	}
  
	registerDialog(dialogDirective: DialogDirective, id: string, ref: TemplateRef<any>, conf?: DialogConfig): DialogRefStructure {
	  this.dialogReferences.set(id, {
		dialogDirective: dialogDirective,
		contextElement: ref,
		config: conf,
		info: {isOpen: false}
	  });
	  return this.dialogReferences.get(id);
	}
  
	unregisterDialog(id: string): void {
	  if (this.dialogReferences.has(id)) {
		this.dialogReferences.delete(id);
	  }
	}
  
	getDialogData(id: string): any {
	  return this.dialogReferences.get(id)?.config.data;
	}
  
	getDialogRef(id: string): MatDialogRef<any> {
	  return this.dialogReferences.get(id)?.dialogRef;
	}
  
	dialogEvents: Subject<[DialogEventType, any]> = new Subject<[DialogEventType, any]>();
	subscriptions: Subscription[] = [];
  
	open(id: string, conf?: DialogConfig | Record<string, any>): MatDialogRef<any> {
	  if (this.dialogReferences.has(id)) {
		const dialogElement = this.dialogReferences.get(id);
		if (conf && conf.hasOwnProperty('data')) {
		  dialogElement.config.data = conf.data;
		}
		//check if the dialog is already open
		if (!dialogElement.info.isOpen) {
		  dialogElement.dialogRef = this.matDialog.open(dialogElement.contextElement, dialogElement.config ? dialogElement.config : conf);
		  dialogElement.info.isOpen = true;
		  if (conf instanceof DialogConfig) {
			if (conf?.events?.length > 0) {
			  let subscriptions: Subscription[] = [];
			  conf.events.forEach(event => {
				this.subscriptions.push(dialogElement.dialogRef[event]().subscribe(event => this.dialogEvents.next(event)));
			  });
			  dialogElement['subscriptions'] = subscriptions;
			}
		  }
		  dialogElement.dialogRef.afterOpened().subscribe(() => {
			dialogElement.dialogDirective?.onOpened.emit();
		  });
		  dialogElement.dialogRef.afterClosed().subscribe(() => {
			dialogElement.info.isOpen = false;
			dialogElement.subscriptions?.forEach(sub => sub.unsubscribe());
		  });
  
		  (document.querySelector('.mdc-dialog__container') as HTMLElement).classList.add(
			'w-full',
			'border-2',
			getColorClass(dialogElement.config.borderColor, 'border'),
			'rounded-lg',
			'shadow-lg',
		  );
		}
		return dialogElement.dialogRef;
	  } else {
		throw new Error('DcDialog id does not exists: ' + id);
	  }
	}
  
	close(id: string, closeParams?: any): void {
	  if (this.dialogReferences.has(id)) {
		const dialogElement = this.dialogReferences.get(id);
		const closeData: DialogData = {openData: dialogElement?.config.data, closeData: closeParams};
		dialogElement.dialogRef.close(closeData);
		dialogElement.subscriptions?.forEach(sub => sub.unsubscribe());
		dialogElement.info.isOpen = false;
	  }
	}
  
	dialogOpened(id: string): boolean {
	  if (this.dialogReferences.has(id)) {
		return this.dialogReferences.get(id).info.isOpen;
	  }
	  return false;
	}
  
	getEvent<T>(id: string, event: DialogEventType): Observable<T> {
	  if (this.dialogReferences.has(id)) {
		return this.dialogReferences.get(id).dialogRef[event]() as Observable<T>;
	  } else {
		throw new Error('DcDialog id does not exists: ' + id);
	  }
	}
  
	closeAllDialogs(): void {
	  this.dialogReferences?.forEach(dialogElement => {
		dialogElement.info.isOpen = false;
		dialogElement.subscriptions?.forEach(sub => sub.unsubscribe());
		dialogElement.dialogRef.close();
	  });
	}
  
	hasDialog(id: string): boolean {
	  return this.dialogReferences.has(id) && this.dialogReferences.get(id).info.isOpen;
	}
  }

export type DialogData = {
	openData?: any;
	closeData?: any;
};
