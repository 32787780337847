import { PermissionType } from "./user-permission.type";


export class JwtToken {
  nameid: string;
  unique_name: string;
  fullname: string;
  permission: PermissionType | PermissionType[];
  permanent: string | string[];
  role: string[];
  language: string;

  constructor(jwtToken: string) {
    const decodedToken = JSON.parse(window.atob(jwtToken.split('.')[1]));
    this.nameid = decodedToken.nameid;
    this.unique_name = decodedToken.unique_name;
    this.fullname = decodedToken.fullname;
    this.permission = decodedToken.permission;
    this.permanent = decodedToken.permanent;
    this.role = decodedToken.role;
    this.language = decodedToken.language;
  }
}
