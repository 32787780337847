import { TableObjectKey } from '../types/table.types';
import { TableComponent } from '../../table/table.component';
import { TableEntryStruct } from '../types/table.types';
import { TableActionStruct } from './table-action-struct.interface';
import { IColumnSortStruct } from './column-sort-struct.type';

export class TableConfig<T extends TableEntryStruct> {
	enableCheckboxes?: boolean = true;
	enableSearch?: boolean = true;
	enableDateRangeFiltering?: boolean = false;
	enableGlobalTableMenu?: boolean = true;
	enableFixedLayout?: boolean = true;
	enableTablePresets?: boolean = true;
	enableTableColumnsShowHide?: boolean = true;
	allowStoreTable?: boolean = true;
	/* -------------------------------------------------------------------------- */
	tableTitle?: string;
	tableItemName?: {
		singular: string;
		plural: string;
	};
	/* -------------------------------------------------------------------------- */
	/** Action for double click row's event: */
	primaryAction?: TableActionStruct;
	tableActions?: TableActionStruct[];
	rowActions?: TableActionStruct[];
	/* -------------------------------------------------------------------------- */
	enableSort?: boolean = false;
	defaultSort?: IColumnSortStruct<T>;
	sortKey?: TableObjectKey = undefined;
	/* -------------------------------------------------------------------------- */
	/*                                 State Data                                 */
	/* -------------------------------------------------------------------------- */
	/* --------------------------------- Search --------------------------------- */
	searchLabel?: string = 'struct.dc-table.search.label';

	constructor(init?: TableConfigStruct<T>, tableComponent?: TableComponent<T>) {
		Object.assign(this, init);
		this.tableActions = this.tableActions?.map(action => {
			return {
				...action,
				data: { tableComponent, ...action?.data },
			};
		});
	}
}

export type TableConfigStruct<T extends TableEntryStruct> = { [key in keyof TableConfig<T>]: any };
