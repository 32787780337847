import {SortDirection} from "@angular/material/sort";
import { IStoredTableColumn } from "./user-table-store.schema";

export class StoredTableColumn implements IStoredTableColumn {
  columnSearch?: string;
  index?: number;
  visible?: boolean;
  width?: number;
  sort?: SortDirection;

  // Custom column id
  columnKey: string;

  constructor(columnKey: string, init: Partial<IStoredTableColumn>) {
    this.columnKey = columnKey;
    Object.assign(this, init);
  }

  toJSON(): IStoredTableColumn {
    return {
      columnSearch: this.columnSearch,
      index: Boolean(this.index) && !isNaN(this.index) ? this.index : -1,
      visible: this.visible,
      width: Boolean(this.width) && !isNaN(this.width) ? this.width : -1,
      sort: this.sort,
    };
  }
}
