import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DialogControlService } from 'src/app/modules/dialog/dialog-control.service';
import { DialogDirective } from 'src/app/modules/dialog/dialog.directive';
import { ExportToType } from 'src/app/tools/exporter.tool';
import { ColorType, getColor } from 'src/styles/theme.data';
import { ColumnStruct } from '../../../data/config/column.config';
import { TableActionStruct } from '../../../data/config/table-action-struct.interface';
import { TableEntryStruct } from '../../../data/types/table.types';
import { TableConnector } from '../../../state.controllers/table-connector';
import { TableSharedModule } from '../../../table.module';
import { TableObjectKey } from '../../../data/types/table.types';
import { TableExportMode } from './../../../data/types/table-export.type';

const matModules = [
	MatMenuModule,
	MatTooltipModule,
	MatIconModule,
	MatDividerModule,
	MatFormFieldModule,
	MatIconModule,
	MatDatepickerModule,
	MatOptionModule,
	MatSelectModule,
	MatInputModule,
	MatCheckboxModule,
	MatSlideToggleModule,
	MatCardModule,
	MatSelectModule,
	MatButtonModule,
];

@Component({
	selector: 'global-table-menu',
	standalone: true,
	imports: [TableSharedModule, ...matModules, DialogDirective],
	templateUrl: './global-table-menu.component.html',
	styleUrls: ['./global-table-menu.component.scss'],
})
export class GlobalTableMenuComponent<T extends TableEntryStruct> implements OnInit {
	protected exportToType = ExportToType;
	protected tableExportMode = TableExportMode;
	/* -------------------------------------------------------------------------- */
	@ViewChild('globalMenu') public matMenu: MatMenu;
	/* ------------------------------- Inputs ------------------------------ */
	@Input() dataColumns: ColumnStruct<T>[];
	@Input() tableTitle: string;
	/* ------------------------------ Option Inputs ----------------------------- */
	@Input() customTableActions: TableActionStruct[] = [];
	@Input() showHideChecboxesAction: boolean = true;

	/* --------------------------------- Outputs -------------------------------- */
	@Output() exportTo: EventEmitter<{
		mode: TableExportMode;
		type: ExportToType;
		columns: { objecyKey: TableObjectKey; title: string; visible: boolean }[];
		fileName: string;
	}> = new EventEmitter();

	@Output() resetChecked: EventEmitter<'all' | 'page'> = new EventEmitter();
	/* ---------------------------------- State --------------------------------- */

	/* ------------------------------- Constructor ------------------------------ */
	constructor(protected dialogControl: DialogControlService, private translate:TranslateService) {}

	/* ----------------------------- Lifecycle Hooks ---------------------------- */
	ngOnInit(): void {
		if (!this.dataColumns) throw new Error('columns is not defined');

		if (this.tableTitle) this.tableTitle = this.translate.instant('dccomp.dc-table.table_title_', { name: this.tableTitle });

		this.columnsToExport = this.dataColumns
			.filter(c => !c.isIdColumn && !c.isLayoutColumn && c.state.visible)
			.map(column => {
				return {
					objecyKey: column.columnKey,
					title: column.title,
					visible: column.state.visible,
				};
			});
	}

	/* -------------------------------------------------------------------------- */
	/*                                   Methods                                  */
	/* -------------------------------------------------------------------------- */
	protected Color: (color: ColorType) => string = (color: ColorType) => getColor(color);

	protected disableCloseMenu($event: MouseEvent) {
		$event.stopPropagation();
	}
	/* -------------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------- */
	/*                            Table Export Options                            */
	/* -------------------------------------------------------------------------- */
	protected exportFileName: string;
	protected columnsToExport: { objecyKey: TableObjectKey; title: string; visible: boolean }[];
	get allColumnsToExportChecked(): boolean {
		return this.columnsToExport.every(column => column.visible);
	}

	protected onExportTo() {
		const exportDialogData = this.dialogControl.getDialogData('ExporTableOptions');
		const { mode, type } = exportDialogData;
		const columnsToExport = this.columnsToExport.filter(column => column.visible);
		this.exportTo.emit({ mode, type, columns: columnsToExport, fileName: this.exportFileName ? this.exportFileName : this.tableTitle });
		this.dialogControl.close('ExporTableOptions');
	}

	protected openExportOptionsDialog(exportType: ExportToType, mode: TableExportMode): void {
		this.exportFileName = '';
		this.dialogControl.open('ExporTableOptions', { data: { type: exportType, mode } });
	}

	protected exportAllColumnsToggle() {
		//check if all columns are checked, if so, uncheck all, else check all
		const allColumnsChecked = this.allColumnsToExportChecked;
		this.columnsToExport.forEach(column => (column.visible = !allColumnsChecked));
	}

	protected exportColumnToggle(ojectKey: string) {
		const column = this.columnsToExport.find(column => column.objecyKey === ojectKey);
		column.visible = !column.visible;
	}

	protected get checkedRowIDs(): string[] {
		return TableConnector.instance.getSelectedRowsIDs();
	}
}
