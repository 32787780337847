Map.prototype.toSequentialArray = function <K, V>(): V[] {
	let resultArray: V[] = [];
	this.forEach((value: V, key: K) => {
		if (Array.isArray(value)) {
			value.forEach((v: V) => {
				resultArray.push(v);
			});
		} else {
			resultArray.push(value);
		}
	});
	return resultArray;
};
/* -------------------------------------------------------------------------- */
Map.prototype.flatValues = function <K, V>(): V[] {
	// return all deep values to flat array (maps,sets,arrays,)... recursive
	let resultArray: V[] = [];
	this.forEach((value: V, key: K) => {
		if (value instanceof Map) {
			resultArray = resultArray.concat(value.flatValues());
		} else if (value instanceof Set) {
			resultArray = resultArray.concat(Array.from(value));
		} else if (Array.isArray(value)) {
			resultArray = resultArray.concat(value);
		} else {
			resultArray.push(value);
		}
	});
	return resultArray;
};

/* -------------------------------------------------------------------------- */
Map.prototype.keysOf = function <K, V>(value: V): K[] | K {
	let result: K[] = [];
	this.forEach((v: V, key: K) => {
		if (v === value) {
			result.push(key);
		}
	});
	if (result.length === 1) return result[0];
	return result;
};

/* -------------------------------------------------------------------------- */
Map.prototype.hasValue = function <K, V>(value: V): boolean {
	for (const v of this.values()) {
		if (v === value) return true;
	}
	return false;
};
/* -------------------------------------------------------------------------- */
Map.prototype.compare = function <K, V>(other: Map<K, V>): boolean {
	if (this.size !== other.size) return false;
	for (const [key, value] of this) {
		if (other.get(key) !== value) return false;
	}
	return true;
};
/* -------------------------------------------------------------------------- */
Map.prototype.setFromObject = function <K, V>(obj: { [key: string]: V }): void {
	for (const key in obj) {
		this.set(key, obj[key]);
	}
};
/* -------------------------------------------------------------------------- */
Map.prototype.keysArray = function <K, V>(): K[] {
	return Array.from(this.keys());
};
/* -------------------------------------------------------------------------- */
Map.prototype.valuesArray = function <K, V>(): V[] {
	return Array.from(this.values());
};
/* -------------------------------------------------------------------------- */
Map.prototype.toObject = function <K, V>(): { [key: string]: V } {
	let result: { [key: string]: V } = {};
	this.forEach((value: V, key: K) => {
		result[key as any] = value;
	});
	return result;
};
/* -------------------------------------------------------------------------- */
Map.prototype.filterKeys = function <K, V>(filter: (key: K) => boolean): Map<K, V> {
	let result: Map<K, V> = new Map();
	this.forEach((value: V, key: K) => {
		if (filter(key)) {
			result.set(key, value);
		}
	});
	return result;
};
/* -------------------------------------------------------------------------- */
Map.prototype.filter = function <K, V>(filter: (value: V, key: K) => boolean): Map<K, V> {
	let result = new Map<K, V>();
	this.forEach((value: V, key: K) => {
		if (filter(value, key)) {
			result.set(key, value);
		}
	});
	return result;
};
/* -------------------------------------------------------------------------- */
// Path: src/app/Extensions/set.extension.ts
