
import { Component, Input } from '@angular/core';
import { BadgeColorType } from './badgeColor.type';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'docBadge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        TranslateModule,
    ],
})
export class BadgeComponent {
	@Input() text: string;
	@Input() badgeColor: BadgeColorType = BadgeColorType.Gray;
	@Input() enableDot: boolean = false;

	constructor() {}


  protected get backgroundColor(): string {
    switch (this.badgeColor) {
      case BadgeColorType.Blue:
        return 'bg-blue-100';
      case BadgeColorType.Green:
        return 'bg-green-100';
      case BadgeColorType.Red:
        return 'bg-red-100';
      case BadgeColorType.Yellow:
        return 'bg-yellow-100';
      case BadgeColorType.Gray:
        return 'bg-gray-200';
      case BadgeColorType.Orange:
        return 'bg-orange-100';
      default:
        return 'bg-gray-100';
    }
  }


}
