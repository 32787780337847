import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, catchError, defer, map, tap } from 'rxjs';
import {ItenantDTO, ITenantSettingsDTO } from './DTO/tenant.DTO';
import { TenantModel, TenantSettingsModel } from '../Entities/tenant/tenant.model';

@Injectable({
  providedIn: 'root'
})
export class TenantsService {

  constructor(private api: ApiService) { }

  private readonly tenantsAdminUri: string = 'Admin/tenant';
  private readonly currenttenantSettingsUri: string = 'Settings';

  public tenantsList = new BehaviorSubject<TenantModel[]>([]);
  public currentTenantSettings = new BehaviorSubject<TenantSettingsModel>(null)

  public getTenantsList(): Observable<TenantModel[]>{
    return defer(()=>{
      return this.api
      .get<ItenantDTO>(this.tenantsAdminUri).pipe(map((result)=>{
       const tenant = this.convertTenantDTOtoModel(result);
       this.tenantsList.pipe(map(()=>tenant));
       return this.tenantsList.value;
      }))
    })

  }

  public getTenantById(tenantID: string): Observable<TenantModel> {
    
    return this.api.get<ItenantDTO>(`${this.tenantsAdminUri}?id=${tenantID}`).pipe(map((res)=>{
      const tenant = this.convertTenantDTOtoModel(res);
      return tenant;
    }))
    
  }

  public updateTenantSettings(settings: TenantSettingsModel): Observable<boolean> {
    return this.api.post<ITenantSettingsDTO>(this.currenttenantSettingsUri, null, settings).pipe(
      map(_ => true)  
    );
  }
  

  public getCurrentTenantSettings(): Observable<TenantSettingsModel>{
    
      return this.api.get<ITenantSettingsDTO>(this.currenttenantSettingsUri).pipe(
        catchError((err)=>{
          console.log(err);
          return err;
        }),
        map((res)=>{
        const settingsModel = this.converStettingsDTOtoSettingModel(res)
        this.currentTenantSettings.next(settingsModel)
        return settingsModel
      })
      )

  }

  private converStettingsDTOtoSettingModel(DTO: ITenantSettingsDTO ): TenantSettingsModel {
    const model = new TenantSettingsModel
    model.companyLogo = DTO?.companyLogo;
    model.defaultLanguage = DTO?.defaultLanguage;
    model.htmlTemplates = DTO.htmlTemplates;
    model.smsAccountName = DTO?.smsAccountName;
    model.postalAccount = DTO?.postalAccount;
    model.smsAccountSid = DTO?.smsAccountSid;
    model.smsTwilioSid = DTO?.smsTwilioSid;
    model.templates = DTO.templates;
    model.useSplashScreen = DTO.useSplashScreen
    return model
  }

 private convertTenantDTOtoModel(DTO: ItenantDTO): TenantModel{
    const model = new TenantModel();
    model.companyID = DTO?.companyID;
    model.companyPH = DTO?.companyPH;
    model.formalName = DTO?.formalName;
    model.hosts = DTO?.hosts;
    model.id = DTO?.id;
    model.isEnabled = DTO?.isEnabled;
    model.name = DTO?.name;
    model.server = DTO?.server;
    return model
  }

}


