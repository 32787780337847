import { BehaviorSubject, map, Observable, of, take, tap } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

import { UserModel } from '../Entities/User/user.model';
import { StorageService } from './storage.service';
import { HttpOptions } from './api.service';
import { PermissionType } from '../Entities/User/user-permission.type';
import { StorageKeys } from './storage.data/storage-keys.data';
import { StorageModeType } from './storage.data/storage-mode.type';
import { UsersService } from './users.service';
import { JwtToken } from '../Entities/User/jwt-token.model';

export enum RequestType{
  Connect = 0,
  Refresh = 1
}

export interface IauthRequest {
  type: RequestType
  userName: string
  password: string
  company: string
  accessToken?: string
  refreshToken?: string
  remoteIpAddress?: string
}

export interface IauthResponse {
  accessToken: string
  refreshToken: string
  apiUri: string
  tenant: string
  user: UserModel
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /* ----------------------------- Authentication ----------------------------- */
  public authenticatedState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /* -------------------------------------------------------------------------- */

  constructor(private storage: StorageService,
   private http: HttpClient,
   private router:Router) {}

  public static readonly apiEndpoint = 'https://docserver.co.il'

  static get apiUri(): string {
    return window.storageService.getItem(StorageKeys.AuthServiceKeys.KeyAPIUri) ?? "";
  }

  static get isRememberMe(): boolean{
   return window.storageService.getItem<boolean>(StorageKeys.AuthServiceKeys.KeyRememberMe) ?? false
  }

  static get accessToken(): string {
    return window.storageService.getItem<string>(StorageKeys.AuthServiceKeys.KeyAccessToken);
  }

  static get refreshToken(): string {
    return window.storageService.getItem(StorageKeys.AuthServiceKeys.KeyRefreshToken)
  }

  static get isAuthenticated(): boolean {
    return AuthService.accessToken != null && AuthService.refreshToken != null;
  }

  static get getBaseUrl(): string {
    return (this.isAuthenticated ? this.apiUri : AuthService.apiEndpoint) + '/api/'
  }

   /* ----------------------------- Current Company ---------------------------- */
  static get currentCompany(): string {
    return window.storageService.getItem<string>(StorageKeys.AuthServiceKeys.KeyCompany);
  }

  // static get currentUser(): UserModel {
  //   return this.currentUser$.value
  // }


  static get getCompanyFromUrl() {
    var company :string = ''
		var uri :string = window.storageService.getItem(StorageKeys.AuthServiceKeys.KeyAPIUri) ?? location.host;
    var cleanUri :string =  cleanUri = uri.replace('www.', '');
    cleanUri = cleanUri.replace('https://', '');
    if (cleanUri.includes('localhost')) cleanUri = cleanUri.replace('http://', '');
    var uriArr = cleanUri.split('.docserver.');
    // console.log(uriArr.length)
    if (uriArr.length > 1){
      // console.log(uriArr[0])
      company = uriArr[0]
    }
    return company
	}

  static get currentUserPermissions(): PermissionType[] {
    return window.storageService.getItem<PermissionType[]>(StorageKeys.AuthServiceKeys.KeyPermissions);
  }

  static get currentUserManage(): boolean {
		return window.storageService.getItem<PermissionType>(StorageKeys.AuthServiceKeys.KeyPermissions)?.includes('users.manage');
	}

  static decodeUrl(url: ActivatedRouteSnapshot): Observable<boolean> {
    if (url.queryParams){
      let params = location.search.split('=');
      let urlAccessToken = params[1].split('&')[0];
      let urlRefreshToken = params[2] + '=';
      let urlCompany = params[4];

      // console.log("urlAccessToken: " + urlAccessToken)
      // console.log("urlRefreshToken: " + urlRefreshToken)
      // console.log("urlCompany: " + urlCompany)

      if (urlAccessToken && urlRefreshToken && urlCompany){
        window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyCompany, urlCompany, StorageModeType.Local);
        window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyAccessToken, urlAccessToken, StorageModeType.Local);
        window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyRefreshToken, urlRefreshToken, StorageModeType.Local);
        return of(true)
      }
      else return of(false)
    }
    return of(false)
  }


  private authAction (request: IauthRequest) : Observable <IauthResponse>{
    var options: HttpOptions = {
      params: new HttpParams(),
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': `*/*`
      })
    }
    var json = request == null ? undefined : JSON.stringify(request)
    // console.log("Auth Req: " + json.toString())
    return this.http.post<IauthResponse>(AuthService.apiEndpoint + '/api/auth/connect', json, options)
  }

  public login(company:string, username: string, password:string, rememberMe: boolean): Observable<IauthResponse> {
    let storeMode: StorageModeType
    if (rememberMe) storeMode = StorageModeType.Local;
    else storeMode = StorageModeType.Session
    let request: IauthRequest = {
      type: RequestType.Connect,
      userName: username,
      password: password,
      company: company,
      accessToken: null,
      refreshToken: null,
      remoteIpAddress: undefined,
   }

    return this.authAction(request).pipe(
     map((response)=>{
      UsersService.currentUser$.next(response.user)
      const user = new UserModel();
      this.decodeAndSetJWTToken(response.accessToken, user);
      window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyRememberMe, rememberMe, StorageModeType.Local);
      window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyCurrentUser, {user: response.user}, storeMode);
      window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyCompany, response.tenant, storeMode);
      window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyAPIUri, response.apiUri, storeMode);
      window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyAccessToken, response.accessToken, storeMode);
      window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyRefreshToken, response.refreshToken, storeMode);
      window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyRefreshToken, response.refreshToken, storeMode)
      window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyPermissions, user.permissions, storeMode);
      
     
      return response;
     }))
  }


  public refreshLogin() : Observable<IauthResponse>{
    let rememberMe = window.storageService.getItem(StorageKeys.AuthServiceKeys.KeyRememberMe) ?? false;
    let storeMode: StorageModeType
    if (rememberMe) storeMode = StorageModeType.Local;
    else storeMode = StorageModeType.Session
    let request: IauthRequest = {
      type: RequestType.Refresh,
      userName: null,
      password: null,
      company: window.storageService.getItem(StorageKeys.AuthServiceKeys.KeyCompany),
      accessToken: window.storageService.getItem(StorageKeys.AuthServiceKeys.KeyAccessToken),
      refreshToken: window.storageService.getItem(StorageKeys.AuthServiceKeys.KeyRefreshToken)
    }
    return this.authAction(request).pipe(
     tap((response)=>{
        console.log("AuthAction Complete")
        localStorage.clear()
        sessionStorage.clear()
        UsersService.currentUser$.next(response.user)
        const user = new UserModel();
        this.decodeAndSetJWTToken(response.accessToken, user);
        window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyRememberMe, rememberMe, StorageModeType.Local);
        window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyCurrentUser, {user: response.user}, storeMode );
        window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyCompany, response.tenant, storeMode);
        window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyAPIUri, response.apiUri, storeMode);
        window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyAccessToken, response.accessToken, storeMode)
        window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyRefreshToken, response.refreshToken, storeMode)
        window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyPermissions, user.permissions, storeMode);
        // console.log(response)
         return response;
      }))
    }


    public logout(forget: boolean = false) {
      window.storageService.setItem(StorageKeys.AuthServiceKeys.KeyRememberMe, false, StorageModeType.Local);
      window.storageService.removeItems(
              ...[
                  StorageKeys.AuthServiceKeys.KeyAccessToken,
                  StorageKeys.AuthServiceKeys.KeyAPIUri,
                  StorageKeys.AuthServiceKeys.KeyCurrentUser,
                  StorageKeys.AuthServiceKeys.KeyCompany,
                  StorageKeys.AuthServiceKeys.KeyPermanentUser,
                  StorageKeys.AuthServiceKeys.KeyPermissions,
                  StorageKeys.AuthServiceKeys.KeyRefreshToken,
                  StorageKeys.AuthServiceKeys.KeyCurrentUserPortrait,
                  'devMsg',
                  StorageKeys.SettingsServiceKeys.KeySettings,
                  StorageKeys.CompanySettingsKeys.KeySettings,
                  StorageKeys.CycleComponentKeys.KeyCurrentCycleId,
                  StorageKeys.CycleComponentKeys.KeySignMode,
                  StorageKeys.CycleComponentKeys.KeySignAllCycleIDs,
              ].concat(forget ? [StorageKeys.AuthServiceKeys.KeyCompany, StorageKeys.AuthServiceKeys.KeyUsername, StorageKeys.AuthServiceKeys.KeyPassword] : []),
      );
      this.authenticatedState$.next(false);
      window.storageService.logAll();
      this.router.navigate(['login']);
  }


  private decodeAndSetJWTToken(token: string, user: UserModel): UserModel {
		let jwtToken = new JwtToken(token);
	 user.permissions =  Array.isArray(jwtToken.permission) ? jwtToken.permission : [jwtToken.permission];
	
		return user;
	}

}
