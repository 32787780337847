import { ColumnStruct } from "./column.config";

export interface IUserTablesPrefrences {
	[key: string]: ITablePresets;
}

export class UserTablesPrefrences {
	allTables: Map<string, TablePresets> = new Map();

	constructor(preferences?: IUserTablesPrefrences) {
		if (!preferences) {
			return;
		}
		Object.entries(preferences).forEach(([tableName, tablePreferences]) => {
			this.allTables.set(tableName, new TablePresets(tableName, tablePreferences));
		});
	}

	getTable(tableName: string): TablePresets {
		return this.allTables.get(tableName);
	}

	toJSON() {
		return Array.from(this.allTables.entries()).reduce((acc, [tableName, table]) => {
			acc[tableName] = table.toJSON();
			return acc;
		}, {});
	}
}
/* -------------------------------------------------------------------------- */

export interface ITablePresets {
	defaultPreset: string;
	presets: {
		[key: string]: ITablePreset;
	};
}

export class TablePresets {
	name: string;
	defaultPreset: string;
	presets: Array<TablePreset> = [];

	constructor(tableName: string, preferences?: ITablePresets) {
		this.name = tableName;
		this.defaultPreset = preferences?.defaultPreset ?? undefined;
		if (preferences?.presets) {
			Object.entries(preferences.presets).forEach(([presetName, presetPreferences]) => {
				this.presets.push(new TablePreset(presetName, presetPreferences));
			});
		}
	}

	toJSON() {
		return {
			defaultPreset: this.defaultPreset,
			presets: this.presets.reduce((acc, preset) => {
				acc[preset.name] = preset.toJSON();
				return acc;
			}, {}),
		};
	}

	getDefaultPreset(): TablePreset {
		return this.presets.find(preset => preset.name === this.defaultPreset);
	}
}
/* -------------------------------------------------------------------------- */

export interface ITablePreset {
	globalSearch: string;
	filter: {
		fromDate: Date;
		toDate: Date;
	};
	columns: {
		[key: string]: IColumnConfig;
	};
}

export class TablePreset {
	name: string;
	globalSearch: string;
	filter: {
		fromDate: Date;
		toDate: Date;
	};

	columns: Array<IColumnConfig> = [];

	constructor(presetName: string, preferences?: ITablePreset) {
		this.name = presetName;
		if (preferences) {
			this.globalSearch = preferences?.globalSearch;
			this.filter = preferences?.filter;
			Object.entries(preferences?.columns).forEach(([columnName, columnPreferences]) => {
				columnPreferences.key = columnName;
				this.columns.push(columnPreferences);
			});
			// sort the columns by index
			this.columns = this.columns.sort((a, b) => a.index - b.index);
		}
	}

	toJSON() {
		return {
			globalSearch: this.globalSearch,
			filter: this.filter,
			columns: this.columns.reduce((acc, column) => {
				const key = column.key;
				const colValue = column;
				acc[key] = colValue;
				return acc;
			}, {}),
		};
	}

	static generate({
		presetName,
		searchValue,
		dateRangeFilter,
		visibleColumns,
	}: {
		presetName: string;
		searchValue?: string;
		dateRangeFilter?: { fromDate: Date; toDate: Date };
		visibleColumns?: ColumnStruct<any>[];
	}): TablePreset {
		const preset = new TablePreset(presetName);
		preset.globalSearch = searchValue ?? '';
		preset.filter = dateRangeFilter ?? { fromDate: null, toDate: null };
		preset.columns = visibleColumns.map(column => {
			return {
				key: column.columnKey,
				columnSearch: '',
				index: column.state.index,
				visible: true,
				width: -1,
				sort: null,
			};
		});
		return preset;
	}
}

/* -------------------------------------------------------------------------- */

export interface IColumnConfig {
	key: string;
	index: number;
	columnSearch: string;
	visible: boolean;
	width: number;
	sort: 'asc' | 'desc' | null;
}
