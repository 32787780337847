import { Directive, TemplateRef, Injectable, Input } from '@angular/core';
import { TableObjectKey } from '../data/types/table.data';

@Directive({
	selector: '[CellTemplateCollector]',
	standalone: true,
})
export class CellTemplateCollectorDirective {
	constructor(private templateRef: TemplateRef<any>, private cellTemplateCollector: CellTemplateCollectorService) {}

	@Input('CellTemplateCollector') set CellTemplateCollector(columnKey: TableObjectKey) {
		this.cellTemplateCollector.setCellTemplate(columnKey, this.templateRef);
	}
}

@Injectable({
	providedIn: 'root',
})
export class CellTemplateCollectorService {
	private cellTemplates: Map<TableObjectKey, TemplateRef<any>> = new Map();

	constructor() {}

	public setCellTemplate(columnKey: TableObjectKey, templateRef: TemplateRef<any>) {
		this.cellTemplates.set(columnKey, templateRef);
	}

	public getCellTemplate(columnKey: TableObjectKey): TemplateRef<any> {
		return this.cellTemplates.get(columnKey);
	}
}
