export class TableConst {
	public static readonly DEFAULT_ROWS_PER_PAGE = 8;
	public static readonly DEFAULT_CURRENT_PAGE_NUMBER = 1;

	/* -------------------------------------------------------------------------- */
	/*                             Lazy Mode Costants                             */
	/* -------------------------------------------------------------------------- */
	public static readonly DEFAULT_LAZY_ROWS_PER_PAGE = 8;
	public static readonly DEFAULT_LAZY_CURRENT_PAGE_NUMBER = 1;

	/* -------------------------------------------------------------------------- */
	/*                         Rows Per Page - By Viewport                        */
	/* -------------------------------------------------------------------------- */
	public static readonly rowsPerPageMobile = 5;
	public static readonly rowsPerPageTablet = 10;
	public static readonly rowsPerPageDesktop = 13;
	public static readonly rowsPerPageLargeDesktop = 19;

	/* -------------------------------------------------------------------------- */
	public static readonly DEFAULT_ROW_HEIGHT = 52;

  /* -------------------------------------------------------------------------- */
  public static readonly VISIBLE_INVISIBLE_COLUMNS_DELTA = 1000;
}
