export class StorageKeys {
	public static readonly GlobalKeys = {
		KeyNewKeysInstall: 'KeyNewKeysInstall',
		KeyImLoggedOutTab: 'ImLoggedOutTab',
	};

	public static readonly AuthServiceKeys = {
		KeyAPIUri: 'Auth.APIUri',
		KeyEnvironment: 'Auth.Environment',
		KeyAccessToken: 'Auth.AccessToken',
		KeyRefreshToken: 'Auth.RefreshToken',
		KeyCurrentUser: 'Auth.CurrentUser',
		KeyCurrentUserPortrait: 'Auth.CurrentUserPortrait',
		KeyCompany: 'Auth.CurrentCompany',
		KeyPermanentUser: 'Auth.PermanentUser',
		KeyPermissions: 'Auth.Permissions',
		KeyLanguage: 'Auth.Language',
		KeyUsername: 'Auth.Username',
		KeyPassword: 'Auth.Password',
		KeyRememberMe: 'Auth.RememberMe',
	};

	public static readonly AuthGuardServiceKeys = {
		KeyDownloadUrl: 'AuthGuard.downloadUrl',
		KeyReloaded: 'AuthGuard.reloaded',
	};

	public static readonly AppVersionKeys = {
		KeyFrontendVersion: 'AppVersion.FrontendVersion',
		KeyBackendVersion: 'AppVersion.BackendVersion',
	};

	public static readonly CycleComponentKeys = {
		KeySignMode: 'CycleComponent.SignMode',
		KeyCurrentCycleId: 'CycleComponent.CurrentCycleId',
		KeySignAllCycleIDs: 'CycleComponent.SignAllCycleIDs',
	};

	public static readonly CompanySettingsKeys = {
		KeySettings: 'Company.settings',
		KeyCompanyLogo: 'Company.logo',
	};

	public static readonly SettingsServiceKeys = {
		KeySettings: 'Settings.settings',
	};

	public static readonly AppComponentKeys = {
		KeyCurrentState: 'App.CurrentState',
	};

	public static readonly SettingsComponentKeys = {
		KeyLastSelectedTab: 'SettingsComponent.KeyLastSelectedTab',
	};

	public static readonly SignDoc2ComponentKeys = {
		KeyAfterSignThankYou: 'SignDoc2Component.KeyAfterSignThankYou',
	};

	public static readonly DcTableKeys = {
		LastLazyMode: 'DcTable.LastLazyMode',
	};
	public static SidenavKeys = {
		KeyFloatOpenSidenavBtnTransform: 'Sidenav.FloatOpenSidenavBtnTransform',
    KeySideNavMode: 'Sidenav.SideNavMode',
	};
}
