
export const BadgeColorType = {
	Blue: 'blue',
  Green: 'green',
  Red: 'red',
  Yellow: 'yellow',
  Gray: 'gray',
  Orange: 'orange',
} as const;
export type BadgeColorType = keyof typeof BadgeColorType | Lowercase<keyof typeof BadgeColorType> | (typeof BadgeColorType)[keyof typeof BadgeColorType];
