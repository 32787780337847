<div class="flex flex-col items-center justify-center">
	<span class="my-4 text-3xl">{{ 'dccomp.dc-table.messages.empty-results' | translate }}</span>

	<button
		*ngIf="showClearButton"
		mat-stroked-button
		color="primary"
		class="mb-4"
		(click)="clear.emit()"
	>
		{{ 'dccomp.dc-table.messages.clear-and-restart' | translate }}
		<i class="fa-solid fa-rotate-right fa-xl"></i>
	</button>
</div>
