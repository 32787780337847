Object.defineProperty(Array.prototype, 'remove', function <T>(this: Array<T>, find: T | ((item: T) => boolean)): number {
	if (typeof find == 'function') {
		var index = this.findIndex(item => (<(item: T) => boolean>find)(item));
	} else {
		var index = this.findIndex(item => item == find);
	}
	if (index > -1) this.splice(index, 1);
	return index;
});

Array.prototype.insertBefore = function <T>(this: Array<T>, item: T, beforeItem: (item: T) => boolean): void {
	var index = this.findIndex(beforeItem);
	if (index > -1) this.splice(index, 0, item);
};

Array.prototype.moveBefore = function <T>(this: Array<T>, item: T, beforeItem: (item: T) => boolean): void {
	var index = this.findIndex(beforeItem);
	if (index > -1) {
		var itemIndex = this.findIndex(i => i == item);
		if (itemIndex > -1) {
			this.splice(itemIndex, 1);
			this.splice(index, 0, item);
		}
	}
};

Array.prototype.insertAfter = function <T>(this: Array<T>, item: T, afterItem: (item: T) => boolean): void {
	var index = this.findIndex(afterItem);
	if (index > -1) this.splice(index + 1, 0, item);
};

Array.prototype.moveAfter = function <T>(this: Array<T>, item: T, afterItem: (item: T) => boolean): void {
	var index = this.findIndex(afterItem);
	if (index > -1) {
		var itemIndex = this.findIndex(i => i == item);
		if (itemIndex > -1) {
			this.splice(itemIndex, 1);

			if (index == this.length - 1) {
				this.push(item);
			} else {
				this.splice(index + 1, 0, item);
			}
		}
	}
};

Array.prototype.insertAt = function <T>(this: Array<T>, item: T, index: number): void {
	this.splice(index, 0, item);
};

Array.prototype.removeAt = function <T>(this: Array<T>, index: number): void {
	this.splice(index, 1);
};
