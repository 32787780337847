import { Language } from './language.types';
import { LanguageType } from './language.types';

export const English: Language = { label: 'English', flag: 'gb', value: 'en', dir: 'ltr', locale_code: 'en', iconPath: 'assets/lang/svg/us.svg' };
export const Hebrew: Language = { label: 'עברית', flag: 'il', value: 'he', dir: 'rtl', locale_code: 'he', iconPath: 'assets/lang/svg/il.svg' };
export const Languages: Language[] = [English, Hebrew];

export function getLanguage(language: LanguageType | string): Language {
	switch (language) {
		case 'en':
			return English;
		case 'he':
			return Hebrew;
		default:
			return Hebrew;
	}
}
