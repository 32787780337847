import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  DocumentModel,
  DocumentStatus,
  DocumentUserModel,
  DocUserStatus,
  LogModel,
} from '../Entities/Documents/document.model';
import { IDocumentDTO, IMetaDatasDTO } from './DTO/document.DTO';
import { ApiService } from './api.service';
import { UsersService } from './users.service';
import { UserModel } from '../Entities/User/user.model';
import { DocumentListType, IReportsQueryResponse, ReportsQueryRequest } from 'src/app/modules/reports/http/reports-query.http';
import { IDocumentData } from './DTO/document-data';

export interface IQueryResponse {
  totalDocuments: number;
  fromDocuments: number;
  toDocuments: number;
  documents: DocumentModel[];
}

export interface IReportListResponse {
  lastCall: string;
  recieved: IDocumentDTO[];
  sent: IDocumentDTO[];
}

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  public documentsList = new BehaviorSubject<DocumentModel[]>([]);

  constructor(private api: ApiService, private usersService: UsersService) {}

  //get Document model from backend with user id and after recive user id call get user from user service and set user in document model
  private getModelFromDTO(dto: IDocumentDTO): DocumentModel {
    const model = new DocumentModel();
    model.id = dto.id;
    model.name = dto.name;
    model.file = dto.file;
    model.log = dto.log.map((l) => {
      const lm = new LogModel();
      lm.action = l.action;
      lm.description = l.description;
      lm.timestamp = l.timestamp + 'Z';
      this.usersService.getUserByID(l.user).subscribe((res) => (lm.user = res));
      return lm;
    });
    this.usersService.getUserByID(dto.recipient).subscribe((res) => {
      model.recipient = new UserModel();
    });

    model.recipients = dto.recipients.map((r) => {
      const du = new DocumentUserModel();
      du.status = DocUserStatus[r.status];
      du.timestamp = r.timestamp;
      du.type = r.type;
      this.usersService.getUserByID(r.user).subscribe((res) => {
        du.user = res;
      });
      return du;
    });

    model.metadatas = dto.metadatas
    model.message = dto.message;
    model.status = DocumentStatus[dto.status];
    model.user = dto.user;
    model.enabled = dto.enabled;
    model.preferences = dto.preferences;
    return model;
  }

  public getDocumentByID(documentID: string): Observable<DocumentModel> {
    let document = this.documentsList.pipe(
      map((list) => list.find((document) => document.id == documentID)));
    return document;
  }


  query(queryCycleRequest: ReportsQueryRequest): Observable<IQueryResponse> {
		return this.api.get<IReportsQueryResponse>('Documents/query', queryCycleRequest.toHttpParams()).pipe(
      map((response: IReportsQueryResponse) => {
        const documents = response.documents.map((doc) => this.getModelFromDTO(doc));
        return {
          totalDocuments: response.totalDocuments,
          fromDocuments: response.fromDocument,
          toDocuments: response.toDocument,
          documents: documents,
        }
      }
      )
    );
	}


  list(fromDate: Date, toDate: Date, list: DocumentListType[]): Observable<DocumentModel[]> {
    return this.api
      .get<IReportListResponse>('Documents/list', {
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        list: list,
      })
      .pipe(
        map((response: IReportListResponse) => {
          const documents = response.sent
            .concat(response.recieved)
            .map((doc) => this.getModelFromDTO(doc));
          return documents;
        })
      );
  }

 public getDocumentData(id: string):Observable<string> {
    return this.api.get<IDocumentData>('Documents/data', { id }).pipe(
      map((response: IDocumentData) => {
        return response.documentData;
      })
    );
  }

  public notifyRecipients(documentID: string): Observable<boolean> {
    return this.api.post<{}>('Documents/resend', { id: documentID }, null).pipe(
      map((response) => {
        return response ? true : false;
      })
    );
  }


}