import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, defer, map, Observable, tap, throwError } from 'rxjs';
import { UserModel } from '../Entities/User/user.model';
import { ApiService } from './api.service';
import { IUserDTO } from './DTO/user.DTO';
import { StorageKeys } from './storage.data/storage-keys.data';
import { StorageService } from './storage.service';
import { AuthService } from './auth.service';

import { JwtToken } from '../Entities/User/jwt-token.model';

interface IuserListResponse {
  lastCall: string;
  users: IUserDTO[];
}

@Injectable({
  providedIn: 'root',
})
export class UsersService {

  /* ------------------------------ Current User ------------------------------ */

  private isRefreshed: boolean = false
  public $usersList = new BehaviorSubject<UserModel[]>([]);
  private readonly UsersListUri: string = 'Users';
  private readonly UserUpdateUri: string = 'Users/user';
  private readonly UserByIdUri: string = 'Users/user?id=';
  private lastCall: string = null;

  constructor(private api: ApiService, private storageService:StorageService, private authService: AuthService) {}

// ===== Get Current User ======
  private static _currentUser$: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);

// ===== Get Current User MODEL ======
	public static get currentUser(): UserModel {
    const user = window.storageService.getItem<{user:UserModel}>(StorageKeys.AuthServiceKeys.KeyCurrentUser)?.user;
    if (this._currentUser$) {
			return this._currentUser$.value;
		}  
		if (user) {
			this._currentUser$.next(user);
			return this._currentUser$.value;
		}
		return null;
	}

// ==== Get Current User Observable =====
  public static get currentUser$():BehaviorSubject<UserModel>{
    const user = window.storageService.getItem<{user:UserModel}>(StorageKeys.AuthServiceKeys.KeyCurrentUser)?.user;    
    if (this._currentUser$) {
      this._currentUser$ = new BehaviorSubject<UserModel>(user)
			return this._currentUser$;
		}		
		if (user) {
			this._currentUser$.next(user);
			return this._currentUser$;
		}
		return null;
  }



  public getUserByID(userId: string): Observable<UserModel | undefined> {
    return this.$usersList.pipe(
      map((list) => list.find((user) => user.id == userId))
    );
  }

  public refreshCurrentUser(): Observable<UserModel> {
    const currentUserId = this.storageService.getItem<{user:UserModel}>(StorageKeys.AuthServiceKeys.KeyCurrentUser).user.id
    if (!currentUserId) {
       this.authService.logout()
       throw ("Current user ID is missing or invalid");
    }
  
    if (!UsersService.currentUser){
      return this.api.get<UserModel>(this.UserByIdUri + currentUserId).pipe(
        tap((user) => {
          console.log(user)
          UsersService.currentUser$.next(user);
        })
      );
    }
  }

  public getUsersList(): BehaviorSubject<UserModel[]> {
    return this.$usersList;
  }

  public convertModelToDTO(model: UserModel): IUserDTO{
    var userDTO: IUserDTO = {
      id: model.id,
      username: model.username,
      fullname:model.fullname,
			email: model.email,
      phonenumber: model.phonenumber,
      enabled: model.enabled,
      roles: model.roles,
      hide: model.hide,
      preferences: model.preferences,
      tags: model.tags,
      title: model.title,
      hasPortrait: model.hasPortrait
    };
    return userDTO
  }

  public getModelFromDTO(dto: IUserDTO): UserModel {
    var model = new UserModel();
    model.id = dto.id;
    model.email = dto.email;
    model.phonenumber = dto.phonenumber;
    model.title = dto.title;
    model.fullname = dto.fullname;
    model.username = dto.username;
    model.roles = dto.roles;
    model.preferences.language = dto.preferences.language;
    model.preferences.notifyEmail = dto.preferences.notifyEmail;
    model.preferences.notifySMS = dto.preferences.notifySMS;
    model.preferences.notifyWhatsapp = dto.preferences.notifyWhatsapp;
    return model;
  }

  public refreshUsersList(): Observable<UserModel[]> {
    return defer(() => {
      return this.api.get<IuserListResponse>(this.UsersListUri, {
        lastCall: this.lastCall,
      });
    }).pipe(
      map((result) => {
        var old = this.$usersList.value;
        this.lastCall = result.lastCall;
        result.users.forEach((doc) => {
          var index = old.find((oldDoc) => oldDoc.id == doc.id);
          if (index === undefined) {
            old.push(this.getModelFromDTO(doc));
          } else {
            old[old.indexOf(index)] = this.getModelFromDTO(doc);
          }
        });
        this.$usersList.next(old);
        return this.$usersList.value;
      })
    );
  }

  public updateUser(user: UserModel, newPassword?: string): Observable<UserModel> {
    const request = { user: user, password: newPassword }
    console.log(request)
		return this.api.put<{user: UserModel}>(this.UserUpdateUri, request).pipe(
			map((res) => {
        this.isRefreshed = true;
        this.storageService.setItem(StorageKeys.AuthServiceKeys.KeyCurrentUser,{user: res.user});
        return res.user;
       })
		
		);
	}



  private decodeAndSetJWTToken(token: string, user: UserModel): UserModel {
		let jwtToken = new JwtToken(token);
	 user.permissions =  Array.isArray(jwtToken.permission) ? jwtToken.permission : [jwtToken.permission];
	



		return user;
	}
}
