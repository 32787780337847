
import ExcelJS from 'exceljs';
import { GlobalsService } from 'src/app/Data/Services/globals.service';
import { ReportsComponent } from 'src/app/modules/reports/reports.component';
import { TableComponent } from 'src/app/modules/table/table/table.component';

export enum ExportToType {
  CSV = 'csv',
  XLSX = 'xlsx',
  JSON = 'json',
}

export type CsvToOptions = {
  fileName: string;
  delimiter?: string; // default: ','
  keysAndTitles?: { key: string; title: string }[];
};

export type ExcelOptions = {
  fileName: string;
  sheetName?: string;
  type?: 'base64' | 'buffer' | 'binary' | 'file' | 'array' | 'string'; // Optional: Output data encoding
  keysAndTitles?: { key: string; title: string }[];
};

export class DcExporterTool {
  public static exporter(data: any[], type: ExportToType, options: CsvToOptions | ExcelOptions) {
    if (options.keysAndTitles) {
      data = data.map((row: any) => {
        const newRow: any = {};
        options.keysAndTitles.forEach((keyAndTitle: { key: string; title: string }) => {
          const translatedTitle = GlobalsService.globalTranslate(keyAndTitle.title);
          newRow[translatedTitle] = row[keyAndTitle.key];
        });
        return newRow;
      });
    }
    switch (type) {
      case ExportToType.CSV:
        return this.exportToCsv(data, options as CsvToOptions);
      case ExportToType.XLSX:
        return this.exportToExcel(data, options as ExcelOptions);
      case ExportToType.JSON:
        return this.exportToJson(data, options as CsvToOptions);
    }
  }

  private static exportToCsv(data: any[], options: CsvToOptions) {
    const replacer = (key: string, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(options.delimiter));
    csv.unshift(header.join(options.delimiter));
    const csvArray = csv.join('\r\n');
    const blob = new Blob([csvArray], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${options.fileName}.csv`);
    link.click();
  }

  private static exportToExcel(data: any[], options: ExcelOptions) {
    const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
    const worksheet: ExcelJS.Worksheet = workbook.addWorksheet(options.sheetName || 'Sheet1', {
      properties: {
        defaultColWidth: 35,
        showGridLines: true,
      },
      views: [
        {
          rightToLeft: GlobalsService.currentLang.he,
        },
      ],
    });

    // Add column headers
    const columns: { key: string; title: string }[] = [{key: '#', title: '#'}, ...options.keysAndTitles];
    worksheet.columns = columns.map((keyAndTitle: { key: string; title: string }) => {
      const keyTitle: string = GlobalsService.globalTranslate(keyAndTitle.title);
      return {
        header: keyTitle,
        key: keyTitle || keyAndTitle.key,
        width: keyTitle == '#' ? 5 : undefined,
      };
    });

    // Add rows
    data.forEach((row: any) => {
      const newRow: any = {
        '#': data.indexOf(row) + 1,
        ...row,
      };

      worksheet.addRow(newRow);
    });

    // Add styles
    const cellsCount = columns.length;

    // HEADER
    const headerRow = worksheet.getRow(1);
    for (let i = 0; i < cellsCount; i++) {
      headerRow.getCell(i + 1).font = {bold: true, size: 16}; // bold header
      headerRow.getCell(i + 1).alignment = {vertical: 'middle', horizontal: 'center'}; // center header
      headerRow.getCell(i + 1).fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFD3D3D3'}}; // gray header
      headerRow.getCell(i + 1).border = {
        top: {style: 'thin'},
        left: {style: 'thin'},
        bottom: {style: 'thin'},
        right: {style: 'thin'}
      }; // border header
    }

    // BODY
    worksheet.getRows(2, data.length).forEach(row => {
      for (let i = 0; i < cellsCount; i++) {
        const cell = row.getCell(i + 1);
        cell.font = {size: 14}; // font body
        cell.alignment = {vertical: 'middle', horizontal: 'center'}; // center body
        cell.border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}; // border body
      }
    });

    // download
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `${options.fileName}.xlsx`);
      link.click();
      ReportsComponent.setLoader(false)
    });
  }

  private static exportToJson(data: { [key: string]: any }[], options: CsvToOptions) {
    const blob = new Blob([JSON.stringify(data)], {type: 'text/json'});
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${options.fileName}.json`);
    link.click();
  }
}
