import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { TenantsService } from 'src/app/Data/Services/tenants.service';

@Component({
  selector: 'general-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    MatCheckboxModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent implements OnInit {
  protected imagePreview: any = null;
  protected isDragOver: boolean = false;

  public generalSettingsFG: FormGroup = new FormGroup({
    defaultLanguage: new FormControl(''),
    useSplashScreen: new FormControl(''),
    companyLogo: new FormControl(''),
  });
  constructor(
    protected sanitizer: DomSanitizer,
    private tenantsService: TenantsService,
  ) {}
  ngOnInit(): void {
    this.tenantsService.currentTenantSettings.subscribe((res) => {
      if (res) {
        this.imagePreview = res.companyLogo.data;
        this.generalSettingsFG.patchValue({
          defaultLanguage: res.defaultLanguage,
          useSplashScreen: res.useSplashScreen,
          companyLogo: res.companyLogo.data,
        });
      }
    });
  }

  public getFormData(): any {
    return this.generalSettingsFG.value;
  }

  onFileChanged(event: any) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imagePreview = reader.result as string;
        this.generalSettingsFG.get('companyLogo')?.setValue(this.imagePreview);
      };
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false; // Add this line
    let files = event.dataTransfer?.files;

    if (files && files.length) {
      this.onFileChanged({ target: { files: files } });
    }
  }

  onDragOver(event: Event) {
    event.preventDefault();
    this.isDragOver = true; // Add this line
  }

  onDragLeave(event: Event) {
    event.preventDefault();
    this.isDragOver = false; // Add this line
  }

  onClear() {
    this.imagePreview = null;
    this.isDragOver = false;
    this.generalSettingsFG.get('companyLogo')?.setValue(null);
  }
}
