import { TableObjectKey } from "../types/table.types";

/**
 * The state of a row in the table.
 * @typedef {Object} RowStateData
 * @property {number} index - The index of the row in the table.
 * @property {number} page - The page of the table that the row is on.
 * @property {any} id - The id of the row.
 * @property {Record<TableObjectKey, any>} data - The data of the row.
 */
export type RowStateDataStruct = {
	index: number;
	page: number;
	id: any;
	data: Record<TableObjectKey, any>;
	checked?: boolean;
};

export class RowStateData implements RowStateDataStruct {
	page: number;
	index: number;
	id: any;
	data: Record<TableObjectKey, any>;
	checked: boolean = false;

	constructor({ id, data, checked }: RowStateDataStruct) {
		this.id = id;
		this.data = data;
		this.checked = checked || false;
	}
}
