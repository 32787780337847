
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="flex justify-center items-center gap-4">
                    <i class="fa-solid fa-gear text-xl"></i>
                    {{"General-settings" | translate }}
                </div>
            </ng-template>
                <general-settings />
         </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div class="flex justify-center items-center gap-4">
                    <i class="fa-sharp fa-solid fa-messages"></i>
                    {{"Templates-and-messages" | translate }}
                </div>
            </ng-template>
            
                <templates-and-messages />
            
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <div class="flex justify-center items-center gap-4">
                    <i class="fa-duotone fa-tags"></i>
                    {{"Tags" | translate }}
                </div>
            </ng-template>
            <ng-template matTabContent>
                <meta-tag />
            </ng-template>
        </mat-tab>
        
    
    
    </mat-tab-group>

   
        <button 
        *ngIf="hesSettingsWritePermission"
        mat-raised-button
        [disabled]="loading"
        color="primary"
        (click)="saveSettings()"
        class="w-28 h-16 text-2xl absolute bottom-4 {{dir === 'rtl' ? 'left-4' : 'right-4'}}"
        >
        {{'save-btn' | translate}}
        </button>
