<ngx-extended-pdf-viewer
*ngIf="pdfBase64"
 [base64Src]="pdfBase64"
 [(zoom)]="zoomValue"
 [(page)]="currentPage"
 [showTextEditor]="false"
 [showDrawEditor]="false"
 [showHandToolButton]="false"
 [showSecondaryToolbarButton]="false"
 [showOpenFileButton]="false"
 [showStampEditor]="false"
 [showRotateButton]="false"
 [showDownloadButton]="true"
 [showPrintButton]="false"
/>


