import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {DocIconComponent} from 'src/app/modules/icon/icon.component';
import {LanguageService} from 'src/app/Data/Services/language.service';
import {HoverDirective} from 'src/app/Directives/hover.directive';
import {IsMobileDirective} from 'src/app/Directives/is-mobile.directive';
import {LoaderDirective} from 'src/app/Directives/loder.directive';
import {DcSidenavItemStruct} from '../../data/sidenav-item-struct.model';
import {SideNavControl} from '../../data/sidenav.control/sidenav.control';
import {UserProfileCardComponent} from '../user-profile-card/user-profile-card.component';
import {MatMenuModule} from '@angular/material/menu';
import {OpenInNewTabDirective} from 'src/app/Directives/open-in-new-tab.directive';
import {DirectionDirective} from 'src/app/Directives/direction.directive';
import {MatButtonModule} from '@angular/material/button';

const standalones = [
  UserProfileCardComponent,
  LoaderDirective,
  HoverDirective,
  DocIconComponent,
  IsMobileDirective,
  OpenInNewTabDirective,
  DirectionDirective,
];
const commons = [CommonModule, RouterModule, MatListModule, TranslateModule, FormsModule, ReactiveFormsModule, NgOptimizedImage];
const matModules = [MatMenuModule, MatInputModule, MatFormFieldModule, MatTooltipModule, MatButtonModule];
const npmModules = [];

@Component({
	selector: 'dc-sidenav-item',
	templateUrl: './sidenav-item.component.html',
	styleUrls: ['./sidenav-item.component.scss'],
	standalone: true,
	imports: [...commons, ...matModules, ...standalones, ...npmModules],
})
export class SidenavItemComponent implements OnInit {
	protected sideNavCtrl: SideNavControl = SideNavControl.instance;
	protected languageService: typeof LanguageService = LanguageService;
	/* --------------------------------- Inputs --------------------------------- */
	@Input() item: DcSidenavItemStruct;

	/* --------------------------------- Outputs -------------------------------- */

	/* -------------------------------------------------------------------------- */
	/*                                 Constructor                                */
	/* -------------------------------------------------------------------------- */
	constructor(public element: ElementRef) {}

	/* -------------------------------------------------------------------------- */
	/*                               LifeCycle Hooks                              */
	/* -------------------------------------------------------------------------- */
	ngOnInit(): void {
		if (!this.item) throw new Error('SidenavItemComponent: item is required');
	}

	/* -------------------------------------------------------------------------- */
	/*                              Protected Methods                             */
	/* -------------------------------------------------------------------------- */
	protected onClickItem(item: DcSidenavItemStruct, event: MouseEvent) {
		if (this.item.type === 'item' && item.onClickFn) {
			item.onClickFn(item);
		} else if (this.item.type === 'item' && window.deviceService.isMobile) {
      this.sideNavCtrl.close();
		} else if (this.item.type === 'category' && item.subItems?.length > 0) {
			this.item.toggleCategoryItems();
		}

		event.stopPropagation();
		event.preventDefault();
	}
}
