import { TableEntryStruct } from '../types/table.types';

export class PagingStruct<T extends TableEntryStruct> {
	currentPage?: number = 1;
	pageSize?: number = 5;
	totalRows?: number;

	constructor(init?: PagingConfigStruct<T>) {
		Object.assign(this, init);
	}

	get totalPages(): number {
		return Math.ceil(this.totalRows / this.pageSize);
	}

	get currentPageRowsCount(): number {
		return this.currentPage == this.totalPages ? this.totalRows % this.pageSize : this.pageSize;
	}
}

export type PagingConfigStruct<T extends TableEntryStruct> = { [key in keyof PagingStruct<T>]?: any };
