import { Injectable } from '@angular/core';
import { InitiableService } from 'src/app/Extensions/initiable.service';
import { StorageModeType, StorageModeData, IStorageModeData } from 'src/app/Data/Services/storage.data/storage-mode.type';
import { StorageKeys } from 'src/app/Data/Services/storage.data/storage-keys.data';

declare global {
	interface Window {
		storageService: StorageService;
	}
}

@Injectable({
	providedIn: 'root',
})
export class StorageService extends InitiableService {
	private get storageMode(): StorageModeType {
		return localStorage.getItem(StorageKeys.AuthServiceKeys.KeyRememberMe) == 'true' ? StorageModeType.Local : StorageModeType.Session;
	}

	constructor() {
		super([]);
		window['storageService'] = this;
	}

	targetStorage(key: string): IStorageModeData | undefined {
		return Array.from(StorageModeData.values())
			.map(storageObject => {
				if (storageObject.getItem(key)) return storageObject;
			})
			.filter(st => Boolean(st))[0];
	}

	has(key: string): boolean {
		return this.targetStorage(key)?.hasOwnProperty(key);
	}

	setItem(key: string, value: any, mode?: StorageModeType) {
		if (!mode) mode = this.storageMode;
		return StorageModeData.get(mode)?.setItem(key, JSON.stringify(value));
	}

	setMultipleItems(items: { [key: string]: any }, mode?: StorageModeType) {
		if (!mode) mode = this.storageMode;
		for (let key in items) {
			this.setItem(key, items[key], mode);
		}
	}

	getItem<T>(key: string): T {
		try {
			const targetStorage = this.targetStorage(key);
			if (targetStorage) {
				const data: T = JSON.parse(targetStorage.getItem(key));
				if (data) {
					return data;
				}
			}
			return undefined;
		} catch (e) {
			return undefined;
		}
	}

	removeItems(...keys: string[]): void {
		keys.forEach(key => {
			this.targetStorage(key)?.removeItem(key);
		});
	}

	clear(specificMode: StorageModeType = undefined): void {
		let backup = this.getItem('__pwacompat_manifest');
		if (specificMode != undefined) {
			StorageModeData.get(specificMode).clear();
		} else {
			window.localStorage.clear();
			window.sessionStorage.clear();
		}
		if (backup) StorageModeData.get(StorageModeType.Session).setItem('__pwacompat_manifest', backup as any);
	}

	logAll(): void {
		console.debug('sessionStorage logAll(): ', sessionStorage);
		console.debug('localStorage logAll(): ', localStorage);
	}
}
