import { MatTooltipModule } from '@angular/material/tooltip';
import { LanguageService } from 'src/app/Data/Services/language.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DocIconComponent } from 'src/app/modules/icon/icon.component';
import { MatButtonModule } from '@angular/material/button';
import { LoaderDirective } from 'src/app/Directives/loder.directive';
import { Language } from 'src/app/Data/Services/language.data/language.types';
import { getLanguage } from 'src/app/Data/Services/language.data/language.const';

const commons = [CommonModule, TranslateModule, NgOptimizedImage];
const matModules = [MatButtonModule, MatTooltipModule];
const standalones = [DocIconComponent, LoaderDirective];
const npmModules = [];

@Component({
  selector: 'docLanguage-toggle',
  standalone: true,
  imports: [...commons, ...matModules, ...standalones, ...npmModules],
  templateUrl: './language-toggle.component.html',
  styleUrls: ['./language-toggle.component.scss'],
})
export class LanguageToggleComponent implements AfterViewInit {
  @ViewChild('SwitchToggle') switchToggle: ElementRef;
  @Output() languageChanged: EventEmitter<Language> =
    new EventEmitter<Language>();

  /* -------------------------------------------------------------------------- */
  /*                              Global Variables                              */
  /* -------------------------------------------------------------------------- */
  private readonly heClasses = ['bg-blue-500', '-translate-x-full'];
  private readonly enClasses = ['bg-red-500', 'translate-x-2'];
  protected activeLanguage: Language = this.languageService.CurrentLanguage;

  /* -------------------------------------------------------------------------- */
  /*                                 Constructor                                */
  /* -------------------------------------------------------------------------- */
  constructor(
    private languageService: LanguageService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  /* -------------------------------------------------------------------------- */
  /*                               Lifecycle Hooks                              */
  /* -------------------------------------------------------------------------- */
  ngAfterViewInit(): void {
    const currentLangToggle =
      (this.languageService.CurrentLanguage.value as 'he' | 'en') === 'he'
        ? 'en'
        : 'he';
    this.toggleLanguage({
      forceLang: currentLangToggle,
      updateUserPreferences: false,
    });
  }

  /* -------------------------------------------------------------------------- */
  /*                              Protected Methods                             */
  /* -------------------------------------------------------------------------- */
  protected toggleLanguage({
    forceLang,
    updateUserPreferences = false,
  }: {
    forceLang?: 'he' | 'en';
    updateUserPreferences?: boolean;
  } = {}): void {
    const currentLang = forceLang
      ? getLanguage(forceLang)
      : this.activeLanguage;
	 // const hasChanged = forceLang !== currentLang.value;
    const newLang = currentLang.value === 'en' ? 'he' : 'en';
    this.languageService.setLanguage(newLang, updateUserPreferences);
    /* -------------------------------------------------------------------------- */

    if (this.switchToggle) {
      if (newLang === 'en') {
        this.heClasses.forEach((className) =>
          this.renderer.removeClass(this.switchToggle.nativeElement, className)
        );
        this.enClasses.forEach((className) =>
          this.renderer.addClass(this.switchToggle.nativeElement, className)
        );
       // setTimeout(() => {
          this.activeLanguage = this.languageService.CurrentLanguage;
          this.languageChanged.emit(this.activeLanguage);
          this.cdr.detectChanges();
        //}, 250);
      } else if (newLang === 'he') {
        this.enClasses.forEach((className) =>
          this.renderer.removeClass(this.switchToggle.nativeElement, className)
        );
        this.heClasses.forEach((className) =>
          this.renderer.addClass(this.switchToggle.nativeElement, className)
        );
       // setTimeout(() => {
          this.activeLanguage = this.languageService.CurrentLanguage;
          this.languageChanged.emit(this.activeLanguage);
          this.cdr.detectChanges();
      //  });
      }
    }
	// if(hasChanged){
	// 	setTimeout(() => {
	// 	  window.location.reload();
	// 	}, 1000);
	// }
  }

  /* -------------------------------------------------------------------------- */
}
