import { GlobalsService } from 'src/app/Data/Services/globals.service';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderType } from './data/loader.type';

@Component({
	selector: 'doc-loader',
	standalone: true,
	imports: [CommonModule, TranslateModule],
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit, OnChanges {
	@Input() public loading: boolean;
	@Input() public type: LoaderType = LoaderType.SPINNER;
	@Input() public color: string = 'var(--dc-primary-500)';
	@Input() public message: string = 'pages.cw.loading';

	constructor() {}

	ngOnChanges(simpleChanges: SimpleChanges): void {
		if (simpleChanges.message?.currentValue) {
			this.message = GlobalsService.globalTranslate(simpleChanges.message.currentValue);
		}
	}

	ngOnInit(): void {}
}
