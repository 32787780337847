import { BehaviorSubject } from 'rxjs';
import { StorageKeys } from 'src/app/Data/Services/storage.data/storage-keys.data';
import { StorageModeType } from 'src/app/Data/Services/storage.data/storage-mode.type';
import packageJson from 'package.json';

export class AppVersionControl {
	private static _instance: AppVersionControl;
	public static get instance(): AppVersionControl {
		return this._instance || (this._instance = new this());
	}
	private constructor() {
		const version = packageJson.version;
		const frontEnd = version.split("B")[0];
		const backEnd = version.split("B")[1];
		if (frontEnd && backEnd) {
			this.setAppVersions({ frontEnd, backEnd });
		}
	}
	/* -------------------------------------------------------------------------- */

	public appVersions$: BehaviorSubject<AppVersions> = new BehaviorSubject<AppVersions>(null);

	public setAppVersions(appVersions: AppVersions): void {
		if (appVersions) {
			const { frontEnd, backEnd } = appVersions;
			if (frontEnd) {
				window.storageService.setItem(StorageKeys.AppVersionKeys.KeyFrontendVersion, frontEnd, StorageModeType.Local);
				window.storageService.setItem(StorageKeys.AppVersionKeys.KeyBackendVersion, backEnd, StorageModeType.Local);
			}
			this.appVersions$.next(appVersions);
		}
	}

	public getAppVersions(): AppVersions {
		return this.appVersions$.getValue();
	}

	public getFrontendVersion(): string {
		return this.appVersions$.getValue().frontEnd;
	}

	public getBackendVersion(): string {
		return this.appVersions$.getValue().backEnd;
	}
}

export type AppVersions = {
	frontEnd: string;
	backEnd: string;
};
