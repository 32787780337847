import {DocIconStruct} from 'src/app/modules/icon/data/icon-struct.model';
import {PermissionType} from 'src/app/Data/Entities/User/user-permission.type';

export type OnClickDcSidenavItemFN = (item: DcSidenavItemStruct) => void;

export class DcSidenavItemStruct {
  id: string;
  index: number;
  label: string;

  type?: 'category' | 'item' = 'item';

	// Category of subItems mode
	opened?: boolean = false;

	private _subItems?: DcSidenavItemStruct[] = [];
	get subItems(): DcSidenavItemStruct[] {
		return this._subItems;
	}

	set subItems(value: DcSidenavItemStruct[]) {
		this._subItems = value;
		this._subItems.forEach((item, index) => {
			item.index = index;
			item.visible = this.opened;
		});
	}

	// UI
	visible?: boolean = true;
	icon: DocIconStruct;

	// Permissions
	permissions: PermissionType[] = [];

	// Options
	mobile: boolean = false;

	//events and interactions
	routerLink: string | string[] = '/';
	onClickFn?: OnClickDcSidenavItemFN;

	constructor(init: IDcSidenavItemStruct) {
		Object.assign(this, init);
		if (this.subItems?.length > 0) {
			this.type = 'category';
		}
	}

	/* -------------------------------------------------------------------------- */
	/*                               Catgory Methods                              */
	/* -------------------------------------------------------------------------- */
	public toggleCategoryItems?: () => void = () => {
		if (this.type != 'category') return;
		this.opened = !this.opened;
		this.subItems.forEach(item => {
			item.visible = !item.visible;
		});
	};

	/* -------------------------------------------------------------------------- */
	/*                             Simple Item Methods                            */
	/* -------------------------------------------------------------------------- */
}

//IDcSidenavItemStruct is type of all properties of DcSidenavItemStruct without methods
export type IDcSidenavItemStruct = {
	id: string;
	index: number;
	label: string;
	type?: 'category' | 'item';
	opened?: boolean;
	visible?: boolean;
	subItems?: DcSidenavItemStruct[];
	icon: DocIconStruct;
	badgeValue?: number;
	permissions: PermissionType[];
	openInNewTabCtxMenu?: boolean;
	mobile: boolean;
	routerLink: string | string[];
	onClickFn?: OnClickDcSidenavItemFN;
};
