<doc-loader [loading]="loaderVisible" [message]="message">
  <div class="table-outer flex flex-col">
    <section class="table-header-section flex">
      <section class="table-title-section">
        <h4 table-title>{{ config.tableTitle | translate }}</h4>
      </section>
    </section>

    <section class="table-actions-section">
      <table-actions
        *ngIf="dataSource"
        [lazyMode]="lazyMode"
        [disable]="loading$ | async"
        [initSearchValue]="searchText"
        [initDateRange]="dateFilterRange"
        [searchLabel]="config?.searchLabel"
        [customTableActions]="config?.tableActions"
        [enableSearch]="config?.enableSearch"
        [enableDateRange]="config?.enableDateRangeFiltering"
        [enableVisibleColumnsSelection]="config?.enableTableColumnsShowHide"
        [dataColumns]="dataColumns"
        [enablePresets]="config?.enableTablePresets"
        [actualTablePreset]="actualTablePreset"
        [selectedRows]="checkedRowsDataStruct"
        (onApplyPreset)="onApplyPreset($event)"
        (searchFilterChange)="onSearchFilterChange($event)"
        (visibleColumnChangeEvent)="onVisibleColumnsChanged($event)"
        (onColumnsOrderChange)="onColumnsOrderChange($event)"
      ></table-actions>
    </section>

    <section class="table-container">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        [matSortDisabled]="lazyMode"
      >
        <!-- ----------------------------------------------------------------------- -->
        <!--                               Table Cells                               -->
        <!-- ----------------------------------------------------------------------- -->
        <!-- ----------------------------------------------------------------------- -->
        <ng-container *ngFor="let col of columns; let i = index">
          <ng-container *ngIf="col.columnKey">
            <ng-container
              matColumnDef="{{ col.columnKey }}"
              [sticky]="!isMobile && col.pinned"
            >
              <!-- ----------------------------- Header Cell ----------------------------- -->
              <th
                mat-header-cell
                *matHeaderCellDef
                [class]="col?.classString?.headerCell || ''"
                [class.rowIdColumn]="col.isIdColumn"
                [class.invisible]="col.state?.isIdColumn"
                mat-sort-header
                [disabled]="!col?.enableSort || col?.state?.isLayoutColumn"
              >
                <ng-container [ngSwitch]="col.columnKey">
                  <ng-container *ngSwitchCase="'checkboxes'">
                    <mat-checkbox
                      #allCheckbox
                      class="scale08 hide_ripple"
                      id="selectAllCheckboxes"
                      color="primary"
                      [ngModel]="allPageChecked()"
                      (ngModelChange)="
                        togglePageChecked(
                          pagingConfig.currentPage,
                          allCheckbox.checked
                        )
                      "
                    />
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <div
                      class="flex w-full items-center gap-2"
                      dcHover
                      (onHover)="hoveredSortColumn = col.objectKey"
                      (onLeave)="hoveredSortColumn = null"
                    >
                      <h5>
                        {{ col.title | translate }}
                      </h5>
                      <ng-container
                        *ngIf="
                          sortConfig &&
                          sortConfig.defaultObjectKey as defaultObjectKey
                        "
                      >
                        <button
                          *ngIf="sortConfig.objectKeys.includes(col.objectKey)"
                          [matTooltip]="
                            defaultObjectKey == col.objectKey
                              ? ('dccomp.dc-table.sort.' +
                                  sortConfig['direction'] | translate)
                              : ('dccomp.dc-table.sort.none' | translate)
                          "
                          (click)="toggleSortDirection(col)"
                          [ngStyle]="{
                            transform:
                              defaultObjectKey == col.objectKey
                                ? sortConfig['direction'] == 'desc'
                                  ? 'rotate(180deg)'
                                  : 'rotate(0deg)'
                                : 'rotate(0deg)',
                            transition: 'transform 0.2s ease-in-out'
                          }"
                        >
                          <DocIcon
                            [class]="
                              hoveredSortColumn == col.objectKey ||
                              defaultObjectKey == col.objectKey
                                ? 'opacity-100'
                                : 'opacity-0'
                            "
                            name="arrow-up"
                            type="duotone"
                            primaryColor="var(--dc-info)"
                            [primaryOpacity]="1"
                            secondaryColor="var(--dc-info)"
                            [secondaryOpacity]="1"
                          />
                        </button>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'actions'">
                    <ng-container *ngIf="config?.enableGlobalTableMenu">
                      <button
                        matTooltip="{{
                          'dccomp.cycle-tables.global-menu.label' | translate
                        }}"
                        [disabled]="dataSource?.filteredData?.length == 0"
                        [class.cursor-not-allowed]="
                          dataSource?.filteredData?.length == 0
                        "
                        #globalMenuBtn
                        [matMenuTriggerFor]="globalTableMenu.matMenu"
                        [matBadge]="checkedRowsCount"
                        [matBadgeHidden]="
                          true || config?.enableCheckboxes != true
                        "
                        matBadgeColor="warn"
                        matBadgePosition="before"
                        matBadgeSize="small"
                      >
                        <i class="fak fa-light-table-gear fa-xl"></i>
                      </button>
                      <global-table-menu
                        #globalTableMenu
                        [tableTitle]="config?.tableTitle | translate"
                        [customTableActions]="config?.tableActions"
                        [dataColumns]="dataColumns"
                        (exportTo)="onExportTo($event)"
                        (resetChecked)="resetCheckedRows($event)"
                      ></global-table-menu>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </th>
              <!-- ------------------------------ Body Cell ------------------------------ -->
              <td
                mat-cell
                #bodyCellRef
                *matCellDef="let row; let rowIndex = index"
                [class.rowIdColumn]="col?.isIdColumn"
                [class]="col?.classString?.bodyCell || ''"
              >
                <ng-container *ngIf="loading$ | async; else ActualBodyCell">
                  <div
                    [style.height.px]="bodyCellRef?.clientHeight * 0.4"
                    [SkeletonLoader]="{ show: true, bg: 'gray' }"
                  ></div>
                </ng-container>
                <ng-template #ActualBodyCell>
                  <ng-container [ngSwitch]="col.columnKey">
                    <!-- ------------------------------ Checkbox Cell ----------------------------- -->
                    <ng-container *ngSwitchCase="'checkboxes'">
                      <mat-checkbox
                        #cellCheckbox
                        class="scale08 hide_ripple"
                        [disableRipple]="true"
                        [checked]="isRowChecked(row, rowIndex)"
                        (click)="$event.stopPropagation()"
                        color="primary"
                        (change)="toggleRowChecked(row)"
                      />
                    </ng-container>
                    <!-- ----------------------------- Row Actions ----------------------------- -->
                    <ng-container *ngSwitchCase="'actions'">
                      <div
                        class="flex aspect-square w-8 flex-row items-center justify-center rounded-full hover:bg-gray-100"
                        matTooltip="{{
                          'dccomp.cycle-tables.row-menu.label' | translate
                        }}"
                      >
                        <button
                          #menuBtn
                          mat-icon-button
                          (click)="
                            loading$.getValue() == false &&
                              openRowMenu(
                                row,
                                rowIndex,
                                matRowContextTriggerMenu,
                                $event
                              )
                          "
                          class="h-full w-full p-0"
                        >
                          <i class="fa-regular fa-ellipsis-vertical fa-xl"></i>
                        </button>
                      </div>
                    </ng-container>
                    <!-- -------------- Simple Cells (textual or custom template) -------------- -->
                    <ng-container *ngSwitchDefault>
                      <ng-container
                        *ngIf="loading$ | async; else TableCellData"
                      ></ng-container>
                      <ng-template #TableCellData>
                        <!-- Textual -->
                        <ng-container *ngIf="col?.customTemplate != true">
                          {{ row[col.columnKey] }}
                        </ng-container>
                        <!-- Dynamic Column data -->
                        <ng-container
                          *ngIf="
                            tableRows.has(row[rowsId]) && col.isDynamicColumn
                          "
                        >
                          {{ getCustomRowData(row, col) }}
                        </ng-container>
                        <!-- Custom Template -->
                        <ng-container *ngIf="col?.customTemplate == true">
                          <ng-container
                            *ngTemplateOutlet="
                              cellTemplateCollector.getCellTemplate(
                                col.columnKey
                              );
                              context: {
                                $implicit: getCustomRowData(row, col),
                                row: row[col.columnKey],
                                cell: row[col.columnKey],
                                rowIndex: rowIndex,
                                column: col,
                                getter: col?.getter
                              }
                            "
                          ></ng-container>
                        </ng-container>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </td>
              <!-- ------------------------- Empty Table Results Cell ------------------------- -->
              <td
                mat-footer-cell
                *matFooterCellDef
                [class.rowIdColumn]="col.isIdColumn"
                [attr.colspan]="columns?.length || 1"
                [class.hidden]="i != 1"
              >
                <empty-table-results
                  [showClearButton]="dataSource.data.length > 0"
                  (clear)="onClearFilters()"
                />
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- ----------------------------------------------------------------------- -->
        <!--                               Table Rows                                -->
        <!-- ----------------------------------------------------------------------- -->
        <!-- ----------------------------- Header Row ------------------------------ -->
        <tr
          mat-header-row
          class="table-header-row"
          *matHeaderRowDef="visibleColumnKeys"
        ></tr>
        <!-- ------------------------------ Body Row ------------------------------- -->
        <tr
          mat-row
          *matRowDef="let row; columns: visibleColumnKeys; let rowIndex = index"
          class="table-body-row"
          [id]="getRowId(row, rowIndex)"
          [attr.data-checked]="false"
          [class.zebra-row-even]="rowIndex % 2 == 0"
          [class.selected-row]="activeRow?.index == rowIndex"
          (click)="loading$.getValue() == false && onRowClick(row, rowIndex)"
          (dblclick)="onRowDoubleClick(row, rowIndex)"
          (contextmenu)="
            checkedRowsCount <= 1 &&
              loading$.getValue() == false &&
              openRowMenu(row, rowIndex, matRowContextTriggerMenu, $event)
          "
        ></tr>
        <!-- ------------------------- Empty Table Results Row ------------------------- -->
        <tr
          [class.collapse]="dataSource?.filteredData?.length > 0"
          mat-footer-row
          *matFooterRowDef="visibleColumnKeys"
          [attr.rowspan]="columns?.length || 1"
          class="bg-gray-100"
        ></tr>
      </table>
    </section>

    <!-- ----------------------------------------------------------------------- -->
    <!--                             Table Paginator                             -->
    <!-- ----------------------------------------------------------------------- -->
    <section class="table-paginator-container">
      <table-paginator
        [lazyMode]="lazyMode"
        [pagingConfig]="pagingConfig"
        [tableItemName]="config?.tableItemName"
        [disable]="loading$ | async"
        (pageChange)="onPageChange($event)"
        (matPaginatorInit)="onPaginatorInit($event)"
      ></table-paginator>
    </section>
  </div>
</doc-loader>

<!-- ----------------------------------------------------------------------- -->
<!--                                Overlays                                 -->
<!-- ----------------------------------------------------------------------- -->
<!-- -------------------- Row Menu Template and Trigger -------------------- -->
<div
  style="visibility: hidden; position: fixed"
  [style.left]="menuPosition.x"
  [style.top]="menuPosition.y"
  #matRowContextTriggerMenu="matMenuTrigger"
  [matMenuTriggerFor]="rowContextMenu.matMenu"
></div>

<table-row-menu
  #rowContextMenu
  [menuTrigger]="matRowContextTriggerMenu"
  [activeRow]="activeRow"
  [actions]="config?.rowActions"
></table-row-menu>
<!-- ----------------------------------------------------------------------- -->
