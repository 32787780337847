import { ComponentRef, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewContainerRef } from '@angular/core';
import { DocIconStruct } from 'src/app/modules/icon/data/icon-struct.model';
import { DocIconComponent } from 'src/app/modules/icon/icon.component';

/**
 * @description: this directive is used to add loader to any element
 * @example:
 * <span [dcLoader]="true"
 *       [loaderIcon]="{
 *                        name: 'compass',
 *                        primaryColor: 'red',
 *                        secondaryColor: 'green',
 *                        secondaryOpacity: 0.3
 *                     }"
 * ></span>
 */
@Directive({
	selector: '[dcLoader]',
	standalone: true,
})
export class LoaderDirective implements OnChanges {
	@Input('dcLoader') dcLoader: boolean;
	/** @note: you can use any font awesome icon as loader (only icon name) */
	@Input() loaderIcon: { [key in keyof DocIconStruct]?: DocIconStruct[key] };

	private loaderIconComponent: ComponentRef<DocIconComponent>;
  private storedElementStyles = {
		cursor: '',
		pointerEvents: '',
	};
	private readonly defaultLoaderIcon: DocIconStruct = {
		name: 'spinner',
		size: undefined,
		type: 'duotone',
		primaryColor: 'primary',
		secondaryColor: 'primary',
		secondaryOpacity: 0.5,
	};

	@Input() loaderSize: 0.5 | 0.75 | 0.9 | 1 = 0.5;

	/* -------------------------------------------------------------------------- */
	/*                                 Constructor                                */
	/* -------------------------------------------------------------------------- */
	constructor(private el: ElementRef, private renderer: Renderer2, private viewContainerRef: ViewContainerRef) {}

	/* -------------------------------------------------------------------------- */
	/*                               LifeCycle Hooks                              */
	/* -------------------------------------------------------------------------- */
	ngOnChanges(changes: SimpleChanges): void {
		if (!this.el.nativeElement) return;
		if (changes.dcLoader && changes.dcLoader.previousValue != changes.dcLoader.currentValue) {
			if (changes.dcLoader.currentValue) this.addLoader();
			else this.removeLoader();
		}
	}

	private addLoader(): void {
		if (this.loaderIconComponent) return;

		let height = this.el.nativeElement.offsetHeight;
		height = height * this.loaderSize;

		// Loader element
		const loaderIcon = this.viewContainerRef.createComponent(DocIconComponent);
		const loaderIconStruct = Object.assign({}, this.defaultLoaderIcon, this.loaderIcon);

		loaderIcon.setInput('icon', loaderIconStruct);
		loaderIcon.setInput('class', `h-[${height}px] animate-spin w-auto`);

		this.renderer.setStyle(loaderIcon.location.nativeElement, 'position', 'absolute');
		this.renderer.setStyle(loaderIcon.location.nativeElement, 'z-index', '10');
		this.renderer.setStyle(loaderIcon.location.nativeElement, 'width', '100%');
		this.renderer.setStyle(loaderIcon.location.nativeElement, 'height', '100%');
		this.renderer.setStyle(loaderIcon.location.nativeElement, 'background-color', 'white');
		this.renderer.setStyle(loaderIcon.location.nativeElement, 'opacity', '0.7');
		this.renderer.setStyle(loaderIcon.location.nativeElement, 'cursor', 'not-allowed');
		this.renderer.setStyle(loaderIcon.location.nativeElement, 'pointer-events', 'none');

		// Element
		this.storedElementStyles.cursor = this.el.nativeElement.style.cursor;
		this.renderer.setStyle(this.el.nativeElement, 'cursor', 'not-allowed');
		this.storedElementStyles.pointerEvents = this.el.nativeElement.style.pointerEvents;
		this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');

		this.loaderIconComponent = loaderIcon;
		this.renderer.appendChild(this.el.nativeElement, this.loaderIconComponent.location.nativeElement);
	}

	private removeLoader(): void {
		if (!this.loaderIconComponent) return;
		this.renderer.removeChild(this.el.nativeElement, this.loaderIconComponent.location.nativeElement);
		this.loaderIconComponent.destroy();
		this.loaderIconComponent = null;

		this.renderer.setStyle(this.el.nativeElement, 'cursor', this.storedElementStyles.cursor);
		this.renderer.setStyle(this.el.nativeElement, 'pointerEvents', this.storedElementStyles.pointerEvents);
	}
}
