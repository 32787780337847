import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { MessageTemplateModel } from 'src/app/Data/Entities/tenant/tenant.model';
import { TenantsService } from 'src/app/Data/Services/tenants.service';

@Component({
  selector: 'templates-and-messages',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    FormsModule,
  ],
  templateUrl: './templates-and-messages.component.html',
  styleUrls: ['./templates-and-messages.component.scss'],
})
export class TemplatesAndMessagesComponent implements OnInit {
  protected HTML_template: MessageTemplateModel[] = [];
  protected SMS_template: MessageTemplateModel[] = [];

  public templatesAndMessagesFG: FormGroup = new FormGroup({
    smsAccountName: new FormControl(''),
    selectedTypeHTML: new FormControl(''),
    selectedLangHTML: new FormControl(''),
    textareaValueHTML: new FormControl(''),
    selectedTypeSMS: new FormControl(''),
    selectedLangSMS: new FormControl(''),
    textareaValueSMS: new FormControl(''),
  });
  constructor(private tenantsService: TenantsService) {}
  ngOnInit(): void {
    this.tenantsService.currentTenantSettings.subscribe((res) => {
      if (res) {
        this.templatesAndMessagesFG.patchValue({
          smsSenderName: res.smsAccountName,
        });
        this.HTML_template = res.htmlTemplates;
        this.SMS_template = res.templates;
        this.templatesAndMessagesFG.patchValue({
          selectedTypeHTML: this.getUniqueTypesHTML()[0],
          selectedLangHTML: res.defaultLanguage,
          selectedTypeSMS:
            this.getUniqueTypesSMS().length > 0
              ? this.getUniqueTypesSMS()[0]
              : '',
          selectedLangSMS: res.defaultLanguage,
        });
        this.templatesAndMessagesFG.get('selectedTypeHTML').disable();
        this.selectTemplateHTML();

        this.selectTemplateSMS();
      }
    });
  }
  /* -------------------------------------------------------------------------- */
  /*                                HTML template                               */
  /* -------------------------------------------------------------------------- */

  public getFormData(): any {
    return this.templatesAndMessagesFG.value;
  }

  selectTemplateHTML() {
    let selectedTemplate = this.HTML_template.find(
      (t) =>
        t.language ===
          this.templatesAndMessagesFG.get('selectedLangHTML').value &&
        t.type === this.templatesAndMessagesFG.get('selectedTypeHTML').value
    );
    if (selectedTemplate) {
      this.templatesAndMessagesFG.patchValue({
        textareaValueHTML: selectedTemplate.text,
      });
    }
  }

  getUniqueTypesHTML() {
    return [...new Set(this.HTML_template.map((item) => item.type))];
  }

  getUniqueLangsHTML() {
    return [...new Set(this.HTML_template.map((item) => item.language))];
  }

  /* -------------------------------------------------------------------------- */
  /*                                SMS template                                */
  /* -------------------------------------------------------------------------- */

  selectTemplateSMS() {
    let selectedTemplate = this.SMS_template.find(
      (t) =>
        t.language ===
          this.templatesAndMessagesFG.get('selectedLangSMS').value &&
        t.type === this.templatesAndMessagesFG.get('selectedTypeSMS').value
    );
    if (selectedTemplate) {
      this.templatesAndMessagesFG.patchValue({
        textareaValueSMS: selectedTemplate.text,
      });
    }
  }

  getUniqueTypesSMS() {
    if (!this.SMS_template) return [];
    return [...new Set(this.SMS_template.map((item) => item.type))];
  }

  getUniqueLangsSMS() {
    return [...new Set(this.SMS_template.map((item) => item.language))];
  }
}
