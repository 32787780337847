import { Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { ColumnStruct, TableEntryStruct } from '../data/types/table.data';
import { QueryTableParamsStruct } from '../data/types/table-query-params-struct.type';
import { TableComponent } from '../table/table.component';

@Inject({
	providedIn: 'root',
})
export class TableConnector<T extends TableEntryStruct> {
	/* -------------------------------------------------------------------------- */
	private static _instance: TableConnector<any>;

	public static get instance(): TableConnector<any> {
		return this._instance || (this._instance = new this());
	}

	private constructor() {}
	
	private tableComponent: TableComponent<T>;
	public init(tableComponent: TableComponent<T>): void {
		this.tableComponent = tableComponent;
	}

	/* -------------------------------------------------------------------------- */
	/*                              Set Table Loading                             */
	/* -------------------------------------------------------------------------- */
	public setTableLoading(loading: boolean): void {
		if (!this.tableComponent) throw new Error('Table component is not initialized');
		this.tableComponent.loading$.next(loading);
	}

	/* -------------------------------------------------------------------------- */
	/*                                 Refresh Table                              */
	/* -------------------------------------------------------------------------- */
	$refreshTable$: Subject<void> = new Subject<void>();
	public refreshTable(): void {
		this.$refreshTable$.next();
	}

	/* -------------------------------------------------------------------------- */
	/*                              Get Query Params                              */
	/* -------------------------------------------------------------------------- */
	public getQueryParams(): QueryTableParamsStruct {
		if (!this.tableComponent) throw new Error('Table component is not initialized');
		return {
			searchText: this.tableComponent.searchText,
			dateFilterRange: this.tableComponent.dateFilterRange,
			sortConfig: this.tableComponent.sortConfig,
		};
	}

	/* -------------------------------------------------------------------------- */
	/*                               Get All Columns                              */
	/* -------------------------------------------------------------------------- */
	public getAllColumns(): ColumnStruct<T>[] {
		if (!this.tableComponent) throw new Error('Table component is not initialized');
		return this.tableComponent.columnsData;
	}

	/* -------------------------------------------------------------------------- */
	/*                           Get selected rows IDs                            */
	/* -------------------------------------------------------------------------- */
	public getSelectedRowsIDs(): any[] {
		if (!this.tableComponent) throw new Error('Table component is not initialized');
		return this.tableComponent.checkedRowIDs;
	}

	/* -------------------------------------------------------------------------- */
	/*                             Reset Selected Rows                            */
	/* -------------------------------------------------------------------------- */
	public resetSelectedRows(): void {
		if (!this.tableComponent) throw new Error('Table component is not initialized');
		this.tableComponent.resetCheckedRows('all');
	}
}
