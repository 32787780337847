import { Component, Injector } from '@angular/core';
import { AuthService } from 'src/app/Data/Services/auth.service';
import { DeviceService } from './Data/Services/device/device-service.service';
import './Extensions/index';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export let AppInjector: Injector;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  title = 'DocServer';

  constructor(private auth:AuthService,
    injector: Injector,
    protected deviceService: DeviceService,
    private translate: TranslateService,
    ){
      AppInjector = injector;

      translate.setDefaultLang('he');
      translate.use('he');
    }

  get IsAuth(): boolean{
    return AuthService.isAuthenticated;
  }
}
