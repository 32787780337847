import { TableObjectKey } from "../../table/data/types/table.types";
import { Observable, of } from "rxjs";
import { ReportsTableStruct } from "./reports-table-struct";
import moment from "moment";
import { AppInjector } from "src/app/app.component";
import { TranslateService } from "@ngx-translate/core";

export function reportTableColumnDataRetriever(): Record<TableObjectKey, (report: ReportsTableStruct) => Observable<any>> {
    return {
        fileName: (row: ReportsTableStruct) => of(row.fileName),
        recipientCellData: (row: ReportsTableStruct) => {
            const translateService = AppInjector.get(TranslateService);
            const recipientCellData = row.recipientCellData.map((recipient) => {
                return `${recipient.friendlyName}, ${translateService.instant(recipient.userStatus)}`
            });
            return of(recipientCellData);
        },
        docStatus: (row: ReportsTableStruct) => {
            const translateService = AppInjector.get(TranslateService);
            return of(translateService.instant(row.docStatus));
        },
        CreateDate: (row: ReportsTableStruct) => of(moment(row.CreateDate).format('DD/MM/YYYY HH:mm:ss')),
        LastUpdate: (row: ReportsTableStruct) => of(moment(row.LastUpdate).format('DD/MM/YYYY HH:mm:ss')),
        userStatus: (row: ReportsTableStruct) => of(row.userStatus),
    }
}