
import { IMetaDatasDTO } from '../../Services/DTO/document.DTO';
import { UserModel } from '../User/user.model'

export class DocumentModel {
  public id: string;
  public name: string;
  public file: string;
  public user: string;
  public enabled: boolean;
  public status: DocumentStatus;
  public recipient: UserModel;
  public recipients?: DocumentUserModel[];
  public metadatas?: IMetaDatasDTO[];
  public message: string;
  public log?: LogModel[];
  public preferences?: {};
  public get lastLog() : LogModel { return this.log[this.log.length-1]; };
}

export class DocumentUserModel {
  public user: UserModel;
  public type: string;
  public status: DocUserStatus;
  public timestamp: string;
}

export class LogModel {
  public timestamp: string;
  public user: UserModel;
  public action: string;
  public description: string;
}

export enum DocUserStatus {
  None = 'None',
  Sent = 'Sent',
  Accessed = 'Accessed',
  Error = 'Error'
}

export enum DocumentStatus {
  Draft = 'Draft',
  Sent = 'Sent',
  Accessed = 'Accessed',
  Error = 'Error'
}
