import {AuthService} from 'src/app/Data/Services/auth.service';
import { UsersService } from 'src/app/Data/Services/users.service';

export class SideNavControl {
	private static _instance: SideNavControl;

	static get instance(): SideNavControl {
		if (!SideNavControl._instance) {
			SideNavControl._instance = new SideNavControl();
		}

		return SideNavControl._instance;
	}

	private constructor() {
		UsersService.currentUser$.subscribe(user => {
			this.reset();
		});
	}
	/* -------------------------------------------------------------------------- */
	private state: 'opened' | 'closed' = window.deviceService.isMobile ? 'closed' : 'opened';

	public isOpened() {
		return this.state === 'opened';
	}

	public open() {
		this.state = 'opened';
	}

	public close() {
		this.state = 'closed';
	}

	public toggle() {
		this.isOpened() ? this.close() : this.open();
	}
	/* -------------------------------------------------------------------------- */
	/*                               Private Methods                              */
	/* -------------------------------------------------------------------------- */

	private reset() {
		window.deviceService.isMobile ? this.close() : this.open();
	}
}
