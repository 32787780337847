<ng-container *ngIf="!asstes; else assetsIcon">
	<span
		#DocIcon
		class="dc-icon flex h-full w-full items-center justify-center"
	>
		<i
			[ngClass]="iconClass"
			[ngStyle]="iconStyles"
		></i>
	</span>
</ng-container>

<ng-template #assetsIcon>
	<mat-icon
		*ngIf="svgLoaded"
		[svgIcon]="activeSvg$ | async"
	></mat-icon>
</ng-template>
