<!-- Open Menu Button (float): when the sidenav minimized -->
<span
	class="absolute top-1/2 z-10"
	dc-dir
	rtlClass="right-0"
	ltrClass="left-0"
	[class.hidden]="sideNavCtrl.isOpened()"
	cdkDrag
	#dragHideSidenav="cdkDrag"
	[cdkDragLockAxis]="'y'"
	(cdkDragEnded)="onDragOpenSidenavBtnEnded($event)"
>
	<button
    id="sidenavToggleButton"
    mat-fab
    class="my-1 w-8 bg-gray-100 bg-opacity-70 text-primary-500 hover:bg-gray-300"
    dc-dir
    rtlClass="rounded-l-full"
    ltrClass="rounded-r-full"
    (pointerup)="preventMouseOnDrag($event, dragHideSidenav)"
    [matTooltip]="'dccomp.sidenav.modes.expand' | translate"
    [matTooltipDisabled]="sideNavCtrl.isOpened()"
    [matTooltipClass]="['font-bold', 'text-lg', 'select-none']"
    [style.transform]="floatSidenavOpenBtnTransform"
	>
		<DocIcon
			[rtlIcon]="!sideNavCtrl.isOpened() ? 'chevron-left' : 'chevron-right'"
			[ltrIcon]="!sideNavCtrl.isOpened() ? 'chevron-right' : 'chevron-left'"
			type="duotone"
			size="2xl"
			primaryColor="var(--dc-primary)"
			secondaryColor="var(--dc-info)"
			[secondaryOpacity]="0.8"
		/>
	</button>
</span>

<!-- ----------------------------------------------------------------------- -->
<!--                             MAIN APP DRAWER                             -->
<!-- ----------------------------------------------------------------------- -->
<mat-drawer-container
	class="sidenav-container"
	[autosize]="true"
	[dir]="languageService.CurrentDir"
	[hasBackdrop]="deviceService.isMobile"
	(backdropClick)="sideNavCtrl.close()"
>
	<mat-drawer
		#drawer
		class="dc-sidenav"
		[mode]="deviceService.isMobile ? 'over' : 'side'"
		IsMobile
		[opened]="sideNavCtrl.isOpened()"
	>
		<section class="sidenav_content top-section">
			<!-- ------------------------ Sidenav Toggle Button ------------------------ -->
			<div class="flex w-full items-center justify-between gap-1">
				<!-- Company Logo -->
				<img
					*ngIf="companyLogo"
					[src]="companyLogo"
					class="h-8 rounded-full max-h-10 w-auto max-w-[85%] sm:h-10"
					alt="Company Logo"
				/>

				<span class="header_text">
					{{ AuthService.currentCompany }}
				</span>
				<button
					mat-button
					class="my-1 w-1/4 rounded-md bg-gray-100 bg-opacity-70 text-primary-500 hover:bg-gray-300"
					(click)="sideNavCtrl.close()"
					[matTooltip]="'dccomp.sidenav.modes.hide' | translate"
					#sideNavViewModeTooltip="matTooltip"
					[matTooltipDisabled]="deviceService.isMobile"
					[matTooltipClass]="['font-bold', 'text-lg', 'select-none']"
				>
					<DocIcon
						name="times"
						type="duotone"
						size="xl"
						primaryColor="var(--dc-primary)"
						secondaryColor="var(--dc-info)"
						[secondaryOpacity]="0.8"
					/>
				</button>
			</div>

			<mat-divider />

			<!-- ------------------------------- Menu -------------------------------- -->

			<!--  Catergories: Top Items -->
			<mat-list class="h-full py-0">
				<ng-container *ngFor="let item of sidenavTopItems">
					<ng-container
            [ngTemplateOutlet]="menuCatItemTemplate"
            [ngTemplateOutletContext]="{item: item}"
					></ng-container>
				</ng-container>
				<mat-divider />
			</mat-list>
		</section>

		<section class="bottom-section mb-1">
			<!--  Catergories: Bottom Items -->
			<!-- <mat-list class="py-0">
				<ng-container *ngFor="let item of sidenavBottomItems">
					<ng-container
            [ngTemplateOutlet]="menuCatItemTemplate"
            [ngTemplateOutletContext]="{item: item, withoutCategory: true}"
					></ng-container>
				</ng-container>
				<mat-divider />
			</mat-list> -->

			<!-- DocServer logo and versions -->
			<section class="flex flex-row items-center justify-between">
				<span
					#dcLogoAndTitle
					class="flex select-none flex-row items-center gap-1"
				>
					<span
						class="header_logo"
						(click)="sideNavCtrl.toggle()"
					>
						<img
              [ngSrc]="'assets/icons/dc-logo-high-res.svg'"
              height="30"
              width="30"
              alt=""
						/>
					</span>
					<span class="header_text"> DocServer </span>
				</span>


				<ng-container *ngIf="sideNavCtrl.isOpened() && AppVersionControl.appVersions$ | async as appVersions">
					<button
						#vaersionInfoTooltip="matTooltip"
						[matTooltip]="'Frontend: ' + appVersions?.frontEnd + '\nBackend: ' + appVersions?.backEnd"
						[matTooltipClass]="['font-bold', 'whitespace-pre-line', 'text-lg', 'select-none']"
						(click)="vaersionInfoTooltip.show()"
					>
						<DocIcon
							name="circle-info"
							type="duotone"
							color="regular"
							size="lg"
						/>
					</button>
				</ng-container>
			</section>
			<mat-divider class="mb-1" />
			<!-- ----------------------- User Profile and Avatar ----------------------- -->
			<button
        class="flex h-10 w-full items-center justify-center gap-1 rounded bg-gray-200 text-primary-500 hover:bg-gray-300 sm:h-16"
        cdkOverlayOrigin
        #triggerUserProfileCard="cdkOverlayOrigin"
        dcHover
        (onHover)="userProfileOpened = true"
			>
				<!-- User Avatar -->
        <div class="w-8 h-8 grid items-center border-solid border-2 border-gray-400  rounded-full sm:w-12 sm:h-12 ">
          <h3>{{initials(UsersService.currentUser?.fullname)}}</h3>
        </div>


				<!-- User Name -->
				<span class="font-bold">
					{{ UsersService.currentUser?.displayedDetails?.shortTitle(2) }}
				</span>
			</button>
		</section>
	</mat-drawer>

	<mat-drawer-content class="mat-drawer-content">
		<main class="APP-CONTENT">
			<div class="APP-CONTENT-WRAPPER">
				<router-outlet></router-outlet>
			</div>
		</main>
	</mat-drawer-content>
</mat-drawer-container>

<!-- ----------------------------------------------------------------------- -->
<!--                           Additional Sections                           -->
<!-- ----------------------------------------------------------------------- -->
<!-- ---------------- Menu Item Template (category or item) ---------------- -->
<ng-template
	#menuCatItemTemplate
	let-item="item"
	let-withoutCategory="withoutCategory"
>
	<ng-container *ngIf="item">
    <ng-container *ngIf="item['subItems']?.length > 0; else SubItemTemplate">
      <mat-divider/>
      <dc-sidenav-item
        *ngIf="withoutCategory != true"
        [item]="item"
      />
    </ng-container>
		<ng-template #SubItemTemplate>
			<dc-sidenav-item
				*ngIf="item.visible || withoutCategory"
				[item]="item"
				class="px-2"
			/>
		</ng-template>
	</ng-container>
</ng-template>

<!-- ------------------ User Profile Hover Card Component ------------------ -->
<ng-template
	cdkConnectedOverlay
	#userProfileCard="cdkConnectedOverlay"
	[cdkConnectedOverlayOrigin]="triggerUserProfileCard"
	[cdkConnectedOverlayOpen]="userProfileOpened"
	[cdkConnectedOverlayHasBackdrop]="false"
	[cdkConnectedOverlayGrowAfterOpen]="true"
	[cdkConnectedOverlayPositions]="userProfileCardPositions"
	(overlayOutsideClick)="userProfileOpened = false"
>
	<dc-user-profile-card
		dcHover
		(onHover)="userProfileOpened = true"
		[leaveDelay]="500"
		(onLeave)="userProfileOpened = false"
	/>
</ng-template>

<!-- ------------------------------- Search -------------------------------- -->
<ng-template #search>
	<mat-list-item class="dc-sidenav-item flex-row gap-1">
		<button
			#search
			class="flex-1"
		>
			<span class="h-8 w-8">
				<DocIcon
					name="search"
					type="duotone"
					color="regular"
					size="lg"
				/>
			</span>
		</button>
		<mat-form-field
			class="search_field dense-min disable-mat-subscript flex-auto"
			appearance="outline"
		>
			<mat-label>{{ 'struct.dc-table.search.label' | translate }}</mat-label>
			<input
				matInput
				[formControl]="searchFC"
			/>
		</mat-form-field>
	</mat-list-item>
	<mat-divider />
</ng-template>
