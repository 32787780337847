export const ColorType = {
	Primary: '--primary',
	PrimaryLightn: '--primary-lighten',
	Secondary: '--secondary',
	SecondaryLighten: '--secondary-lighten',
	Accent: '--accent',
	AccentLighten: '--accent-lighten',
	Error: '--error',
	ErrorLighten: '--error-lighten',
	Info: '--info',
	InfoLighten: '--info-lighten',
	Success: '--success',
	SuccessLighten: '--success-lighten',
	Warn: '--warn',
	WarnLighten: '--warn-lighten',
} as const;

// export type of ColorType keys including the lowercase version of the keys and all values:
export type ColorType = keyof typeof ColorType | Lowercase<keyof typeof ColorType> | (typeof ColorType)[keyof typeof ColorType];

export type ColorKeyType = Lowercase<keyof typeof ColorType>;

export enum ColorOpacityType {
	Op500 = '-500',
	Op400 = '-400',
	Op300 = '-300',
	Op200 = '-200',
	Op100 = '-100',
}

export function getColorClass(color: ColorType, element: 'bg' | 'border' | string): string {
	const key = getColorType(color).toLowerCase();
	return `${element}-${key}`;
}

export function getColor(color: ColorType): string {
	const key = Object.keys(ColorType).find(key => key.toLowerCase() === color.toLowerCase());
	if (!key) {
		return `var(${color})`;
	}
	return `var(${ColorType[key as keyof typeof ColorType]})`;
}

export function getColorType(color: ColorType): ColorKeyType {
	const key = Object.entries(ColorType).find(([key, value]) => key.toLowerCase() === color.toLowerCase() || value === color);
	if (key) {
		return key[0].toLowerCase() as ColorKeyType;
	}
	return undefined;
}

export function getDynamicColorClass(twPrefix: string, color: ColorType): string {
	const key = getColorType(color) + '-500';
	return `${twPrefix}-${key}`;
}

export function getColorAndLighten(color: ColorType): { color: string; lighten: string } {
	const key = getColorType(color);
	const lightenKey = (key + 'lighten') as ColorType;
	return {
		color: getColor(color),
		lighten: getColor(lightenKey),
	};
}

// example of binding getColor to a component:
//	protected dcColor: (color: DcColorType) => string = (color: DcColorType) => getColor(color);

/* -------------------------------------------------------------------------- */
// export enum MatButtonType {
// 	Raised = 'mat-mdc-raised-button',
// 	Stroked = 'mat-mdc-outlined-button',
// 	Flat = 'mat-flat-button',
// 	Icon = 'mat-icon-button',
// 	Fab = 'mat-fab',
// 	MiniFab = 'mat-mini-fab',
// }
