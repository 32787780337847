declare var require: any;

export enum ExportToType {
	CSV = 'csv',
	XLSX = 'xlsx',
	JSON = 'json',
}

export type CsvToOptions = {
	fileName: string;
	delimiter?: string; // default: ','
	keysAndTitles?: { key: string; title: string }[];
};

export type ExcelOptions = {
	fileName: string;
	sheetName?: string;
	type?: 'base64' | 'buffer' | 'binary' | 'file' | 'array' | 'string'; // Optional: Output data encoding
	keysAndTitles?: { key: string; title: string }[];
};

export class ExporterTool {
	public static exporter(data: any[], type: ExportToType, options: CsvToOptions | ExcelOptions) {
		if (options.keysAndTitles) {
			data = data.map((row: any) => {
				const newRow: any = {};
				options.keysAndTitles.forEach((keyAndTitle: { key: string; title: string }) => {
          const translatedTitle = keyAndTitle.title;
          newRow[translatedTitle] = row[keyAndTitle.key];
        });
				return newRow;
			});
		}
		switch (type) {
			case ExportToType.CSV:
				return this.exportToCsv(data, options as CsvToOptions);
			case ExportToType.XLSX:
				return this.exportToExcel(data, options as ExcelOptions);
			case ExportToType.JSON:
				return this.exportToJson(data, options as CsvToOptions);
		}
	}

	private static exportToCsv(data: any[], options: CsvToOptions) {
		const replacer = (key: string, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
		const header = Object.keys(data[0]);
		let csv = data.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(options.delimiter));
		csv.unshift(header.join(options.delimiter));
		const csvArray = csv.join('\r\n');
		const blob = new Blob([csvArray], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute('download', `${options.fileName}.csv`);
		link.click();
	}

	private static exportToExcel(data: any[], options: ExcelOptions) {
		var XLSX = require('xlsx');
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(data);
		XLSX.utils.book_append_sheet(wb, ws, options.sheetName);
		XLSX.writeFile(wb, `${options.fileName}.xlsx`);
	}

	private static exportToJson(data: { [key: string]: any }[], options: CsvToOptions) {
		const blob = new Blob([JSON.stringify(data)], { type: 'text/json' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.setAttribute('href', url);
		link.setAttribute('download', `${options.fileName}.json`);
		link.click();
	}
}
