<div class="flex justify-center w-full">
  <div class="w-full md:w-3/4">
    <generic-table
      rowsId="key"
      [config]="dcMetaTagTableConfig"
      [columns]="dcMetaTagColumns"
      [data]="metaTagTableData$ | async"
      [pagingConfig]="metaTagTablePaginationConfig"
      (rowClicked$)="onRowClick($event)"
      (rowDblClicked$)="onRowDoubleClick($event)"
    />
  </div>
</div>

<!-- ----------------------------------------------------------------------- -->
<!--                             edit tag dialog                             -->
<!-- ----------------------------------------------------------------------- -->
<ng-template
  DcDialog="editTagRef"
  [borderColor]="'primary'"
  [hasbackdrop]="true"
>
  <mat-card>
    <mat-card-header class="justify-center">
      <mat-card-title class="dc-primary">
        <h4>{{ "edit-tag" | translate }}</h4>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content [formGroup]="editTagFG">
      <mat-divider class="my-4"></mat-divider>
      <mat-form-field appearance="outline">
        <!-- ---------------------------- tag key input ---------------------------- -->
        <mat-label>{{ "key" | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="editTagKey"
          formControlName="key"
          #editTagKey
        />
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <!-- --------------------------- tag name input ---------------------------- -->
        <mat-label>{{ "value" | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="editTagName"
          formControlName="name"
          #editTagName
        />
      </mat-form-field>
      <mat-divider class="my-4"></mat-divider>
    </mat-card-content>
    <mat-card-actions class="justify-around">
      <!-- ---------------------------- cancel button ---------------------------- -->
      <button
        (click)="dcDialogControl.close('editTagRef', { edit: false })"
        mat-button
        mat-stroked-button
        matDialogClose
        color="primary"
      >
        {{ "cancel-button" | translate }}
      </button>
      <!-- ---------------------------- submit button ---------------------------- -->
      <button
        mat-button
        mat-raised-button
        color="primary"
        (click)="
          dcDialogControl.close('editTagRef', {
            edit: true,
            key: editTagKey.value,
            name: editTagName.value
          })
        "
        [disabled]="!editTagFG.valid"
      >
        {{ "update" | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</ng-template>

<!-- ----------------------------------------------------------------------- -->
<!--                            create tag dialog                            -->
<!-- ----------------------------------------------------------------------- -->
<ng-template
  DcDialog="createTagRef"
  [borderColor]="'primary'"
  [hasbackdrop]="true"
>
  <mat-card>
    <mat-card-header class="justify-center">
      <mat-card-title class="dc-primary">
        <h4>{{ "create-new-tag" | translate }}</h4>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content [formGroup]="createTagFG">
      <mat-divider class="my-4"></mat-divider>
      <mat-form-field appearance="outline">
        <!-- ---------------------------- tag key input ---------------------------- -->
        <mat-label>{{ "key" | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="createTagKey"
          formControlName="key"
          #createTagKey
        />
      </mat-form-field>
      <br />
      <mat-form-field appearance="outline">
        <!-- --------------------------- tag name input ---------------------------- -->
        <mat-label>{{ "value" | translate }}</mat-label>
        <input
          matInput
          type="text"
          name="createTagName"
          formControlName="name"
          #createTagName
        />
      </mat-form-field>
      <mat-divider class="my-4"></mat-divider>
    </mat-card-content>
    <mat-card-actions class="justify-around">
      <!-- ---------------------------- cancel button ---------------------------- -->
      <button
        (click)="dcDialogControl.close('createTagRef', { create: false })"
        mat-button
        mat-stroked-button
        matDialogClose
        color="primary"
      >
        {{ "cancel-button" | translate }}
      </button>
      <!-- ---------------------------- submit button ---------------------------- -->
      <button
        mat-button
        mat-raised-button
        color="primary"
        (click)="
          dcDialogControl.close('createTagRef', {
            create: true,
            key: createTagKey.value,
            name: createTagName.value
          })
        "
        [disabled]="!createTagFG.valid"
      >
        {{ "create" | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</ng-template>

<!-- ----------------------------------------------------------------------- -->
<!--                           restore tag dialog                            -->
<!-- ----------------------------------------------------------------------- -->
<ng-template
  DcDialog="restoreTagRef"
  [borderColor]="'primary'"
  [hasbackdrop]="true"
>
  <mat-card>
    <mat-card-header class="justify-center">
      <mat-card-title class="dc-primary">
        <h4>{{ "create-new-tag" | translate }}</h4>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-divider class="my-4"></mat-divider>
      {{ "struct.metatageditor.restore.description" | translate }}
      <mat-divider class="my-4"></mat-divider>
    </mat-card-content>
    <mat-card-actions class="justify-around">
      <!-- ---------------------------- cancel button ---------------------------- -->
      <button
        (click)="dcDialogControl.close('restoreTagRef', { restore: false })"
        mat-button
        mat-stroked-button
        matDialogClose
        color="primary"
      >
        {{ "struct.metatageditor.restore.decline" | translate }}
      </button>
      <!-- ---------------------------- accept button ---------------------------- -->
      <button
        mat-button
        mat-raised-button
        color="primary"
        (click)="dcDialogControl.close('restoreTagRef', { restore: true })"
      >
        {{ "struct.metatageditor.restore.accept" | translate }}
      </button>
    </mat-card-actions>
  </mat-card>
</ng-template>
