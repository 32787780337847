import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  catchError,
  switchMap,
  filter,
  take,
  finalize,
  throwError,
} from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class TokenIterceptor implements HttpInterceptor {

  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private authService:AuthService, private router:Router){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) return this.handle401Error(req, next);
        if (error.status === 400 || error.status === 404) throw (error.error) ;
        if (error.status === 500 ) throw (error.error) ;

        throw error;
      })
    );
  }

  private addToken(req: HttpRequest<any>) {
    let token = AuthService.accessToken
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.isRefreshing) {
      return this.refreshTokenSubject.pipe(
        filter((token) => token),
        take(5),
        switchMap((token) => {
          return next.handle(this.addToken(req));
        })
      );
    } else {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshLogin().pipe(
        switchMap((refreshResponse) => {
          this.refreshTokenSubject.next(refreshResponse.accessToken);
          return next.handle(
            this.addToken(req));
        }), finalize(()=>(this.isRefreshing = false))
      );
    }
  }
}

export const tokenInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenIterceptor,
  multi: true,
};
