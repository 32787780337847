import {AfterViewInit, Directive, ElementRef, Input} from "@angular/core";
import {MatRowDef} from "@angular/material/table";
import {Router} from "@angular/router";

@Directive({
  selector: "[dcTableExporterDataPicker]",
  exportAs: "dcTableExporterDataPicker",
  standalone: true
})
export class DcExporterRowDataPickerDirective implements AfterViewInit {
  private static RowsDataCollector: Map<string, Map<string, Map<string, any>>> = new Map();

  public static resetTableRowsData(tableId: string) {
    this.RowsDataCollector.delete(tableId);
  }

  public static getTableRowsData(tableId: string): Map<string, Map<string, any>> {
    return this.RowsDataCollector.get(tableId);
  }

  @Input() tableId: string;
  public rowId: string;

  constructor(private elementRef: ElementRef, private matRowDef: MatRowDef<any>, private router: Router) {
  }

  public ngAfterViewInit() {
    this.getRowData();
  }

  public getRowData(): Map<string, any> {
    const columns = this.matRowDef.columns;
    const cellsElements = Array.from(this.elementRef.nativeElement.querySelectorAll('td') as NodeListOf<HTMLTableCellElement>);

    if (cellsElements.length > 0) {
      let rowCellsData: Map<string, any> = new Map();
      cellsElements.forEach((cellElement, index) => {
        const column = columns[index];
        if (column == 'id') this.rowId = cellElement.innerText?.toString()?.trim();
        if (this.rowId) {
          const cellData = cellElement.innerText;
          rowCellsData.set(column, cellData);

          const id = this.tableId ? this.tableId : this.router.url;
          let rowsData = DcExporterRowDataPickerDirective.getTableRowsData(id);
          if (!rowsData) rowsData = new Map();
          rowsData.set(this.rowId, rowCellsData);
          DcExporterRowDataPickerDirective.RowsDataCollector.set(id, rowsData);
        }
      });
      return rowCellsData;
    }
    return null;
  }
}
