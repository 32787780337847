import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { catchError } from 'rxjs';
import {
  MessageTemplateModel,
  TenantSettingsModel,
} from 'src/app/Data/Entities/tenant/tenant.model';
import { GlobalsService } from 'src/app/Data/Services/globals.service';
import { StorageKeys } from 'src/app/Data/Services/storage.data/storage-keys.data';
import { StorageService } from 'src/app/Data/Services/storage.service';
import { TenantsService } from 'src/app/Data/Services/tenants.service';
import { SnackbarService } from '../Layouts/snackbar/snackbar.service';
import { GeneralSettingsComponent } from './sub-components/general-settings/general-settings.component';
import { TemplatesAndMessagesComponent } from './sub-components/templates-and-messages/templates-and-messages.component';
import { AuthService } from 'src/app/Data/Services/auth.service';
import { PermissionType } from 'src/app/Data/Entities/User/user-permission.type';
import { MetaTagComponent } from './sub-components/meta-tag/meta-tag.component';

@Component({
  selector: 'company-settings',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    GeneralSettingsComponent,
    TranslateModule,
    TemplatesAndMessagesComponent,
    MatButtonModule,
    MetaTagComponent
  ],
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss'],
})
export class CompanySettingsComponent implements OnInit {
  @ViewChild(GeneralSettingsComponent, { static: false })
  generalSettingsComponent: GeneralSettingsComponent;
  @ViewChild(TemplatesAndMessagesComponent, { static: false })
  templatesAndMessagesComponent: TemplatesAndMessagesComponent;

  /* -------------------------------------------------------------------------- */
  /*                                 constructor                                */
  /* -------------------------------------------------------------------------- */
  constructor(
    private tenantService: TenantsService,
    private snackbar: SnackbarService,
    private storageService: StorageService
  ) {}

  /* -------------------------------------------------------------------------- */
  /*                              Global variables                              */
  /* -------------------------------------------------------------------------- */
  TenantSettingsModel: TenantSettingsModel = null;
  protected dir = GlobalsService.dir;
  protected loading: boolean = false;
  protected hesSettingsWritePermission: boolean = false;

  /* -------------------------------------------------------------------------- */
  /*                               lifecycle hooks                              */
  /* -------------------------------------------------------------------------- */
  ngOnInit(): void {
    const permissions = AuthService.currentUserPermissions;
    this.hesSettingsWritePermission = permissions.includes(PermissionType.settings_update);
    this.tenantService.getCurrentTenantSettings().subscribe((res) => {
      this.TenantSettingsModel = {
        defaultLanguage: res.defaultLanguage,
        useSplashScreen: res.useSplashScreen,
        companyLogo: res.companyLogo,
        templates: res.templates,
        htmlTemplates: res.htmlTemplates,
        postalAccount: res.postalAccount,
        smsAccountSid: res.smsAccountSid,
        smsAccountName: res.smsAccountName,
        smsTwilioSid: res.smsTwilioSid,
      };
    });
  }

  saveSettings() {
    this.loading = true;
    if (
      this.generalSettingsComponent.generalSettingsFG.valid &&
      this.templatesAndMessagesComponent.templatesAndMessagesFG.valid
    ) {
      const generalSettingsData =
        this.generalSettingsComponent.generalSettingsFG.getRawValue();
      const templatesAndMessagesData =
        this.templatesAndMessagesComponent.templatesAndMessagesFG.getRawValue();
      const smsTemplates: MessageTemplateModel[] = [
        {
          type: templatesAndMessagesData.selectedTypeSMS,
          language: templatesAndMessagesData.selectedLangSMS,
          text: templatesAndMessagesData.textareaValueSMS,
        },
      ];
      const htmlTemplates: MessageTemplateModel[] = [
        {
          type: templatesAndMessagesData.selectedTypeHTML,
          language: templatesAndMessagesData.selectedLangHTML,
          text: templatesAndMessagesData.textareaValueHTML,
        },
      ];
      const settings: TenantSettingsModel = {
        defaultLanguage: generalSettingsData.defaultLanguage,
        useSplashScreen: generalSettingsData.useSplashScreen,
        companyLogo: {
          type: 'image/png',
          data: generalSettingsData.companyLogo,
        },
        templates: smsTemplates,
        htmlTemplates: htmlTemplates,
        postalAccount:
          this.tenantService.currentTenantSettings.value.postalAccount,
        smsAccountSid:
          this.tenantService.currentTenantSettings.value.smsAccountSid,
        smsAccountName: templatesAndMessagesData.smsAccountName,
        smsTwilioSid:
          this.tenantService.currentTenantSettings.value.smsTwilioSid,
      };
      this.tenantService
        .updateTenantSettings(settings)
        .pipe(
          catchError((err) => {
            this.loading = false;
            this.snackbar.open({
              message: 'upadet-company-setting-error',
              type: 'error',
            });
            return err;
          })
        )
        .subscribe((res) => {
          this.loading = false;
          if (res)
            this.snackbar.open({
              message: 'upadet-company-setting-success',
              type: 'success',
            });

          this.storageService.setItem(
            StorageKeys.CompanySettingsKeys.KeyCompanyLogo,
            settings.companyLogo?.data
          );
        });
    }
  }
}
