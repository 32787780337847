import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/Data/Services/language.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { PagingConfigStruct, PagingStruct, TableEntryStruct } from '../../data/types/table.data';
import { TableSharedModule } from '../../table.module';
import { GlobalsService } from 'src/app/Data/Services/globals.service';

const matModules = [MatPaginatorModule];

@Component({
	selector: 'table-paginator',
	standalone: true,
	imports: [TableSharedModule, ...matModules],
	templateUrl: './table-paginator.component.html',
	styleUrls: ['./table-paginator.component.scss'],
})
export class TablePaginatorComponent<T extends TableEntryStruct> implements OnInit {
	@ViewChild(MatPaginator) set _paginator(paginator: MatPaginator) {
		if (this.lazyMode) return;
		this.matPaginatorInit.emit(paginator);
	}
	/* --------------------------------- Inputs --------------------------------- */
	@Input() disable: boolean;
	@Input() lazyMode: boolean = true;
	@Input() pagingConfig: PagingConfigStruct<T> | PagingStruct<T>;
	@Input() tableItemName: { singular: string; plural: string };
	/* --------------------------------- Outputs -------------------------------- */
	@Output() matPaginatorInit: EventEmitter<MatPaginator> = new EventEmitter<MatPaginator>();
	@Output() pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

	/* ---------------------------- Global Variables ---------------------------- */
	protected isMobile = window.deviceService.isMobile;

	public currrentPageNo = 0; // 0 based (index)
	public numOfPages = 0;// Math.ceil(length /this.pageSize);
	public totalItems = 0;
	public itemsPerPage = 0;
	protected mainLabel: string = ""; //`עמוד {{currrentPageNo}} מתוך {{numOfPages}}`;
	private prevLang: string = ""

	private readonly emptyResLable = "pages.reports.paging.empty";
	private readonly pageLabel = "pages.reports.paging.page";
	private readonly ofLabel = "pages.reports.paging.of";
	private readonly singularLabel = "pages.reports.item.singular";
	private readonly pluralLabel = "pages.reports.item.plural";
	private readonly firstPageLabel = 'pages.reports.paging.first';
	private readonly lastPageLabel = 'pages.reports.paging.last';
	private readonly nextPageLabel = 'pages.reports.paging.next';
	private readonly previousPageLabel = 'pages.reports.paging.prev';



	/* ------------------------------- Constructor ------------------------------ */
	constructor(private tablePaginatorIntl: MatPaginatorIntl, private languageService:LanguageService, public translate:TranslateService) {
		// this.languageService.currentLanguage$.subscribe((val) => {
		// 	if(val.value != this.prevLang){
		// 		this.tablePaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
		// 			this.totalItems = this.pagingConfig.totalRows
		// 			this.itemsPerPage = this.pagingConfig.pageSize
		// 			this.currrentPageNo = this.pagingConfig.currentPage; // 0 based (index)
		// 			this.numOfPages = Math.ceil(this.totalItems / this.itemsPerPage);

		// 			this.mainLabel = this.translate.instant(this.pageLabel) + " " + this.currrentPageNo.toString() + this.translate.instant(this.ofLabel) + this.numOfPages.toString();
		// 			if (length == 0) this.mainLabel = this.translate.instant(this.emptyResLable);
		// 			if (length == 1 ) this.mainLabel += " (" + pageSize.toString() + " " + this.translate.instant(this.singularLabel) + " )"
		// 			else this.mainLabel += " (" + this.totalItems?.toString() + " " + this.translate.instant(this.pluralLabel) + " )"

		// 			this.tablePaginatorIntl.firstPageLabel = this.translate.instant(this.firstPageLabel);
		// 			this.tablePaginatorIntl.lastPageLabel = this.translate.instant(this.lastPageLabel);
		// 			this.tablePaginatorIntl.nextPageLabel = this.translate.instant(this.nextPageLabel);
		// 			this.tablePaginatorIntl.previousPageLabel = this.translate.instant(this.previousPageLabel);
		// 			this.tableItemName.plural = this.translate.instant(this.pluralLabel);
		// 			this.tableItemName.singular = this.translate.instant(this.singularLabel);
		// 			return this.mainLabel;
		// 		};
				
		// 		let pEvent = new PageEvent();
		// 		pEvent = {
		// 			length: this.totalItems, 
		// 			pageIndex: this.currrentPageNo, 
		// 			pageSize: this.itemsPerPage,
		// 			previousPageIndex: this.currrentPageNo 
		// 		};
		// 		this.pageChange.emit(pEvent);
		// 		this.prevLang = val.value
		// 	}
		// });
		if (GlobalsService.lang == 'he') {
			this.tablePaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
			  const pageNo = page + 1; // 0 based (index)
			  const numOfPages = Math.ceil(length / pageSize);
			  let result = `עמוד ${pageNo} מתוך ${numOfPages}`;
			  if (length == 0) result = 'אין נתונים להצגה';
			  if (this.tableItemName) {
				result += `\t (${length} ${length == 1 ? this.tableItemName.singular : this.tableItemName.plural})`;
			  }
			  return result;
			};
			this.tablePaginatorIntl.firstPageLabel = 'עמוד ראשון';
			this.tablePaginatorIntl.lastPageLabel = 'עמוד אחרון';
			this.tablePaginatorIntl.nextPageLabel = 'עמוד הבא';
			this.tablePaginatorIntl.previousPageLabel = 'עמוד קודם';
		  } else {
			this.tablePaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
			  const pageNo = page + 1; // 0 based (index)
			  const numOfPages = Math.ceil(length / pageSize);
			  let result = `Page ${pageNo} of ${numOfPages}`;
			  if (length == 0) result = 'No data to display';
			  if (this.tableItemName) {
				result += ` ${length == 1 ? this.tableItemName.singular : this.tableItemName.plural}`;
			  }
			  return result;
			};
			this.tablePaginatorIntl.firstPageLabel = 'First Page';
			this.tablePaginatorIntl.lastPageLabel = 'Last Page';
			this.tablePaginatorIntl.nextPageLabel = 'Next Page';
			this.tablePaginatorIntl.previousPageLabel = 'Previous Page';
		  }
	}

	/* ----------------------------- Lifecycle Hooks ---------------------------- */
	ngOnInit(): void {
		if (!this.pagingConfig) throw new Error('Table paging config is required');
		if (this.disable == undefined) throw new Error('loading is required');
	}

	/* -------------------------------------------------------------------------- */
	/*                                   Methods                                  */
	/* -------------------------------------------------------------------------- */
	protected onPageChange(pageEvent: PageEvent) {
		this.pageChange.emit(pageEvent);
	}

}
