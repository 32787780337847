import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
	selector: '[dcHover]',
	standalone: true,
})
export class HoverDirective {
	@Input() hoverDelay: number = 0;
	@Output() onHover: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

	@Input() leaveDelay: number = 0;
	@Output() onLeave: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

	constructor() {}

	@HostListener('pointerenter', ['$event'])
	onPointerEnter(event: PointerEvent) {
		if (this.hoverDelay) {
			setTimeout(() => this.onHover.emit(event), this.hoverDelay);
		} else {
			this.onHover.emit(event);
		}
	}

	@HostListener('pointerleave', ['$event'])
	onPointerLeave(event: PointerEvent) {
		if (this.leaveDelay) {
			setTimeout(() => this.onLeave.emit(event), this.leaveDelay);
		} else {
			this.onLeave.emit(event);
		}
	}
}
