import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateAdapter, MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocIconComponent } from 'src/app/modules/icon/icon.component';
import { RowStateData } from '../../data/config/row-state-data.model';
import { ColumnStruct, TableActionStruct, TableEntryStruct } from '../../data/types/table.data';
import { TableSharedModule } from '../../table.module';
import { SearchFilterChangeStruct } from './../../data/types/table-filter.type';
import moment from 'moment';
import { IStoredTableFilter } from '../../data/types/user-table-store.schema';
import { GlobalsService } from 'src/app/Data/Services/globals.service';
import { StoredTablePreset } from '../../data/types/stored-table-preset.model';
import { pairwise } from 'rxjs';
import { SnackbarService } from 'src/app/modules/Layouts/snackbar/snackbar.service';

const materialModules = [
	MatFormFieldModule,
	MatButtonToggleModule,
	MatIconModule,
	MatDatepickerModule,
	MatOptionModule,
	MatSelectModule,
	MatInputModule,
	MatTooltipModule,
	MatDividerModule,
	DragDropModule,
	MatBadgeModule,
	MatNativeDateModule,
];
const standaloneComponents = [DocIconComponent];
@Component({
	selector: 'table-actions',
	standalone: true,
	imports: [TableSharedModule, ...materialModules, ...standaloneComponents],
	templateUrl: './table-actions.component.html',
	styleUrls: ['./table-actions.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableActionsComponent<T extends TableEntryStruct> implements OnInit, OnChanges {
	/* --------------------------------- Inputs --------------------------------- */
	@Input() disable: boolean;
	@Input() lazyMode: boolean;
	@Input() initSearchValue: string;
	@Input() initDateRange: IStoredTableFilter;
	@Input() customTableActions: TableActionStruct[] = [];
	@Input() dataColumns: ColumnStruct<T>[];
	@Input() selectedRows: RowStateData[] = [];
  
	/* -------------- Customization Table Action features - Inputs -------------- */
	@Input() enableSearch: boolean = true;
	@Input() enableDateRange: boolean = false;
	@Input() enablePresets: boolean = true;
	@Input() enableVisibleColumnsSelection: boolean = true;
  
	/* --------------------------------- Outputs -------------------------------- */
	@Output() searchFilterChange: EventEmitter<SearchFilterChangeStruct> = new EventEmitter();
	@Output() visibleColumnChangeEvent: EventEmitter<MatSelectChange> = new EventEmitter();
  
	/* ---------------------------- Global Variables ---------------------------- */
	protected isMobile = GlobalsService.isMobile;
  
	 /* ------------------------------ Table Presets ----------------------------- */
	 @Input() actualTablePreset: StoredTablePreset = null;
	 protected actualPreset: { preset: StoredTablePreset; color: string };
	 @Output() onApplyPreset: EventEmitter<StoredTablePreset> = new EventEmitter();
   
	  protected onClickStorePreset(): void {
	   this.onApplyPreset.emit(this.actualTablePreset);
	   this.actualPreset = {preset: this.actualTablePreset, color: 'var(--dc-primary)'};
	   this.actualTablePreset?.savedOnBackend$.subscribe(saved => {
		 if (saved) this.actualPreset.color = 'var(--dc-success)';
		 else this.actualPreset.color = 'var(--dc-primary)';
	   });

	   this.snackbarService.open({
        message: GlobalsService.globalTranslate('table.preset.success'),
        type: 'success',
      });
	 }
  
	/* ------------------------ Visible Columns Selection ----------------------- */
	protected visibleColumnsFC: FormControl = new FormControl([]);
	@Output() onColumnsOrderChange: EventEmitter<CdkDragDrop<any, any, any>> = new EventEmitter();
  
	/* ------------------------------ Global Search ----------------------------- */
	@Input() searchLabel: string;
	private last2SearchValues: [string, string] = [null, null];
	protected searchFC: FormControl = new FormControl('');

	/* ---------------------------- Date Range Filter --------------------------- */
	protected today = new Date(); 
	private last2DateRangeValues: [{ fromDate: Date; toDate: Date }, IStoredTableFilter] = [null, null];
	@Input() dateRange: IStoredTableFilter = {fromDate: null, toDate: new Date()};
	protected dateRangeFG: FormGroup = new FormGroup({
	  fromDate: new FormControl(null),
	  toDate: new FormControl(null),
	});
  
	/* ----------------- Query Param Changes (dateRange, search) ---------------- */
	private get queryParamsChanged(): boolean {
	  return this.last2DateRangeValues[0] != this.last2DateRangeValues[1] || this.last2SearchValues[0] != this.last2SearchValues[1] || this.searchFC.value != this.last2SearchValues[0];
	}
  
	/* ------------------------------- Constructor ------------------------------ */
	constructor( private snackbarService: SnackbarService) {}
  
	/* ----------------------------- Lifecycle Hooks ---------------------------- */
	ngOnInit(): void {
	  this.actualPreset = {preset: this.actualTablePreset, color: 'var(--dc-primary)'};
	  this.actualTablePreset?.savedOnBackend$.subscribe(saved => {
		if (saved) this.actualPreset.color = 'var(--dc-success)';
		else this.actualPreset.color = 'var(--dc-primary)';
	  });
  
	  // store 2 results of this.dateRangeFG and this.searchFC and compare them with the current value to see if they changed
	  this.dateRangeFG.valueChanges.pipe(pairwise()).subscribe(([prev, curr]) => {
		if (prev.fromDate != curr.fromDate || prev.toDate != curr.toDate) {
		  this.last2DateRangeValues[1] = this.last2DateRangeValues[0];
		  this.last2DateRangeValues[0] = curr;
		}
	  });
	  this.searchFC.valueChanges.pipe(pairwise()).subscribe(([prev, curr]) => {
		if (prev != curr) {
		  this.last2SearchValues[1] = this.last2SearchValues[0];
		  this.last2SearchValues[0] = curr;
		}
	  });
	}
  
	ngOnChanges(changes: SimpleChanges): void {
	  if (changes?.initSearchValue?.currentValue != undefined) this.searchFC.setValue(changes.initSearchValue.currentValue);
	  if (changes?.initDateRange?.currentValue != undefined) this.dateRangeFG.setValue(changes.initDateRange.currentValue);
	  if (changes?.dataColumns?.currentValue && changes?.dataColumns?.currentValue != changes?.dataColumns?.previousValue) {
		const visibleColumns = this.dataColumns.filter(col => col.state.visible && !col.isIdColumn && !col.isLayoutColumn && !col.pinned);
		this.visibleColumnsFC.setValue(visibleColumns);
	  }
	}
  
	/* -------------------------------------------------------------------------- */
	/*                                   Methods                                  */
  
	/* -------------------------------------------------------------------------- */
	protected trackByCustomActions(index: number, action: TableActionStruct) {
	  return action?.label ?? index;
	}
  
	/* ------------------- Search and Date Range Filter Logics ------------------- */
	protected onSearchFilterChange({
									 force = false,
									 clearSearch = false,
									 clearDateRange = false,
								   }: { force?: boolean; clearSearch?: boolean; clearDateRange?: boolean } = {}) {
	  if (clearDateRange) this.dateRangeFG.reset();
	  if (!force && !this.queryParamsChanged && !clearDateRange) return;
  
	  /* -------------------------- search query string: -------------------------- */
	  const search = clearSearch ? '' : this.searchFC.value;
  
	  /* -------------------------- date range: -------------------------- */
	  // fromDate => set to 00:00:00, so that the date range includes the whole day
	  const fromDate = this.dateRangeFG.value.fromDate ? moment(this.dateRangeFG.value.fromDate).startOf('day').toDate() : null;
	  //toDate => set to 23:59:59, so that the date range includes the whole day
	  const toDate = this.dateRangeFG.value.toDate ? moment(this.dateRangeFG.value.toDate).endOf('day').toDate() : null;
	  const dateRange = {fromDate, toDate};
	  // emit the search and dateRange values to the parent component
	  this.searchFilterChange.emit({search, dateRange});
	}
  
	/* -------------------- Visible Actions Collapsable Forms ------------------- */
  
	private _visibleForms: {
	  searchInput: boolean;
	  dateRangePicker: boolean;
	  visibleColumnsSelection: boolean;
	  tablePresetSave: boolean;
	} = {
	  searchInput: !window.deviceService.isMobile,
	  dateRangePicker: false,
	  visibleColumnsSelection: false,
	  tablePresetSave: false,
	};
  
	get visibleForms() {
	  return this._visibleForms;
	}
  
	protected setCollapsableActionForm(formName: keyof typeof this._visibleForms, value: boolean) {
	  if (window.deviceService.isMobile) {
		this._visibleForms = {
		  searchInput: formName == 'searchInput' ? value : false,
		  dateRangePicker: formName == 'dateRangePicker' ? value : false,
		  visibleColumnsSelection: formName == 'visibleColumnsSelection' ? value : false,
		  tablePresetSave: formName == 'tablePresetSave' ? value : false,
		};
	  } else {
		this._visibleForms[formName] = value;
	  }
	}
  }
