import {Directive, ElementRef, OnInit} from "@angular/core";
import { LanguageService } from "../Data/Services/language.service";

@Directive({
  selector: '[CurrentDir]',
  standalone: true,
})
export class CurrentDirDirective implements OnInit {
  constructor(private languageService: LanguageService, private element: ElementRef) {
  }

  ngOnInit(): void {
    this.languageService.currentLanguage$.subscribe(lang => {
      this.element.nativeElement.classList.add(lang.dir);
    });
  }
}
