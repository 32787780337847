import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, of, pipe } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (AuthService.isAuthenticated && !state.url.includes('?accessToken=')) {
      if (next.firstChild.routeConfig.path) {
        if (!state.url.includes(next.firstChild.routeConfig.path)) {
          //this.router.navigate(['auth/' + next.firstChild.routeConfig.path]);
          this.router.navigate(['reports']);
          return of(true)
        }
      }
      return of(true)
    } else if (state.url.includes('?accessToken=')) {
      return AuthService.decodeUrl(next).pipe(
        switchMap(() => {
          return this.authService.refreshLogin().pipe(
           map(()=> true)
          );
        })
      );
    } else {
      this.authService.logout();
      return of(false);
    }
  }
}
