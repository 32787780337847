import { Injectable } from '@angular/core';
import { catchError, map, Observable, Subject, take } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { StorageKeys } from './storage.data/storage-keys.data';
import { SnackbarService } from 'src/app/modules/Layouts/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';


export type HttpOptions = { headers: HttpHeaders; params: HttpParams };
export type HttpAction = 'get' | 'post' | 'put' | 'delete' | 'patch';
export type HttpShortParams = { [key: string]: string | string[] };


@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private authService: AuthService, private snackbarService:SnackbarService, private translateService: TranslateService ) {}
  timeOutFlag: boolean = false
  public get<T>(apiPath: string, params: HttpShortParams | HttpParams = {}  ): Observable<T> {
    return this.action<T>('get', apiPath, params);
  }
  public post<T>(apiPath: string, params: HttpShortParams | HttpParams = {}, object: any = null): Observable<T> {
    return this.action<T>('post', apiPath, params, object);
  }
  public put<T>(apiPath: string, object: any = null): Observable<T> {
    return this.action<T>('put', apiPath, undefined, object);
  }
  public delete<T>(
    apiPath: string,
    params: HttpShortParams = {}
  ): Observable<T> {
    return this.action<T>('delete', apiPath, params);
  }
  public patch<T>(apiPath: string, object: any = null): Observable<T> {
    return this.action<T>('patch', apiPath, undefined, object);
  }
  public static userInactive: Subject<any> = new Subject<any>();

  public readonly httpActions: {
    [name in HttpAction]: <T>(
      url: string,
      options: HttpOptions,
      json?: string
    ) => Observable<T>;
  } = {
    get: <T>(url: string, options: HttpOptions) =>
      this.http.get<T>(url, {
        headers: options.headers,
        params: options.params,
        observe: 'body',
      }),
    post: <T>(url: string, options: HttpOptions, json?: string) =>
      this.http.post<T>(url, json, {
        headers: options.headers,
        params: options.params,
        observe: 'body',
      }),
    put: <T>(url: string, options: HttpOptions, json?: string) =>
      this.http.put<T>(url, json, {
        headers: options.headers,
        params: options.params,
        observe: 'body',
      }),
    delete: <T>(url: string, options: HttpOptions) =>
      this.http.delete<T>(url, {
        headers: options.headers,
        params: options.params,
        observe: 'body',
      }),
    patch: <T>(url: string, options: HttpOptions, json?: string) =>
      this.http.patch<T>(url, json, {
        headers: options.headers,
        params: options.params,
        observe: 'body',
      }),
  };

  public getRequestHeaders: () => HttpHeaders = () =>
    new HttpHeaders({
      Authorization: 'Bearer ' + window.storageService.getItem(StorageKeys.AuthServiceKeys.KeyAccessToken),
      'Content-Type': 'application/json',
      Accept: `*/*`,
    });


  private getRequestParams(shortParams: HttpShortParams | HttpParams): HttpParams {
    let params = new HttpParams();
    if (shortParams instanceof HttpParams) return shortParams;
    for (const key in shortParams) {
      if (shortParams[key] != null) {
        if (typeof shortParams[key] === 'string') {
          params = params.append(key, shortParams[key] as string);
        } else if (typeof shortParams[key] === 'number') {
          params = params.append(key, shortParams[key].toString());
        } else if (typeof shortParams[key] === 'boolean') {
          params = params.append(key, shortParams[key].toString());
        } else if (Array.isArray(shortParams[key])) {
          (shortParams[key] as string[]).forEach(param => {
            params = params.append(key, param);
          });
        }
      }
    }
    return params;
  }

  public action<T>(action: HttpAction, apiPath: string, params: HttpShortParams | HttpParams = {}, object: any = null): Observable<T> {
    var options: HttpOptions = {
      params: this.getRequestParams(params),
      headers: this.getRequestHeaders(),
    };
    var json = object == null ? undefined : JSON.stringify(object);
    return this.httpActions[action]<T>(AuthService.getBaseUrl + apiPath, options, json).pipe(
      map((value) => {
        console.log('API ' + action.toUpperCase() + ' ' + AuthService.getBaseUrl + apiPath + ' - success');
        return value;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('API ' + action.toUpperCase() + ' ' + AuthService.getBaseUrl + apiPath + ' - error ' + error?.status);
       
        let message = "" 
        if (error?.status == 0) {
          message = this.translateService.instant('pages.error.no-internet');
          this.snackbarService.open({
            message: message,
            type: 'error',
          });
          throw error;
        }

        else if (error?.status == 400){
          message = this.translateService.instant('pages.error.400')
          this.snackbarService.open({ 
            message: message,
            type: 'error',});
            take(10)
            this.authService.logout();
        }
        
        message = error.message
        console.log(error.message);
        this.authService.logout();
        throw error;
      })
    );
  }
}
