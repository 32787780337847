import { Component, Input } from '@angular/core';
import {  NgIf } from '@angular/common';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ZoomValue } from './data/zoom-level-struct.type';
import { GlobalsService } from 'src/app/Data/Services/globals.service';
import { LoaderComponent } from '../Layouts/loader/loader.component';

@Component({
  selector: 'preview-document',
  standalone: true,
  imports: [NgIf,NgxExtendedPdfViewerModule,LoaderComponent],
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss']
})
export class PreviewDocumentComponent{
  constructor() {}
  @Input() pdfBase64: string = '';
  public zoomValue: ZoomValue | string | number = GlobalsService.isMobile ? 40 : 'page-height';
  public currentPage: number = 1;




}
