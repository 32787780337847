export class TenantModel {
  id: number
  isEnabled: boolean
  name: string
  formalName: string
  companyID: string
  companyPH: string
  hosts: string[]
  server: string
}

export class TenantSettingsModel {
  defaultLanguage?: string;
  useSplashScreen: boolean;
  companyLogo?: CompanyLogo
  templates: MessageTemplateModel[]
  htmlTemplates: MessageTemplateModel[]
  postalAccount: string;
  smsAccountSid?: string
  smsAccountName?: string
  smsTwilioSid?: string
}

export class CompanyLogo {
  type?: string;
  data?: string;
}

export class MessageTemplateModel {
  type?: string
  language?: string
  text?: string
}



