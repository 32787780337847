<mat-menu
	#globalMenu="matMenu"
	class="revert-menu-checkboxes"
>
	<!-- Row Checked -->
	<ng-container *ngIf="showHideChecboxesAction">
		<div
			mat-menu-item
			#resetCheckboxesTrigger="matMenuTrigger"
			[matMenuTriggerFor]="resetCheckedRows"
			role="menuitem"
		>
			{{ 'dccomp.cycle-tables.global-menu.reset-checked-rows' | translate }}
		</div>

		<mat-divider></mat-divider>
	</ng-container>

	<!-- Export to -->
	<div
		mat-menu-item
		[class]="checkedRowIDs.length == 0 ? 'cursor-not-allowed' : 'cursor-pointer'"
		[disabled]="checkedRowIDs.length == 0"
		#exportToMenuTrigger="matMenuTrigger"
		[matMenuTriggerFor]="exportTypeMenu"
		[matMenuTriggerData]="{ mode: tableExportMode.AllTable }"
		role="menuitem"
	>
		{{ 'dccomp.dc-table.actions.export-to.label' | translate }}
	</div>

	<!-- --------------------------- Custom Actions ---------------------------- -->
	<ng-container *ngIf="customTableActions?.length > 0">
		<!-- Customs -->
		<mat-divider></mat-divider>
		<mat-divider></mat-divider>
		<ng-container *ngFor="let action of customTableActions; let index = index">
			<ng-container *ngIf="action?.section == 'menu'">
				<div
					mat-menu-item
					role="menuitem"
					[class]="action?.disabled(action?.data) ? 'cursor-not-allowed' : 'cursor-pointer'"
					(click)="!action?.disabled(action?.data) && action.onClickFn(action?.data)"
					[disabled]="action?.disabled(action?.data)"
				>
					<span class="mx-2 flex items-center gap-2">
						<DocIcon
							*ngIf="action?.iconName"
							[name]="action.iconName"
							type="duotone"
							size="lg"
							[primaryColor]="Color(action.color)"
							[secondaryColor]="Color(action.color)"
						/>

						<h6 class="text-lg">{{ action.label(action?.data) | translate }}</h6>
					</span>
				</div>
				<mat-divider *ngIf="index < customTableActions.length - 1"></mat-divider>
			</ng-container>
		</ng-container>
	</ng-container>
</mat-menu>

<!-- ------------------------- Reset Checked Rows -------------------------- -->
<mat-menu
	#resetCheckedRows="matMenu"
	class="revert-menu"
>
	<button
		mat-menu-item
		role="menuitem"
		(click)="resetChecked.emit('page')"
	>
		{{ 'dccomp.cycle-tables.global-menu.reset-checked-row-current-page' | translate }}
	</button>
	<mat-divider></mat-divider>
	<button
		mat-menu-item
		role="menuitem"
		(click)="resetChecked.emit('all')"
	>
		{{ 'dccomp.cycle-tables.global-menu.reset-checked-row-global' | translate }}
	</button>
</mat-menu>

<!-- ------------------------------ Export to ------------------------------ -->
<mat-menu
	#exportToMenu="matMenu"
	class="revert-menu-checkboxes"
>
	<!-- Selected Rows-->
	<button
		mat-menu-item
		#exportSelectedRowsTrigger="matMenuTrigger"
		[matMenuTriggerFor]="exportTypeMenu"
		[matMenuTriggerData]="{ mode: tableExportMode.SelectedRows }"
		role="menuitem"
	>
		{{ 'dccomp.dc-table.export.selected-rows' | translate }}
	</button>

	<mat-divider></mat-divider>

	<!-- All Table -->
	<button
		mat-menu-item
		#exportAllTableTrigger="matMenuTrigger"
		[matMenuTriggerFor]="exportTypeMenu"
		[matMenuTriggerData]="{ mode: tableExportMode.AllTable }"
		role="menuitem"
	>
		{{ 'dccomp.dc-table.export.all-table' | translate }}
	</button>
</mat-menu>

<mat-menu
	#exportTypeMenu="matMenu"
	class="revert-menu"
	xPosition="before"
>
	<ng-template
		matMenuContent
		let-mode="mode"
	>
		<!-- CSV -->
		<button
			mat-menu-item
			role="menuitem"
			class="p-0"
			(click)="openExportOptionsDialog(exportToType.CSV, mode)"
		>
			<span class="flex w-full items-center justify-between p-1">
				<i class="fa-duotone fa-file-csv fa-xl text-blue-500"></i>
				<span>CSV</span>
			</span>
		</button>

		<mat-divider></mat-divider>

		<!-- XLSX -->
		<button
			mat-menu-item
			role="menuitem"
			class="p-0"
			(click)="openExportOptionsDialog(exportToType.XLSX, mode)"
		>
			<span class="flex w-full items-center justify-between p-1">
				<i class="fa-duotone fa-file-excel fa-xl text-green-500"></i>
				<span>Excel</span>
			</span>
		</button>

		<mat-divider></mat-divider>

		<!-- JSON -->
		<button
			mat-menu-item
			role="menuitem"
			class="p-0"
			(click)="openExportOptionsDialog(exportToType.JSON, mode)"
		>
			<span class="flex w-full items-center justify-between p-1">
				<i class="fa-duotone fa-file-code fa-xl text-yellow-500"></i>
				<span>JSON</span>
			</span>
		</button>
	</ng-template>
</mat-menu>

<!-- ----------------------------------------------------------------------- -->
<!--                        Global Table Menu Dialogs                        -->
<!-- ----------------------------------------------------------------------- -->

<!-- ---------------- Dialog: Export table Data Options ----------------- -->
<ng-template
	#exportToDialog
	Dialog="ExporTableOptions"
	[hasbackdrop]="true"
>
	<mat-card appearance="outlined">
		<mat-card-header>
			<mat-card-title>
				<span class="font-bold">
					{{ 'dccomp.dc-table.export.select-visible-columns' | translate }}
				</span>
			</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<section>
				<span>
					<mat-checkbox
						class=""
						[checked]="allColumnsToExportChecked"
						color="primary"
						[indeterminate]=""
						(change)="exportAllColumnsToggle()"
					></mat-checkbox>
					<span class="font-semibold">{{ 'dccomp.dc-table.export.select-all-columns' | translate }}</span>
				</span>
				<span>
					<ul
						class="mx-4"
						class="grid grid-cols-3 gap-2"
					>
						<li
							*ngFor="let col of columnsToExport"
							class="col-span-1"
						>
							<mat-checkbox
								class=""
								[checked]="col.visible"
								color="primary"
								(change)="exportColumnToggle(col.objecyKey)"
							></mat-checkbox>
							{{ col?.title | translate }}
						</li>
					</ul>
				</span>
			</section>

			<mat-divider class="my-2"></mat-divider>
			<!-- File Name -->
			<section>
				<span class="font-semibold">{{ 'dccomp.dc-table.export.file-name' | translate }}</span>
				<span class="dense-2">
					<mat-form-field
						appearance="outline"
						class="w-full"
					>
						<input
							matInput
							[autofocus]="true"
							type="text"
							[placeholder]="tableTitle ? tableTitle : ('dccomp.dc-table.export.file-name' | translate)"
							[(ngModel)]="exportFileName"
						/>
					</mat-form-field>
				</span>
			</section>
		</mat-card-content>
		<mat-card-actions class="flex w-full items-center justify-center p-2">
			<button
				mat-stroked-button
				color="primary"
				(click)="onExportTo()"
				matDialogClose
			>
				{{ 'dccomp.dc-table.export.label' | translate }}
			</button>
		</mat-card-actions>
	</mat-card>
</ng-template>
