export const DcColorType = {
	Primary: '--primary',
	PrimaryLightn: '--primary-lighten',
	Secondary: '--secondary',
	SecondaryLighten: '--secondary-lighten',
	Accent: '--accent',
	AccentLighten: '--accent-lighten',
	Error: '--error',
	ErrorLighten: '--error-lighten',
	Info: '--info',
	InfoLighten: '--info-lighten',
	Success: '--success',
	SuccessLighten: '--success-lighten',
	Warn: '--warn',
	WarnLighten: '--warn-lighten',
} as const;

// export type of DcColorType keys including the lowercase version of the keys and all values:
export type DcColorType = keyof typeof DcColorType | Lowercase<keyof typeof DcColorType> | (typeof DcColorType)[keyof typeof DcColorType];
export type DcColorKeyType = Lowercase<keyof typeof DcColorType>;

export enum DcColorOpacityType {
	Op500 = '-500',
	Op400 = '-400',
	Op300 = '-300',
	Op200 = '-200',
	Op100 = '-100',
}

export function getColorClass(color: DcColorType, element: 'bg' | 'border' | string): string {
	const key = getColorType(color).toLowerCase();
	return `dc-${element}-${key}`;
}

export function getColor(color: DcColorType): string {
	const key = Object.keys(DcColorType).find(key => key.toLowerCase() === color.toLowerCase());
	if (!key) {
		return `var(${color})`;
	}
	return `var(${DcColorType[key as keyof typeof DcColorType]})`;
}

export function getColorType(color: DcColorType): DcColorKeyType {
	const key = Object.entries(DcColorType).find(([key, value]) => key.toLowerCase() === color.toLowerCase() || value === color);
	if (key) {
		return key[0].toLowerCase() as DcColorKeyType;
	}
	return undefined;
}

export function getDynamicColorClass(twPrefix: string, color: DcColorType): string {
	const key = getColorType(color) + '-500';
	return `${twPrefix}-${key}`;
}

export function getColorAndLighten(color: DcColorType): { color: string; lighten: string } {
	const key = getColorType(color);
	const lightenKey = (key + 'lighten') as DcColorType;
	return {
		color: getColor(color),
		lighten: getColor(lightenKey),
	};
}

// example of binding getColor to a component:
//	protected dcColor: (color: DcColorType) => string = (color: DcColorType) => getColor(color);

/* -------------------------------------------------------------------------- */
export enum MatButtonType {
	Raised = 'mat-mdc-raised-button',
	Stroked = 'mat-mdc-outlined-button',
	Flat = 'mat-flat-button',
	Icon = 'mat-icon-button',
	Fab = 'mat-fab',
	MiniFab = 'mat-mini-fab',
}
