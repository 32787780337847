import { HttpParams } from "@angular/common/http";
import moment from "moment";
import { IColumnSortStruct } from "../../table/data/config/column-sort-struct.type";
import { IDocumentDTO } from "src/app/Data/Services/DTO/document.DTO";


export type IReportsQueryRequest = {
  query: string;
  page?: number;
  size?: number;
};

export type IReportsQueryResponse = {
  totalDocuments: number;
  fromDocument: number;
  toDocument: number;
  documents: IDocumentDTO[];
};


export enum DocumentListType {
  Received = 'Recieved',
  Sent = 'Sent',
};

export type IReportsQueryParams = {
  query: string;
  page?: number;
  size?: number;
  fromDate?: Date;
  toDate?: Date;
  type?: DocumentListType[]; // the type of lists to include in the query
  sort?:  IColumnSortStruct<any>;
};

export class ReportsQueryRequest implements IReportsQueryRequest {
  queryParams: IReportsQueryParams;
  //
  query: string;
  page: number = 1;
  size: number = 0;

  //

  constructor(params: IReportsQueryParams) {
    this.queryParams = params;
    this.query = this.generateQueryString();
    if (params.page) this.page = params.page;
    if (params.size ?? false) this.size = params.size;
  }

  private generateQueryString(): string {
    let queryString = `${this.queryParams.query}`;
    if (this.queryParams.fromDate) {
      // adapt the date according to the timezone of the user and convert it to ISOString
      const fromDateString = moment(this.queryParams.fromDate).add(moment().utcOffset(), "minutes").toISOString();
      queryString += ` fromCreateDate:${fromDateString}`;
    }
    if (this.queryParams.toDate) {
      // adapt the date according to the timezone of the user and convert it to ISOString
      const toDateString = moment(this.queryParams.toDate).add(moment().utcOffset(), "minutes").toISOString();
      queryString += ` toCreateDate:${toDateString}`;
    }
    if (this.queryParams.type) {
      let types = this.queryParams.type.map(type => type.charAt(0).toUpperCase() + type.slice(1));
      if (types.length > 0) queryString += ` type:[${types.join(",")}]`;
    }
    if (this.queryParams.sort) {
      const sortMarker = CycleTableColumnToQuerySortMarker(this.queryParams.sort.defaultObjectKey as SortableReportsTableColumn);
      queryString += ` sortBy:${sortMarker } sortDesc:${this.queryParams.sort.direction === "desc"}`;
      //queryString += ` sortDesc:${this.queryParams.sort.direction === "desc"}`;
    }
    return queryString;
  }

  toHttpParams(): HttpParams {
    return new HttpParams().set("query", this.generateQueryString()).set("page", this.page).set("size", this.size);
  }
}

export enum CycleQuerySortMarker {
  LastUpdate = "LastUpdate",
  CreateDate = "CreateDate",
  Status = "Status",
  Recipient = "Recipient",
}

export function CycleTableColumnToQuerySortMarker(columnKey: SortableReportsTableColumn): CycleQuerySortMarker {
  switch (columnKey) {
    case SortableReportsTableColumn.last_update:
      return CycleQuerySortMarker.LastUpdate;
    case SortableReportsTableColumn.start_date:
      return CycleQuerySortMarker.CreateDate;
    case SortableReportsTableColumn.recipient:
      return CycleQuerySortMarker.Recipient;
    case SortableReportsTableColumn.doc_status:
      return CycleQuerySortMarker.Status;
  }
}

export const SortableReportsTableColumn = {
  last_update: "LastUpdate",
  start_date: "CreateDate",
  recipient: "recipientCellData",
  doc_status: "docStatus",
} as const;
export type SortableReportsTableColumn = (typeof SortableReportsTableColumn)[keyof typeof SortableReportsTableColumn];
