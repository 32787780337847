import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: '[IsMobile]',
	standalone: true,
})
export class IsMobileDirective implements OnInit {
	constructor(private element: ElementRef, private renderer: Renderer2) {}

	ngOnInit(): void {
		if (window.deviceService.isMobile) {
			this.renderer.addClass(this.element.nativeElement, 'mobile');
		}else{
      this.renderer.removeClass(this.element.nativeElement, 'mobile');
    }
	}
}
